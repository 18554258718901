import { ListenerCallback, Listeners, PvCChannelMap } from '@Types';

export class EventEmitter<T_INIT, T_ROUND, T_RESULT> {

  private listeners!: Listeners;

  constructor() {
    this.listeners = { };
  }

  on<T extends keyof PvCChannelMap<T_INIT, T_ROUND, T_RESULT>>(
    channel: T,
    callback: ListenerCallback<PvCChannelMap<T_INIT, T_ROUND, T_RESULT>[T]>
  ): void {
    const fns = this.listeners[channel] || [];
    fns.push(callback);
    this.listeners[channel] = fns;
  }

  offAll(): void {
    for (const channel in this.listeners) {
      delete this.listeners[channel];
    }
  }

  protected emit<T extends keyof PvCChannelMap<T_INIT, T_ROUND, T_RESULT>>(
    channel: T,
    data: PvCChannelMap<T_INIT, T_ROUND, T_RESULT>[T]
  ): void {
    const fns = this.listeners[channel] || [];

    for (const fn of fns) {
      fn(data);
    }
  }

}
