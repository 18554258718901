import React from 'react';

import { Divider, ListItem, Typography } from '@mui/material';

import RankingPlacement from './RankingPlacement';
import TeamMembers from '../team/TeamMembers';
import { Member } from '@Webpages/ranking/functions/team-group-and-sort';

type TeamRankingListItemProps = {
  name: string;
  members: Member[];
  elo: number;
  placement: number;
  id: string;
};

function TeamRankingListItem({
  name,
  members,
  elo,
  placement,
  id
}: TeamRankingListItemProps) {
  return (
    <>
      <Divider sx={{ borderColor: 'var(--divider-color)' }} />
      <a
        href={ `/team/${id}` }
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <ListItem
          sx={{ columnGap: '16px', ":hover": { bgcolor: 'primary.50' } }}
        >
          <Typography flex='60%'>{ name }</Typography>
          <TeamMembers flex='calc(40% - 264px)' members={ members } />
          <Typography flex='200px' align='right'>{ elo }</Typography>
          <RankingPlacement placement={ placement } />
        </ListItem>
      </a>
    </>
  );
}

export default React.memo(TeamRankingListItem);
