import { EnqueueSnackbar } from 'notistack';

import { VoidFn } from '@Types/void-function';

/**
 *
 * @param promise the response of the request
 * @param errorMessageHandler the function to open a snackbar
 * @param successMessageHandler a function to be called on success. It should
 * open a snackbar internally with a predefined message
 * @returns
 */
export async function handleAsyncError<T>(
  promise: Promise<T>,
  errorMessageHandler: EnqueueSnackbar | null = null,
  successMessageHandler: VoidFn | null = null,
): Promise<any> {
  try {
    const result: any = await promise;

    successMessageHandler?.();

    return result?.data ?? result;
  } catch (e: any) {
    let message = e.message;

    if (e.isAxiosError) {
      message = e.response?.data ?? e.response?.statusText ?? e.message;
    }

    errorMessageHandler?.(message, {
      variant: 'error',
    });

    throw e;
  }
}
