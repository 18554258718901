import React from 'react';

import { LinearProgress, Stack, Typography } from '@mui/material';

import { Rock, Paper, Scissors, Well } from '@Assets';
import DisplayContainer from '@Components/games/DisplayContainer';
import { getWinner } from '../functions/get-winner';
import { useJsonApiConnector, useLogger } from '@Hooks';
import { Bot } from '@Models/bot';
import { RockPaperScissorsDisplay } from '@Types/rock-paper-scissors-display';

type RockPaperScissorsProps = {
  includeWell?: boolean;
};

function RockPaperScissors({ includeWell = false }: RockPaperScissorsProps) {
  const logger = useLogger();
  const jsonApiConnector = useJsonApiConnector();
  const [bots, setBots] = React.useState<Bot[]>([]);
  const [step, setStep] = React.useState<number>(0);
  const elements = {
    STEIN: Rock,
    PAPIER: Paper,
    SCHERE: Scissors,
    BRUNNEN: Well,
  };
  const gameId = includeWell ? 'SSPB' : 'SSP';

  const [{
    data: info
  }, reload] = jsonApiConnector.displayGame.read<RockPaperScissorsDisplay>(
    gameId
  );

  React.useEffect(() => {
    setStep(0);
    setBots([]);

    if (info) {
      (async () => {
        const bots = await Promise.all(info.data.players.map(
          async (id) => (
            await jsonApiConnector.client.get<Bot>(`/bot/${id}`)
          ).data
        ));

        setBots(bots);
      })();
    }
  }, [info, jsonApiConnector]);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!info || bots.length === 0) {
      logger.info('still loading');
    } else {
      if (step === info.data.states.length) {
        timeout = setTimeout(() => {
          reload();
        }, 5000);
      } else {
        timeout = setTimeout(() => {
          setStep(step + 1);
        }, 2000);
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [step, info, bots, reload, logger]);

  const state = info?.data.states[step - 1];

  return (
    <DisplayContainer label={ `Schnick, Schnack, Schnuck${includeWell ? ', Brunnen' : ''}` }>
      {
        !info || bots.length === 0 || !state ? <LinearProgress /> : (
          <>
            <Stack direction='row' justifyContent='space-evenly'>
              <Stack direction='column' alignContent='center' justifyContent='center'>
                <img src={ elements[state.results[0]] } alt='Icon' width='128px' />
              </Stack>
              <Stack direction='column' alignContent='center' justifyContent='center'>
                <img src={ elements[state.results[1]] } alt='Icon' width='128px' />
              </Stack>
            </Stack>
            <Typography variant='h4' color='#2c3e50' sx={{
              transition: 'all 0.5s',
              fontSize: '2.3rem',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              mt: '32px'
            }}>
              {
                getWinner(state.results)
              }
            </Typography>
            <LinearProgress value={100 / info.data.states.length * step} variant='determinate'
              sx={{
                m: 'min(0.5vw, 0.75vh)',
                mt: '16px',
                height: 'min(0.5vw, 0.75vh)',
                borderRadius: 'min(0.25vw, 0.375vh)'
              }}
            />
          </>
        )
      }
    </DisplayContainer>
  )
}

export default React.memo(RockPaperScissors);
