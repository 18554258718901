import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import PageNotFoundImage from '@Assets/page_not_found.gif';

type AccessDeniedProps = {
  isLoginRequired?: boolean;
  isManagerRequired?: boolean;
};

function AccessDenied({
  isLoginRequired = false,
  isManagerRequired = false,
}: AccessDeniedProps) {
  const navigateTo = useNavigate();

  let label!: string;

  if (isLoginRequired && !isManagerRequired) {
    label = 'Um auf diese Seite zugreifen zu können musst du angemeldet sein';
  } else {
    label = 'Um auf diese Seite zugreifen zu können musst du Manager Berechtigungen besitzen';
  }

  return (
    <Box sx={{
      textAlign: 'center',
      backgroundImage: `url(${PageNotFoundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      mixBlendMode: 'multiply',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'inherit'
      }}>
        <Typography variant='h1' sx={{ pb: '256px' }}>403</Typography>
        <Typography variant='subtitle2' sx={{
          color: 'text.secondary',
          mt: '128px',
          fontSize: '1.6rem'
        }}>
          { label }
        </Typography>
        <Button
          color='secondary'
          variant='contained'
          onClick={() => navigateTo('/')}
        >
          Startseite
        </Button>
        <Button
          color='secondary'
          variant='contained'
          onClick={() => window.history.back()}
        >
          Eine Seite zurück
        </Button>
      </Box>
    </Box>
  );
}

export default React.memo(AccessDenied);
