import { Box, LinearProgress, Stack, Typography, useTheme } from '@mui/material';

import React from 'react';

import DisplayContainer from '@Components/games/DisplayContainer';
import { useJsonApiConnector, useLogger } from '@Hooks';
import { Bot } from '@Models/bot';
import { MetaTicTacToeDisplay } from '@Types/meta-tic-tac-toe-display';

function check(board: string[]): number[] {
  for (const symbol of ['X', 'O']) {
    if (board[0] === symbol && board[1] === symbol && board[2] === symbol) {
      return [0, 1, 2];
    }
    if (board[3] === symbol && board[4] === symbol && board[5] === symbol) {
      return [3, 4, 5];
    }
    if (board[6] === symbol && board[7] === symbol && board[8] === symbol) {
      return [6, 7, 8];
    }
    if (board[0] === symbol && board[3] === symbol && board[6] === symbol) {
      return [0, 3, 6];
    }
    if (board[1] === symbol && board[4] === symbol && board[7] === symbol) {
      return [1, 4, 7];
    }
    if (board[2] === symbol && board[5] === symbol && board[8] === symbol) {
      return [2, 5, 8];
    }
    if (board[0] === symbol && board[4] === symbol && board[8] === symbol) {
      return [0, 4, 8];
    }
    if (board[2] === symbol && board[4] === symbol && board[6] === symbol) {
      return [2, 4, 6];
    }
  }

  return [];
};

function MetaTicTacToe() {
  const logger = useLogger();
  const theme = useTheme();
  const jsonApiConnector = useJsonApiConnector();
  const [{
    data: info
  }, reload] = jsonApiConnector.displayGame.read<MetaTicTacToeDisplay>('MTTT');
  const [bots, setBots] = React.useState<Bot[]>([]);
  const [step, setStep] = React.useState<number>(0);

  React.useEffect(() => {
    setStep(0);
    setBots([]);

    if (info) {
      (async () => {
        const bots = await Promise.all(info.data.players.map(async (id) => (await jsonApiConnector.client.get<Bot>(`/bot/${id}`)).data));
        setBots(bots);
      })();
    }
  }, [info, jsonApiConnector]);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!info || bots.length === 0) {
      logger.info('still loading');
    } else {
      if (step === info.data.states.length) {
        timeout = setTimeout(() => {
          reload();
        }, 5000);
      } else {
        timeout = setTimeout(() => setStep(step + 1), 2000);
      }
    }
    return () => clearTimeout(timeout);
  }, [step, info, bots, reload, logger]);

  if (!info) {
    return <LinearProgress />;
  }

  const state = info.data.states[step - 1];

  function renderBoard(board: string[][], overview: string[]) {
    const overviewMap = check(overview);

    if (!info) return '';

    return (
      <Stack direction='row' sx={{
        transition: 'all 0.25s',
        border: 'min(0.5vw, 0.75vh) solid transparent',
        margin: 'min(0.5vw, 0.75vh)',
        borderRadius: 'min(1vw, 1.5vh)',
        display: 'grid',
        gridTemplateRows: 'auto auto auto',
        gridTemplateColumns: 'auto auto auto',
        overflow: 'hidden',
        position: 'relative'
      }}>
        {
          board.map((fields, section) => {
            const sectionMap = check(fields);
            const completed = overview[section] !== '';

            return (
              <Stack direction='column' key={ section } sx={{
                transition: 'all 0.5s',
                transitionDelay: '1s',
                display: 'grid',
                gridTemplateRows: 'auto auto auto',
                gridTemplateColumns: 'auto auto auto',
                border: `min(0.25vw, 0.375vh) solid ${theme.palette.primary.main}`,
                borderTop: Math.floor(section / 3) === 0 ? '0px solid transparent' : null,
                borderBottom: Math.floor(section / 3) === 2 ? '0px solid transparent' : null,
                borderLeft: section % 3 === 0 ? '0px solid transparent' : null,
                borderRight: section % 3 === 2 ? '0px solid transparent' : null,
                background: step !== info.data.states.length && ((state.forcedSection != null && state.forcedSection !== section) || completed) ? '#95a5a6' : null,
                position: 'relative'
              }}>
                {
                  completed ? (
                    <Box sx={{
                      zIndex: 10,
                      filter: `grayscale(${overviewMap.length && !overviewMap.includes(section) ? 1 : 0})`,
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      fontSize: 'min(9vw, 13.5vh)',
                      textAlign: 'center',
                      lineHeight: 'min(15vw, 22.5vh)'
                    }}>
                      {overview[section] === 'X' ? '✖️' : (overview[section] === 'O' ? '⭕' : '💩')}
                    </Box>
                  ) : null
                }
                {
                  fields.map((value, field) => {
                    let content = '';
                    const style: any = {};

                    switch (value) {
                      case 'X':
                        style.fontSize = 'min(3vw, 4.5vh)';
                        content = '✖️';
                        break;
                      case 'O':
                        style.fontSize = 'min(3vw, 4.5vh)';
                        content = '⭕';
                        break;
                    }

                    return (
                      <Box sx={{
                        zIndex: 1,
                        border: `min(0.125vw, 0.1875vh) solid ${theme.palette.primary.main}`,
                        borderTop: Math.floor(field / 3) === 0 ? '0px solid transparent' : null,
                        borderBottom: Math.floor(field / 3) === 2 ? '0px solid transparent' : null,
                        borderLeft: field % 3 === 0 ? '0px solid transparent' : null,
                        borderRight: field % 3 === 2 ? '0px solid transparent' : null
                      }} key={ field }>
                        <Box sx={{
                          transition: 'background 1s, font-size 0.5s, box-shadow 0.25s',
                          filter: `grayscale(${completed && !sectionMap.includes(field) ? 1 : 0})`,
                          width: 'min(5vw, 7.5vh)',
                          height: 'min(5vw, 7.5vh)',
                          textAlign: 'center',
                          lineHeight: 'min(5vw, 7.5vh)',
                          borderRadius: 'min(0.5vw, 0.75vh)',
                          fontSize: '0',
                          ...style
                        }} key={ field }>{ content }</Box>
                      </Box>
                    );
                  })
                }
              </Stack>
            );
          })
        }
      </Stack>
    );
  };

  return (
    <DisplayContainer label='Meta-Tic-Tac-Toe'>
      {
        !info || bots.length === 0 || !state ? <LinearProgress/> : (
          <>
            <Stack direction='row' justifyContent='space-evenly' sx={{ height: '100%' }}>
              <Box sx={{ height: '100%', width: '75%' }}>
                <Stack direction='column' justifyContent='center'
                  sx={{ height: 'calc(100% - min(1.5vw, 2.25vh))', overflow: 'hidden' }}>
                  <Stack direction='row' justifyContent='space-evenly'>
                    <Box sx={{
                      width: 'min(40vw, 60vh)'
                    }}>
                      <Typography variant='h4'
                        color={ state.active === 1 ? '#2c3e50' : '#95a5a6' }
                        sx={{
                          transition: 'all 0.5s',
                          fontSize: 'min(6vw, 9vh)',
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>✖️{ bots[0].name }</Typography>
                      <Typography variant='body2'
                        color={ state.active === 1 ? '#2c3e50' : '#95a5a6' }
                        sx={{
                          transition: 'all 0.5s',
                          fontSize: 'min(2vw, 3vh)',
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>Team "{ bots[0].team }"</Typography>
                    </Box>
                    <Box sx={{
                      width: 'min(40vw, 60vh)'
                    }}>
                      <Typography variant='h4' color={ state.active === 0 ? '#2c3e50' : '#95a5a6' }
                        sx={{
                          transition: 'all 0.5s',
                          fontSize: 'min(6vw, 9vh)',
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>⭕{ bots[1].name }</Typography>
                      <Typography variant='body2' color={ state.active === 0 ? '#2c3e50' : '#95a5a6' }
                        sx={{
                          transition: 'all 0.5s',
                          fontSize: 'min(2vw, 3vh)',
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>Team "{ bots[1].team }"</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' justifyContent='space-evenly'>
                    {
                      renderBoard(state.board, state.overview)
                    }
                  </Stack>
                </Stack>
                <LinearProgress
                  value={ 100 / info.data.states.length * step }
                  variant='determinate'
                  sx={{
                    m: 'min(0.5vw, 0.75vh)',
                    height: 'min(0.5vw, 0.75vh)',
                    borderRadius: 'min(0.25vw, 0.375vh)'
                  }}
                />
              </Box>
            </Stack>
          </>
        )
      }
    </DisplayContainer>
  );
}

export default React.memo(MetaTicTacToe);
