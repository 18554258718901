import React from 'react';

import {
  Box,
  Divider,
  ListItem,
  SxProps,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';

import RankingPlacement from './RankingPlacement';

type BotRankingListItemProps = {
  botName: string;
  teamName: string;
  lastGame: string | null;
  isOnline: boolean;
  elo: number;
  placement: number;
  teamId: string;
};

function BotRankingListItem(props: BotRankingListItemProps) {
  const {
    botName,
    teamName,
    lastGame,
    isOnline,
    elo,
    placement,
    teamId
  } = props;
  let tooltip = 'Noch kein Spiel gespielt';

  if (lastGame !== null) {
    tooltip = `Letztes Spiel: ${ lastGame }`;
  }

  const defaultStatusStyles: SxProps<Theme> = {
    borderRadius: '16px',
    padding: '0 8px',
    fontSize: '.9rem',
    userSelect: 'none',
    float: 'right',
    width: 'fit-content',
    fontWeight: 'bold'
  };
  const statusStyles: SxProps<Theme> = isOnline ? {
    border: '1px solid var(--success)',
    color: 'var(--success)',
    ...defaultStatusStyles
  } : {
    border: '1px solid var(--error)',
    color: 'var(--error)',
    ...defaultStatusStyles
  };
  const status = isOnline ? 'online' : 'offline';

  return (
    <>
      <Divider sx={{ borderColor: 'var(--divider-color)' }} />
      <ListItem sx={{ columnGap: '16px', ':hover': { bgcolor: 'primary.50' } }}>
        <Typography flex='40%'>{ botName }</Typography>
        <a
          href={ `/team/${teamId}` }
          style={{
            flex: 'calc(60% - 364px)',
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          <Typography>{ teamName }</Typography>
        </a>
        <Box flex='100px'>
          <Tooltip title={ tooltip } placement='left'>
            <Typography sx={ statusStyles }>{ status }</Typography>
          </Tooltip>
        </Box>
        <Typography flex='200px' align='right'>{ elo }</Typography>
        <RankingPlacement placement={ placement } />
      </ListItem>
    </>
  );
}

export default React.memo(BotRankingListItem);
