import { faDiscord, faGithub } from '@fortawesome/free-brands-svg-icons';

export const logins = [
  {
    id: 'discord',
    title: 'Discord',
    disabled: false,
    icon: faDiscord
  },
  {
    id: 'github',
    title: 'GitHub',
    disabled: false,
    icon: faGithub
  }
];
