import React from 'react';

import { GroupingService } from '@Services/grouping-service';
import { JsonApiConnector } from '@Services/json-api-connector';
import { LoggerService } from '@Services/logger-service';
import { Nullable } from '@Types/nullable';

export type ServicesContextValues = {
  logger: Nullable<LoggerService>;
  grouping: Nullable<GroupingService>;
  jsonApiConnector: Nullable<JsonApiConnector>;
};

export const ServicesContext = React
  .createContext<Nullable<ServicesContextValues>>(null);

export const SERVICES: ServicesContextValues = {
  logger: new LoggerService(),
  grouping: new GroupingService(),
  jsonApiConnector: new JsonApiConnector()
};
