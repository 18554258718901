import React from 'react';

import { Paper, Typography } from '@mui/material';

type DisplayContainerProps = {
  label: string;
  children: JSX.Element | JSX.Element[];
}

function DisplayContainer({ label, children }: DisplayContainerProps) {
  function handleDoubleClick() {
    const player = document.getElementById('display');

    if (player && document.fullscreenEnabled && player.requestFullscreen) {
      if (!document.fullscreenElement) {
        player.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    }
  }

  return (
    <>
      <Typography variant='h3' textAlign='center' sx={{ p: '16px 0' }} data-testid='label-text'>
        { label }
      </Typography>
      <Paper
        data-testid='children-container'
        id='display'
        sx={{
          m: '16px auto',
          p: '16px',
          maxWidth: '1200px',
          bgcolor: 'var(--white)'
        }}
        elevation={ 2 }
        onDoubleClick={() => handleDoubleClick()}
      >{ children }</Paper>
    </>
  );
}

export default React.memo(DisplayContainer);
