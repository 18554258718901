import { LoggerService } from './logger-service';

export type Group<T> = {
  label: string;
  items: T[];
};
type GroupingType = 'alphabetical' | 'alphabetical-reverse' | 'custom-group';
export type GrouperFn<T> = (items: T[]) => Group<T>[];

export class GroupingService {
  private logger: LoggerService;

  constructor() {
    this.logger = new LoggerService();
  }

  /**
   *
   * @param items the elements which should be grouped
   * @returns
   */
  createDefaultGroup<T>(items: T[]): Group<T>[] {
    return [{
      label: '',
      items: [...items]
    }];
  }

  /**
   * @param items the elements which should be grouped (if no custom grouper
   *              function is defined, an array of strings is expected)
   * @param groupingType
   * @param grouper custom grouper function if no strings are sorted
   * @returns
   */
  groupItems<T>(
    items: T[],
    groupingType: GroupingType,
    grouper: GrouperFn<T> | null = null
  ): Group<T>[] {
    if (
      grouper === null &&
      items.length > 0 &&
      !(typeof items[0] === 'string')
    ) {
      this.logger.error(
        'Expected an array of strings since no custom grouper function was defined!'
      );

      throw new Error();
    }

    switch (groupingType) {
      case 'alphabetical-reverse': {
        if (grouper !== null) {
          return grouper(items);
        }

        return this.groupAlphabetical(items, true);
      }
      case 'alphabetical': {
        if (grouper !== null) {
          return grouper(items);
        }

        return this.groupAlphabetical(items, false);
      }
      case 'custom-group': {
        if (grouper === null) {
          this.logger.error(
            'Since you provided "custom-group" as the groupingType, a custom grouper function was expected!'
          );
          throw new Error();
        }

        return grouper(items);
      }
    }
  }

  private groupAlphabetical<T>(items: T[], reverse: boolean): Group<T>[] {
    const letterMapping: Map<string, T[]> = new Map<string, T[]>();
    const groups: Group<T>[] = [];

    items.forEach((item) => {
      let key = getKeyForChar(String(item).charAt(0).toLocaleUpperCase('de-DE'));
      const mappedItems: T[] = letterMapping.get(key) || [];
      mappedItems.push(item);
      letterMapping.set(key, mappedItems);
    });

    letterMapping.forEach((value, key) => {
      groups.push({
        label: key,
        items: value
      });
    });

    groups.sort((a, b) => a.label.localeCompare(b.label, 'de-DE'));
    groups.forEach((group) => group.items.sort((a, b) => String(a).localeCompare(String(b), 'de-DE')));

    if (reverse === true) groups.reverse();

    return groups;
  }

}

export function getKeyForChar(text: string): string {
  return /[0-9]/.test(text) ? '0-9' : text;
}
