import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';

import { useJsonApiConnector } from '@Hooks/use-json-api-connector';
import { createSubtitle } from '@Utils/create-subtitle';

function Competitions() {
  const jsonApiConnector = useJsonApiConnector();
  const [{
    data: competitions,
    error,
  }] = jsonApiConnector.competitions.readAll();

  if (!competitions || error) {
    return null;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={ 2 }>
        {
          competitions.map((competition) => (
            <Grid item xs={ 12 } sm={ 6 } md={ 4 } key={ competition.id }>
              <Card
                data-testid='competition-card'
                component={ RouterLink }
                to={ `/competition/${competition.id.toLocaleLowerCase()}` }
                key={ competition.id }
                sx={{
                  bgcolor: 'primary.50',
                  display: 'flex',
                  flexDirection: 'column',
                  textDecoration: 'none',
                  height: '100%',
                }}
              >
                <CardActionArea sx={{ height: '100%', bgcolor: 'primary.50'}}>
                  <CardContent sx={{ p: 3 }}>
                    <Typography variant='h5' component='div'>
                      { competition.name }
                    </Typography>
                    <Typography gutterBottom variant='subtitle1' component='div'>
                      { createSubtitle(competition) }
                    </Typography>
                    <Typography gutterBottom variant='body2' color='text.secondary'>
                      { competition.description }
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))
        }
      </Grid>
    </Box>
  )
}

export default React.memo(Competitions)
