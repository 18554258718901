import * as React from 'react';
import { useParams } from 'react-router-dom';

import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { useJsonApiConnector, useProfile } from '@Hooks';
import { Nullable } from '@Types';
import { Account } from '@Models';
import PageNotFound from '@Webpages/error/PageNotFound';
import { handleAsyncError } from '@Utils/handle-async-error';
import UserAvatar from '@Components/profile/UserAvatar';

function Competition() {
  const { competitionId } = useParams();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { profile } = useProfile();
  const jsonApiConnector = useJsonApiConnector();

  const [{
    data: competition,
    error
  }, reload] = jsonApiConnector.competitionManagement.read(competitionId || null);

  const [name, setName] = React.useState<Nullable<string>>(null);
  const [description, setDescription] = React.useState<Nullable<string>>(null);
  const [userAction, setUserAction] = React.useState<Nullable<any>>(null);

  const [newMember, setNewMember] = React.useState<Nullable<any>>(null);
  const [newMemberInputValue, setNewMemberInputValue] = React.useState<string>('');
  const [{
    data: newMemberOptions
  }] = jsonApiConnector.useAxios<Account[]>(`/search/user/${newMemberInputValue}`, {
    manual: !newMemberInputValue
  });

  const renderName = name ?? competition?.name ?? '';
  const renderDescription = description ?? competition?.description ?? '';

  if (error?.response?.status === 404) return <PageNotFound />;
  if (!profile || !competition) return null;

  async function saveChanges() {
    await handleAsyncError(
      axios.post(`/api/profile/competition/${competitionId}`, {
        name: renderName,
        description: renderDescription,
      }),
      enqueueSnackbar,
      () => {
        enqueueSnackbar('Änderungen gespeichert', {
          variant: 'success'
        });
      },
    );

    reload();
  }

  return <>
    <Paper sx={{ mt: 2, p: 3 }} elevation={ 2 }>
      <Typography variant='h4' sx={{ flexGrow: 1, flexShrink: 1 }} gutterBottom>
        { competition?.name }
      </Typography>
      <Stack spacing={ 2 }>
        {
          !competition.manager ? (
            <Typography variant='body1' color='text.secondary' gutterBottom>
              { competition?.description }
            </Typography>
          ) : (
            <>
              <TextField
                size='small'
                type='text'
                label='Name'
                value={ renderName }
                onChange={(ev) => {
                  setName(ev.target.value);
                }}
              />
              <TextField
                size='small'
                type='text'
                label='Beschreibung'
                multiline
                rows={ 5 }
                value={ renderDescription }
                onChange={(ev) => {
                  setDescription(ev.target.value);
                }}
              />
              <Box sx={{
                display: 'flex',
                gridAutoFlow: 'column',
                gridGap: theme.spacing(2)
              }}>
                <Box sx={{ flexGrow: 1, flexShrink: 1 }}/>
                <Button
                  variant='contained'
                  disabled={ !renderName }
                  onClick={() => saveChanges()}
                >
                  speichern
                </Button>
              </Box>
            </>
          )
        }
      </Stack>
      <Table
        size='small'
        sx={{ mt: 2, ml: -1, width: `calc(100% + ${theme.spacing(2)})` }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 1, pr: 1 }} colSpan={ 2 }>Spieler</TableCell>
            <TableCell
              sx={{ pl: 1, pr: 1, width: 0 }}
              colSpan={ !competition.manager ? 1 : 2 }
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            (competition?.members || []).map((user) => {
              let statusAction = null;
              const statusText = [];

              if (user.manager) statusText.push('Manager');

              if (!user.accepted_account) {
                statusText.push('eingeladen');
              } else if (!user.accepted_competition) {
                if (competition.manager) {
                  statusAction = (
                    <Button
                      variant='outlined'
                      color='success'
                      onClick={async () => {
                        await handleAsyncError(
                          axios.post(
                            `/api/profile/competition/${competitionId}/accept/${user.id}`,
                            {},
                          ),
                          enqueueSnackbar,
                          () => {
                            enqueueSnackbar(
                              'Einladung erfolgreich angenommen',
                              {
                                variant: 'success',
                              },
                            );
                          },
                        );
                        reload();
                      }}
                    >
                      annehmen
                    </Button>
                  );
                } else {
                  statusText.push('angefragt');
                }
              }

              return (
                <TableRow
                  key={ user.id }
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{ pl: 1, pr: 1, width: 0 }}>
                    <UserAvatar key={ user.id } profile={ user }/>
                  </TableCell>
                  <TableCell
                    sx={{ pl: 1, pr: 1, wordBreak: 'break-word' }}
                    component='th'
                    scope='row'
                  >
                    { user.username }
                  </TableCell>
                  <TableCell sx={{ pl: 1, pr: 1, width: 0 }}>
                    { statusAction ?? statusText.join(', ') }
                  </TableCell>
                  {
                    !competition.manager ? null : (
                      <TableCell sx={{ pl: 1, pr: 1, width: 0 }}>
                        <IconButton onClick={(ev) => {
                          setUserAction({
                            id: user.id,
                            element: ev.target,
                          });
                        }}>
                          <ExpandCircleDownIcon />
                        </IconButton>
                        <Menu
                          anchorEl={ userAction?.element }
                          open={ userAction?.id === user.id }
                          onClose={() => setUserAction(null)}
                        >
                          {
                            user.manager ? (
                              <MenuItem
                                onClick={async () => {
                                  if (user.id !== profile.id || window.confirm('Willst du dich wirklich selbst degradieren?')) {
                                    await handleAsyncError(
                                      axios.post(
                                        `/api/profile/competition/${competitionId}/downgrade/${user.id}`,
                                        {},
                                      ),
                                      enqueueSnackbar,
                                      () => {
                                        enqueueSnackbar(
                                          'Erfolgreich degradiert',
                                          {
                                            variant: 'success',
                                          },
                                        );
                                      },
                                    );
                                    reload();
                                    setUserAction(null);
                                  }
                                }}
                              >
                                degradieren
                              </MenuItem>
                            ) : (
                              <MenuItem
                                onClick={async () => {
                                  await handleAsyncError(
                                    axios.post(
                                      `/api/profile/competition/${competitionId}/upgrade/${user.id}`,
                                      {},
                                    ),
                                    enqueueSnackbar,
                                    () => {
                                      enqueueSnackbar(
                                        'Erfolgreich befördert',
                                        {
                                          variant: 'success',
                                        },
                                      );
                                    },
                                  );
                                  reload();
                                  setUserAction(null);
                                }}
                              >
                                befördern
                              </MenuItem>
                            )
                          }
                          {
                            user.id === profile.id ? null : (
                              <MenuItem
                                onClick={async () => {
                                  if (window.confirm(`Willst du "${user?.username}" wirklich aus dem Competition entfernen?`)) {
                                    await handleAsyncError(
                                      axios.post(
                                        `/api/profile/competition/${competitionId}/remove/${user.id}`,
                                        {},
                                      ),
                                      enqueueSnackbar,
                                      () => {
                                        enqueueSnackbar(
                                          'Erfolgreich entfernt',
                                          {
                                            variant: 'success',
                                          },
                                        );
                                      },
                                    );
                                    reload();
                                    setUserAction(null);
                                  }
                                }}
                              >
                                entfernen
                              </MenuItem>
                            )
                          }
                        </Menu>
                      </TableCell>
                    )
                  }
                </TableRow>
              );
            })
          }
          {
            !competition.manager ? null : (
              <TableRow>
                <TableCell sx={{ pl: 1, pr: 1 }} colSpan={ 2 }>
                  <Autocomplete
                    getOptionLabel={(option) => option?.username}
                    filterOptions={(x) => x}
                    options={ newMemberOptions ?? [] }
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={ newMember }
                    onChange={(event: any, newValue) => {
                      setNewMember(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setNewMemberInputValue(newInputValue);
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField {...params} size='small' label='Mitspieler suchen' fullWidth/>
                      );
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props}>
                          <Grid container alignItems='center' gap={2}>
                            <Grid item>
                              <UserAvatar profile={option}/>
                            </Grid>
                            <Grid item>
                              {option?.username}
                            </Grid>
                          </Grid>
                        </li>
                      );
                    }}
                    sx={{ flexGrow: 1, flexShrink: 1 }}
                  />
                </TableCell>
                <TableCell sx={{ pl: 1, pr: 1, width: 0 }} colSpan={ 2 }>
                  <Button
                    variant='contained'
                    fullWidth
                    disabled={ !newMember }
                    onClick={async () => {
                      await handleAsyncError(
                        axios.post(
                          `/api/profile/competition/${competitionId}/invite/${newMember?.id}`,
                          {},
                        ),
                        enqueueSnackbar,
                        () => {
                          enqueueSnackbar(
                            'Erfolgreich eingeladen',
                            {
                              variant: 'success',
                            },
                          );
                        },
                      );
                      setNewMember(null);
                      setNewMemberInputValue('');
                      reload();
                    }}
                  >
                    einladen
                  </Button>
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </Paper>
  </>;
};

export default React.memo(Competition);
