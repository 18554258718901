export function getDeepClone<T>(input: T): T {
  if (Array.isArray(input)) {
    return input.map(item => getDeepClone(item)) as T;
  } else if (typeof input === 'object' && input !== null) {
    const copiedObject: Record<string, any> = {};

    for (const key in input) {
      copiedObject[key] = getDeepClone(input[key]);
    }

    return copiedObject as T;
  }

  return input;
}
