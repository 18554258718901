import React from 'react';

import { Box, LinearProgress, Stack, Typography, useTheme } from '@mui/material';

import DisplayContainer from '@Components/games/DisplayContainer';
import { useJsonApiConnector, useLogger } from '@Hooks';
import { Bot } from '@Models';
import { BattleshipDisplay, BattleshipSymbol } from '../types';
import { randomChoice } from '@Utils/random-choice';

function Battleship() {
  const theme = useTheme();
  const logger = useLogger();
  const jsonApiConnector = useJsonApiConnector();
  const [{
    data: info,
  }, reload] = jsonApiConnector.displayGame.read<BattleshipDisplay>('SV');
  const [bots, setBots] = React.useState<Bot[]>([]);
  const [step, setStep] = React.useState(0);

  React.useEffect(() => {
    setStep(0);
    setBots([]);

    if (info) {
      (async () => {
        const bots = await Promise.all(info.data.players.map(async (id) => (await jsonApiConnector.client.get<Bot>(`/bot/${id}`)).data));
        setBots(bots);
      })();
    }
  }, [info, jsonApiConnector]);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!info || bots.length === 0) {
      logger.info('still loading');
    } else {
      if (step === info.data.states.length) {
        timeout = setTimeout(() => reload(), 3000);
      } else {
        timeout = setTimeout(() => setStep(step + 1), 1000);
      }
    }

    return () => clearTimeout(timeout);
  }, [step, info, bots, reload, logger]);

  const state = info?.data.states[step - 1];

  const renderBoard = (board: BattleshipSymbol[][], target: boolean) => {
    const factor = 0.75;

    return (
      <Stack direction='row' sx={{
        transition: 'all 0.25s',
        border: `min(${factor}vw, ${1.5 * factor}vh) solid transparent`,
        borderColor: target ? theme.palette.primary.main : 'transparent',
        padding: `min(${factor}vw, ${1.5 * factor}vh)`,
        borderRadius: `min(${2 * factor}vw, ${3 * factor}vh)`,
        width: 'fit-content'
      }}>
        {
          board.map((column, x) => (
            <Stack direction='column' key={x}>
              {
                column.map((value, y) => {
                  const seed = `${info?.id} ${x} ${y}`;
                  let content = randomChoice(['🐳', '🐋', '🐬', '🐟', '🐠', '🐡', '🦈', '🐙', '🦀', '🦑'], seed);
                  const hitContent = randomChoice(['💥', '🔥'], seed);
                  const style: any = { };

                  switch (value) {
                    case '.':
                      style.bgcolor = '#2980b9';
                      break;
                    case 'x':
                      style.bgcolor = '#e74c3c';
                      style.fontSize = `min(${3 * factor}vw, ${4.5 * factor}vh)`;
                      content = hitContent;
                      break;
                    case 'X':
                      style.bgcolor = '#34495e';
                      style.fontSize = `min(${3 * factor}vw, ${4.5 * factor}vh)`;
                      content = hitContent;
                      break;
                    case 'O':
                      style.bgcolor = '#95a5a6';
                      content = hitContent;
                      break;
                    default:
                      style.bgcolor = '#3498db';
                  }

                  if (target && x === state?.move[0] && y === state.move[1]) {
                    style.fontSize = `min(${3 * factor}vw, ${4.5 * factor}vh)`;
                    style.boxShadow = `inset 0 0 0 min(${0.5 * factor}vw, ${0.75 * factor}vh) ${theme.palette.primary.main}`;
                  }

                  return (
                    <Box sx={{
                      transition: 'background 1s, font-size 0.5s, box-shadow 0.25s',
                      width: `min(${5 * factor}vw, ${7.5 * factor}vh)`,
                      height: `min(${5 * factor}vw, ${7.5 * factor}vh)`,
                      boxShadow: 'inset 0 0 0 0.5px white',
                      textAlign: 'center',
                      lineHeight: `min(${5 * factor}vw, ${7.5 * factor}vh)`,
                      borderRadius: `min(${0.5 * factor}vw, ${0.75 * factor}vh)`,
                      fontSize: '0',
                      ...style
                    }} key={ y }>{ content }</Box>
                  );
                })
              }
            </Stack>
          ))
        }
      </Stack>
    );
  };

  return (
    <DisplayContainer label='Schiffe versenken'>
      {
        !info || bots.length === 0 || !state ? <LinearProgress /> : (
          <>
            <Stack direction='column' justifyContent='center' sx={{ height: 'calc(100% - min(1.5vw, 2.25vh))' }}>
              <Stack direction='row' justifyContent='space-evenly'>
                <Box flex='50%'>
                  <Typography variant='h4' color={ state.active === 0 ? '#2c3e50' : '#95a5a6' }
                    sx={{
                      transition: 'all 0.5s',
                      fontSize: 'min(6vw, 9vh)',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>{ bots[0].name }</Typography>
                  <Typography variant='body2' color={ state.active === 0 ? '#2c3e50' : '#95a5a6' }
                    sx={{
                      transition: 'all 0.5s',
                      fontSize: 'min(2vw, 3vh)',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>Team "{ bots[0].team }"</Typography>
                </Box>
                <Box flex='50%'>
                  <Typography variant='h4' color={ state.active === 1 ? '#2c3e50' : '#95a5a6' }
                    sx={{
                      transition: 'all 0.5s',
                      fontSize: 'min(6vw, 9vh)',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>{ bots[1].name }</Typography>
                  <Typography variant='body2' color={ state.active === 1 ? '#2c3e50' : '#95a5a6' }
                    sx={{
                      transition: 'all 0.5s',
                      fontSize: 'min(2vw, 3vh)',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>Team "{ bots[1].team }"</Typography>
                </Box>
              </Stack>
              <Stack direction='row' justifyContent='space-evenly'>
                <Box flex='50%' display='flex' justifyContent='center'>
                  {
                    renderBoard(state.boards[0], state.active === 1)
                  }
                </Box>
                <Box flex='50%' display='flex' justifyContent='center'>
                  {
                    renderBoard(state.boards[1], state.active === 0)
                  }
                </Box>
              </Stack>
            </Stack>
            <LinearProgress value={ 100 / info.data.states.length * step } variant='determinate'
              sx={{
                m: 'min(0.5vw, 0.75vh)',
                mt: '32px',
                height: 'min(0.5vw, 0.75vh)',
                borderRadius: 'min(0.25vw, 0.375vh)'
              }}
            />
          </>
        )
      }
    </DisplayContainer>
  );
};

export default React.memo(Battleship);
