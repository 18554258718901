import React from 'react';

import { ServicesContext } from '@Contexts/services-context';

export function useJsonApiConnector() {
  const context = React.useContext(ServicesContext);

  if (context === null || context.jsonApiConnector === null) {
    throw new Error('Trying to access the json-api-connector service but no instance of that service was defined!');
  }

  return context.jsonApiConnector;
}
