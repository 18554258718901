import React from 'react';

import { VoidFn } from '@Types';

type Event = {
  target: any;
  [key: string]: any;
};

function getContextElement(element: any, context: string): any | null {
  if (!element.parentElement || element.localName === 'body') return null;
  if (element.parentElement.id === context) return element.parentElement;

  return getContextElement(element.parentElement, context);
}

/**
 * This hook implements a detection system to detect a click outside of the
 * referenced element.
 *
 * @param callback the function to be called if an click outside the ref was
 * detected.
 * @param context an optional context (the id of an element). It's required if
 * multiple elements are in use, which are using this hook.
 * @returns a mutable ref-object to reference the element
 */
export function useOutsideClick(
  callback: VoidFn,
  context: string | null = null
): React.MutableRefObject<any> {
  const ref = React.useRef<any>();

  React.useEffect(() => {
    const handleClick = (event: Event) => {
      if (context !== null) {
        let contextElement = getContextElement(event.target, context);

        if (
          contextElement !== null &&
          ref.current &&
          !ref.current.contains(contextElement)
        ) {
          callback();
        }
      } else {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      }
    }

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  });

  return ref;
}
