import React from 'react';

import { Box, Link, Typography } from '@mui/material';

import BackgroundImage from '@Assets/background.png';
import Competitions from '@Components/competetion/Competitions';
import Games from '@Components/games/Games';

function Home() {
  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 0,
        py:2,
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'fixed'
      }}>
        <Box sx={{
          mx: 2,
          maxWidth: 1200,
          padding: 1,
          bgcolor: '#D9D9D9B2',
          borderRadius: 4,
          backdropFilter: 'blur(5px)',
        }}>
          <Typography fontWeight='bold' gutterBottom variant='h5'>
            Was soll das?
          </Typography>
          <Typography gutterBottom variant='body1'>
            Nachdem wir mehrfach den <Link href='https://www.hackathon-leer.de/'>Hackathon Leer</Link> organisiert haben und
            immer wieder Fragen danach aufkamen, ob die dabei entstandenen Aufgaben/Spiele nicht durchgehend online
            verfügbar sein könnten, ist es jetzt endlich so weit.
          </Typography>
          <Box>
            <Typography gutterBottom variant='body1'>
              Alle Spiele funktionieren, indem die Bots der Spieler per <Link href='https://github.com/socketio/socket.io-protocol'>Socket.IO-Protokoll 5</Link> (also Socket.IO-Version 4.x) mit unserem Server kommunizieren.
              Client-Libraries dafür gibt es für viele Programmiersprachen, z.B.
            </Typography>
            <ul>
              <li><Link href='https://github.com/socketio/socket.io-client'>JavaScript</Link> (Beispiel: <Link href='https://github.com/uhno-de/games-example-SSP-javascript-nodejs-ug'>Schnick, Schnack, Schnuck von Udo</Link>)</li>
              <li><Link href='https://github.com/socketio/socket.io-client-java'>Java</Link> (Beispiel: <Link href='https://github.com/uhno-de/games-example-SSP-java-maven-bb'>Schnick, Schnack, Schnuck von Bobo</Link>)</li>
              <li><Link href='https://python-socketio.readthedocs.io/en/latest/client.html'>Python</Link> (Beispiel: <Link href='https://github.com/uhno-de/games-example-SSP-python-cb'>Schnick, Schnack, Schnuck von Carsten</Link>)</li>
              <li><Link href='https://github.com/socketio/socket.io-client-cpp'>C++</Link></li>
              <li><Link href='https://github.com/doghappy/socket.io-client-csharp'>C#</Link></li>
              <li><Link href='https://docs.rs/rust_socketio/latest/rust_socketio/'>Rust</Link></li>
              <li>...</li>
            </ul>
          </Box>
          <Typography variant='body1'>
            Also: melde dich an, gründe ein Team und leg los!
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'var(--white)' }}>
        <Box sx={{ m: '16px', maxWidth: 1200}}>
          <Typography variant='h4' sx={{ mb: 1 }}>Verfügbare Spiele</Typography>
          <Games />
        </Box>
        <Box sx={{ m: '16px', maxWidth: 1200}}>
          <Typography variant='h4' sx={{ mb: 1 }}>Turniere</Typography>
          <Competitions />
        </Box>
      </Box>
    </>
  );
}

export default React.memo(Home);
