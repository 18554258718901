type Placement = {
  placement: number;
  [key: string]: any;
};

/**
 * This function loops through all of the provided data and compares data using
 * the `comparisonKey` to define the placement (1st, 2nd or 3rd place).
 *
 * @param data
 * @param comparisonKey the key used to define the placements (e.g. the `elo`-key).
 *                      The data type of the values assigned to that key should be
 *                      of `number`.
 */
export function definePlacements<T extends Placement>(data: T[], comparisonKey: string): T[] {
  if (data.length === 0 || !Object.hasOwn(data[0], comparisonKey)) return data;

  let points: Set<number> = new Set();
  data.map((entry) => entry[comparisonKey]).forEach((value) => points.add(value));
  let pointsArray = Array.from(points.values());

  const firstPlace = Math.max(...pointsArray);
  pointsArray = pointsArray.filter((value) => value !== firstPlace);
  const secondPlace = pointsArray.length === 0 ? null : Math.max(...pointsArray);
  pointsArray = pointsArray.filter((value) => value !== secondPlace);
  const thirdPlace = pointsArray.length === 0 ? null : Math.max(...pointsArray);

  if (secondPlace === thirdPlace && thirdPlace === null) {
    data.forEach((entry) => entry.placement = 1);
  } else if (thirdPlace === null) {
    data.forEach((entry) => {
      entry.placement = entry[comparisonKey] === firstPlace ? 1 : 2;
    });
  } else {
    data.forEach((entry) => {
      if (entry[comparisonKey] === firstPlace) {
        entry.placement = 1;
      } else if (entry[comparisonKey] === secondPlace) {
        entry.placement = 2;
      } else {
        entry.placement = entry[comparisonKey] === thirdPlace ? 3 : 4;
      }
    });
  }

  return data;
}
