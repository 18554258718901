import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  AvatarGroup,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Fab,
  Tooltip,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import LoadingSpinner from '@Components/loading/LoadingSpinner';
import ProfilePicture from '@Components/profile/ProfilePicture';
import { useJsonApiConnector, useLogger } from '@Hooks';

function Teams() {
  const logger = useLogger();
  const jsonApiConnector = useJsonApiConnector();

  const [{
    data: teams,
    loading,
    error
  }] = jsonApiConnector.teamManagement.readAll();

  if (!loading) {
    logger.info('Teams from the database: ', teams);
  }

  if (loading || !teams || error) {
    if (error) logger.error('Fetching teams from the database', error);

    return <LoadingSpinner sx={{ m: '0 auto' }} />;
  }

  return (
    <>
      <Typography variant='h3' textAlign='center' sx={{p: '16px 0'}}>Meine Teams</Typography>
        <Box display='grid' gridTemplateColumns='repeat( auto-fill, minmax(320px, 1fr) )' gap={2} sx={{m: '0px auto', maxWidth: '1200px', alignItems: 'center'}}>
          {
            teams.map((team) => (
              <Card key={team.id}>
                <CardActionArea  component={RouterLink} to={`/user/teams/${team.id}`}>
                  <CardContent sx={{
                    height: '240px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#FEFEFE'
                  }}>
                    <Typography variant='h5' component='div'>
                      {team.name}
                    </Typography>
                    <Typography gutterBottom variant='subtitle1'>
                      {team.competition}
                    </Typography>
                    <Typography gutterBottom variant='body2' color='text.secondary' sx={{
                      height: 0,
                      overflow: 'hidden',
                      flexGrow: 1
                    }}>
                      {team.description}
                    </Typography>
                    <AvatarGroup max={4}>
                      {
                        team.members.map((member) => {
                          return (
                            <ProfilePicture key={ member.id } image={ member.photo } username={ member.username } />
                          );
                        })
                      }
                    </AvatarGroup>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))
          }
          <Box component={RouterLink} to='/user/teams/new' sx={{display: "flex",
            alignItems: "center",
            justifyContent: "center"}}>
            <Tooltip title='Neues Team'>
              <Fab component={RouterLink} to='/user/teams/new' color="secondary" aria-label="add">
                <AddIcon />
              </Fab>
            </Tooltip>
          </Box>
        </Box>
    </>
  )
}

export default React.memo(Teams);
