type Locale = 'de-DE';

export function formatDate(
  date: Date | string | null,
  locale: Locale = 'de-DE'
): string | null {
  if (date === null) return null;
  if (typeof date === 'string') date = new Date(date);

  return date.toLocaleDateString(locale, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  });
}
