import React from 'react';

import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Stack,
  Typography
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import StarsIcon from "@mui/icons-material/Stars";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import GroupsIcon from "@mui/icons-material/Groups";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import {useProfile} from "@Hooks";
import {logins} from "../../logins";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


function MobileNav() {
  const [
    anchorElement,
    setAnchorElement
  ] = React.useState<null | HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);

  const navigateTo = useNavigate();
  const { profile } = useProfile();
  const isLoggedIn = profile !== undefined;
  const isManager = !!(profile && profile?.competitionmanager);


  function handleClick(event: React.MouseEvent<HTMLDivElement>): void {
    setAnchorElement(event.currentTarget);
  }

  function handleClose(): void {
    setAnchorElement(null);
    setOpen(false);
  }

  function handleLogin(): void {
    setOpen(true);
  }

  function handleMenuItemClick(route: string): void {
    handleClose();
    navigateTo(route);
  }

  return (
    <Stack spacing={'8px'}>
      <Box onClick={() => navigateTo('/')} sx={{
        columnGap: '8px',
        display: 'flex',
        cursor: 'pointer',
        textAlign: 'center'
      }}>
        <HomeIcon />
        <Typography>Startseite</Typography>
      </Box>

      <div onClick={ handleClick } color='inherit' role='button' style={{
        columnGap: '8px',
        display: 'flex',
        cursor: 'pointer'
      }}>
        <StarsIcon />
        <Typography>Ranglisten</Typography>
        {
          Boolean(anchorElement) ? <ExpandLessIcon /> : <ExpandMoreIcon />
        }
      </div>
      <Menu
        open={ Boolean(anchorElement) }
        anchorEl={ anchorElement }
        onClose={ handleClose }
        sx={{ mt: '8px' }}
      >
        <MenuItem onClick={() => handleMenuItemClick('/ranking/teams')}>
          <Box sx={{ display: 'flex', columnGap: '8px' }}>
            <LeaderboardIcon />
            <Typography>Team-Rangliste</Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('/ranking/users')}>
          <Box sx={{ display: 'flex', columnGap: '8px' }}>
            <LeaderboardIcon />
            <Typography>Spieler-Rangliste</Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('/ranking/bots')}>
          <Box sx={{ display: 'flex', columnGap: '8px' }}>
            <LeaderboardIcon />
            <Typography>Bot-Rangliste</Typography>
          </Box>
        </MenuItem>
      </Menu>

      {
        isLoggedIn ? (
          <>
            <Box onClick={() => navigateTo('/user/teams')} sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: '8px',
              cursor: 'pointer'
            }}>
              <GroupsIcon />
              <Typography>Meine Teams</Typography>
            </Box>
            {
              isManager ? (
                <Box onClick={() => navigateTo('/user/competitions')} sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '8px',
                  cursor: 'pointer'
                }}>
                  {/* <GroupsIcon /> */}
                  <Typography>Meine Turniere</Typography>
                </Box>
              ) : <></>
            }
          </>
        ) : <></>
      }
      {
        isLoggedIn ? (
          <Button
            variant='contained'
            color='secondary'
            href='/auth/logout'
            sx={{
              borderRadius: '8px',
              columnGap: '8px'
            }}
          >
            <LogoutIcon />
            Abmelden
          </Button>
        ) : (
          <Button
            variant='contained'
            color='secondary'
            onClick={() => handleLogin()}
            sx={{
              borderRadius: '8px',
              columnGap: '8px'
            }}
          >
            <LoginIcon />
            Anmelden
          </Button>
        )
      }
      <Dialog PaperProps={{
        style: {
          maxWidth: '200p',
          backgroundColor: "white",
        },
      }} open={open} onClose={handleClose}>
        <DialogTitle>Anmelden via...</DialogTitle>
        <DialogContent dividers>
          {
            logins.map(({ id, title, icon, disabled }) => (
              <Button fullWidth={true} key={id} variant='contained'
                      disabled={disabled}
                      sx={{my: '8px'}}
                      href={`/auth/${id}/login`}
                      startIcon={<FontAwesomeIcon icon={icon}/>}
              >
                {title}
              </Button>
            ))
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default React.memo(MobileNav);
