import React from 'react';

import { Profile } from '@Models/profile';
import { Nullable, Undefinable } from '@Types';

export type ProfileContextData = {
  profile: Undefinable<Profile>;
  profileLoading: boolean;
  profileError: any;
  profileReload: any;
};

export const ProfileContext = React.createContext<Nullable<ProfileContextData>>(
  null
);
