import { Filter } from '@Components/ranking/RankingFilter';
import { Group, GrouperFn, GroupingService, getKeyForChar } from '@Services/grouping-service';

export type User = {
  id: string;
  username: string;
  photo: string | null;
  elo: number;
  placement: number;
};

export function groupAndSortByUserameAlphabetical(reverse: boolean): GrouperFn<User> {
  return (users: User[]): Group<User>[] => {
    const letterMapping: Map<string, User[]> = new Map<string, User[]>();
    const groups: Group<User>[] = [];

    users.forEach((user) => {
      let key = getKeyForChar(user.username.charAt(0).toLocaleUpperCase('de-DE'));
      const mappedUsers: User[] = letterMapping.get(key) || [];
      mappedUsers.push(user);
      letterMapping.set(key, mappedUsers);
    });

    letterMapping.forEach((value, key) => {
      groups.push({
        label: key,
        items: value
      });
    });

    groups.sort((a, b) => a.label.localeCompare(b.label, 'de-DE'));
    groups.forEach((group) => {
      group.items.sort((a, b) => a.username.localeCompare(b.username, 'de-DE'));

      if (reverse === true) group.items.reverse();
    });

    if (reverse === true) groups.reverse();

    return groups;
  }
}

export function groupAndSortByElo(reverse: boolean): GrouperFn<User> {
  const groupingService = new GroupingService();

  return (users: User[]): Group<User>[] => {
    const groups: Group<User>[] = groupingService.createDefaultGroup(users);

    groups[0].items.sort((a, b) => {
      if (a.elo === b.elo) {
        return a.username.localeCompare(b.username, 'de-DE');
      }

      return b.elo - a.elo;
    });

    if (reverse === true) groups[0].items.reverse();

    return groups;
  };
}

export type FilterWithGrouper = Filter & {
  groupAndSort: GrouperFn<User>;
};

export const FILTERS: FilterWithGrouper[] = [{
  id: 'name-asc',
  label: 'Spielername alphabetisch aufsteigend',
  active: false,
  groupAndSort: groupAndSortByUserameAlphabetical(false)
}, {
  id: 'name-desc',
  label: 'Spielername alphabetisch absteigend',
  active: false,
  groupAndSort: groupAndSortByUserameAlphabetical(true)
}, {
  id: 'elo-asc',
  label: 'Punktzahl (Elo) aufsteigend',
  active: false,
  groupAndSort: groupAndSortByElo(true)
}, {
  id: 'elo-desc',
  label: 'Punktzahl (Elo) absteigend',
  active: true,
  groupAndSort: groupAndSortByElo(false)
}];
