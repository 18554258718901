import React from 'react';

import { Box } from '@mui/material';
import { Chessboard } from 'react-chessboard';
import { Piece, Square } from 'react-chessboard/dist/chessboard/types';
import { Chess as ChessGame } from 'chess.js';

import { useLogger } from '@Hooks/use-logger';

function Chess() {
  const [game, setGame] = React.useState(new ChessGame());
  const logger = useLogger();

  function performMove(move: { from: Square; to: Square; }) {
    const gameCopy = { ...game } as any;
    const result = gameCopy.move(move);
    setGame(gameCopy);

    return result; // null if the move was illegal, the move object if the move was legal
  }

  function onPieceDrop(source: Square, target: Square, piece: Piece): boolean {
    const move = performMove({
      from: source,
      to: target
    });

    logger.info(`Trying to move ${piece} from ${source} to ${target}.`);

    if (move === null) {
      logger.error(`Couldn't move ${piece} from ${source} to ${target}!`);

      return false;
    }

    logger.info(`Successfully moved ${piece} from ${source} to ${target}!`);

    return true;
  }

  return (
    <Box sx={{ p: '16px', m: '0 auto', width: '832px' }}>
      <Chessboard position={game.fen()} boardWidth={800} onPieceDrop={onPieceDrop} />
    </Box>
  )
}

export default React.memo(Chess);
