import React from 'react';

import { Box, SxProps, Theme, Typography } from '@mui/material';

import { useLogger } from '@Hooks/use-logger';
import ClickableField from './ClickableField';

function Mill() {
  const [currentPlayer, setCurrentPlayer] = React.useState(0);
  const logger = useLogger();

  function handleFieldSelection(pos: number[]) {
    logger.info('Clicked position:', pos);
    setCurrentPlayer((currentPlayer + 1) % 2);
  }

  const defaultStyles: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
    background: 'transparent',
    width: 'inherit',
  };

  const pieceBeige: any = {
    backgroundColor: 'rgb(216, 196, 175)',
    background: 'radial-gradient(closest-side, rgb(216, 196, 175) 0, rgb(216, 196, 175) 15%, rgb(196, 173, 151) 24%, rgb(216, 196, 175) 39%, rgb(216, 196, 175) 53%, rgb(196, 173, 151) 69%, rgb(216, 196, 175) 81%, rgb(216, 196, 175) 100%)',
    border: 'groove rgb(216, 196, 175) 1px',
    width: '64px',
    height: '64px',
    boxShadow: '0 4px 0 0 rgb(196, 173, 151)',
  };

  const pieceBlack: any = {
    backgroundColor: 'rgba(0,0,0,1)',
    background: 'radial-gradient(closest-side, rgba(40,40,40,1) 0, rgba(40,40,40,1) 15%, rgba(0,0,0,1) 24%, rgba(40,40,40,1) 39%, rgba(40,40,40,1) 53%, rgba(0,0,0,1) 69%, rgba(40,40,40,1) 81%, rgba(40,40,40,1) 100%)',
    border: 'groove rgba(40,40,40,1) 1px',
    width: '64px',
    height: '64px',
    boxShadow: '0 4px 0 0 rgba(0,0,0,1)',
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '0 auto'}}>
        <Box sx={{ ...pieceBeige, margin: '8px', borderRadius: '50%'}}></Box>
        <Box sx={{padding: '16px 32px 16px 0'}}>
          <Typography variant='h3' textAlign='center'>Bot 1</Typography>
          <Typography variant='body1' textAlign='center'>Team Eins</Typography>
        </Box>
        <Box sx={{padding: '16px 0 16px 32px'}}>
          <Typography variant='h3' textAlign='center'>Bot 2</Typography>
          <Typography variant='body1' textAlign='center'>Team Zwei</Typography>
        </Box>
        <Box sx={{ ...pieceBlack, margin: '8px', borderRadius: '50%'}}></Box>
      </Box>

      <Box sx={{ minWidth: '624px', maxWidth: '624px', minHeight: '624px', maxHeight: '624px', display: 'flex', flexDirection: 'column', margin: '16px auto 0', rowGap: '52px', background: '#fff0c1' }}>
        <Box sx={{ position: 'absolute', width: '586px', height: '586px', m: '20px', border: '4px solid #000', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ position: 'absolute', width: '386px', height: '386px', border: '4px solid #000', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ zIndex: 1, background: '#fff0c1', position: 'absolute', width: '186px', height: '186px', border: '4px solid #000' }}></Box>
            {/* Ab hier kommen die Schaltflächen für das mittlerste Quadrat */}
            <Box display='flex' flexDirection='column' justifyContent='space-between' width='221px' height='221px'>
              <Box sx={ defaultStyles }>
                <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([2, 0])} />
                <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([2, 1])} />
                <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([2, 2])} />
              </Box>
              <Box sx={ defaultStyles }>
                <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([2, 7])} />
                <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([2, 3])} />
              </Box>
              <Box sx={ defaultStyles }>
                <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([2, 6])} />
                <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([2, 5])} />
                <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([2, 4])} />
              </Box>
            </Box>
          </Box>
          <Box sx={{ position: 'absolute', border: '2px solid #000', height: '586px' }}></Box>
          <Box sx={{ position: 'absolute', border: '2px solid #000', width: '586px' }}></Box>
          {/* Ab hier kommen die Schaltflächen für das erste innere Quadrat */}
          <Box display='flex' flexDirection='column' justifyContent='space-between' width='421px' height='421px'>
            <Box sx={ defaultStyles }>
              <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([1, 0])} />
              <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([1, 1])} />
              <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([1, 2])} />
            </Box>
            <Box sx={ defaultStyles }>
              <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([1, 7])} />
              <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([1, 3])} />
            </Box>
            <Box sx={ defaultStyles }>
              <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([1, 6])} />
              <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([1, 5])} />
              <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([1, 4])} />
            </Box>
          </Box>
        </Box>
        {/* Ab hier kommen die Schaltflächen auf dem äußeren Rand */}
        <Box display='flex' flexDirection='column' justifyContent='space-between' width='624px' height='624px'>
          <Box sx={{ ...defaultStyles, pl: '2px' }}>
            <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([0, 0])} />
            <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([0, 1])} />
            <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([0, 2])} />
          </Box>
          <Box sx={{ ...defaultStyles, pl: '2px' }}>
            <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([0, 7])} />
            <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([0, 3])} />
          </Box>
          <Box sx={{ ...defaultStyles, pl: '2px' }}>
            <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([0, 6])} />
            <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([0, 5])} />
            <ClickableField currentPlayer={ currentPlayer } onClick={() => handleFieldSelection([0, 4])} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default React.memo(Mill);
