import React from 'react';

import { Box, Divider, ListItem, Typography } from '@mui/material';

import ProfilePicture from '../profile/ProfilePicture';
import RankingPlacement from './RankingPlacement';

type UserRankingListItemProps = {
  username: string;
  photo: string | null;
  elo: number;
  placement: number;
};

function UserRankingListItem({
  username,
  photo,
  elo,
  placement
}: UserRankingListItemProps) {
  return (
    <>
      <Divider sx={{ borderColor: 'var(--divider-color)' }} />
      <ListItem sx={{ columnGap: '16px', ":hover": { bgcolor: 'primary.50' } }}>
        <Box
          flex='calc(100% - 264px)'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: '16px'
          }}
        >
          <ProfilePicture image={ photo } />
          <Typography>{ username }</Typography>
        </Box>
        <Typography flex='200px' align='right'>{ elo }</Typography>
        <RankingPlacement placement={ placement } />
      </ListItem>
    </>
  );
}

export default React.memo(UserRankingListItem);
