import { Socket, io } from 'socket.io-client';

import { EventEmitter } from './event-emitter';
import { LoggerService } from './logger-service';

/**
 * An instance of this class can be used to establish a connection via websocket
 * to the game server. This is required to allow users to play games in the mode
 * "player vs. computer".
 */
export class PvC<T_INIT, T_ROUND, T_RESULT> extends EventEmitter<T_INIT, T_ROUND, T_RESULT> {

  private socket!: Socket;
  private secret!: string;
  private logger!: LoggerService;

  constructor(secret: string) {
    super();

    this.logger = new LoggerService();
    this.socket = io(
      window.location.origin,
      {
        transports: ['websocket'],
        autoConnect: false,
      },
    );
    this.secret = secret;

    this.socket.on('connect', () => this.onConnect());
    this.socket.on('disconnect', () => this.onDisconnect());
    this.socket.on('data', (data, callback) => this.onData(data, callback));
  }

  private onConnect(): void {
    this.emit('connection-state', true);
    this.socket.emit('authenticate', this.secret, this.handleAuthentication);
  }

  private handleAuthentication(success: boolean): void {
    this.emit('authentication-state', success);
  }

  private onDisconnect(): void {
    this.emit('connection-state', false);
  }

  private onData(data: any, callback?: (arg: any) => void): void {
    if (Object.hasOwn(data, 'type')) {
      switch (data.type) {
        case 'INIT':
          this.emit('round-init', (data as T_INIT));
          break;
        case 'RESULT':
          this.emit('round-ended', (data as T_RESULT));
          break;
        case 'ROUND':
        case 'SET':
          this.emit('round-state', ([data, callback] as T_ROUND));
          break;
        default:
          this.logger.error('expected one of the following types "INIT", "RESULT", "SET" or "ROUND"');
      }
    } else {
      this.logger.error('expected the property "type" on the data object.');
    }
  }

  /**
   * This function actually establishes the connection via websocket
   */
  connect(): void {
    this.socket.connect();
  }

  /**
   * This function disconnects from the websocket
   */
  disconnect(): void {
    this.socket.disconnect();
  }

}
