import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import md5 from 'md5';
import { useSnackbar } from 'notistack';

import { useJsonApiConnector, useProfile } from '@Hooks';
import { Account } from '@Models';
import { Nullable } from '@Types';
import PageNotFound from '@Webpages/error/PageNotFound';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import axios from 'axios';
import { handleAsyncError } from '@Utils';

function ProfileTeam() {
  const { teamId } = useParams();
  const jsonApiConnector = useJsonApiConnector();
  const navigateTo = useNavigate();
  const { profile } = useProfile();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [{
    data: team,
    error: teamError
  }, reloadTeam] = jsonApiConnector.teamManagement.read(teamId ?? '');

  const [type, setType] = React.useState<Nullable<string>>(null);
  const [name, setName] = React.useState<Nullable<string>>(null);
  const [description, setDescription] = React.useState<Nullable<string>>(null);
  const [userAction, setUserAction] = React.useState<Nullable<any>>(null);

  const [newMember, setNewMember] = React.useState<Nullable<Account>>(null)
  const [newMemberInputValue, setNewMemberInputValue] = React.useState('');
  const [{
    data: newMemberOptions
  }] = jsonApiConnector.useAxios<Account[]>(`/search/user/${newMemberInputValue}`, {
    manual: !newMemberInputValue,
  });

  const [{
    data: games,
  }] = jsonApiConnector.teamManagement.getGames(teamId as string);
  const [newBotName, setNewBotName] = React.useState<Nullable<string>>(null);
  const [newBotGame, setNewBotGame] = React.useState<Nullable<string>>(null);

  const renderType = type ?? team?.type ?? '';
  const renderName = name ?? team?.name ?? '';
  const renderDescription = description ?? team?.description ?? '';

  if (teamError?.response?.status === 404) {
    return <PageNotFound />;
  }

  if (!profile || !team) {
    return null;
  }

  return (
    <>
      <Paper sx={{ backgroundColor: '#FEFEFE', p: '16px', m: '16px auto', maxWidth: '1200px' }} elevation={3}>
        {
          !team.manager ? null : (
            <Button
              color='error'
              variant='contained'
              onClick={async () => {
                if (window.confirm(`Willst du das Team "${team?.name}" wirklich unwiderruflich löschen?`)) {
                  await handleAsyncError(
                    axios.delete(`/api/profile/team/${team.id}`),
                    enqueueSnackbar,
                    () => {
                      enqueueSnackbar('Team erfolgreich gelöscht', {
                        variant: 'success',
                      });
                    },
                  );
                  navigateTo('/user/teams');
                }
              }}
              sx={{ float: 'right', ml: 1 }}
            >
              löschen
            </Button>
          )
        }
        <Button
          color='error'
          onClick={async () => {
            if (window.confirm(`Willst du das Team "${team?.name}" wirklich verlassen?`)) {
              await handleAsyncError(
                axios.post(`/api/profile/team/${team.id}/leave`),
                enqueueSnackbar,
                () => {
                  enqueueSnackbar('Team erfolgreich verlassen', {
                    variant: 'success',
                  });
                },
              );
              navigateTo('/user/teams');
            }
          }}
          sx={{ float: 'right', ml: 1 }}
        >
          verlassen
        </Button>
        <Typography variant='h4' gutterBottom sx={{ flexGrow: 1, flexShrink: 1 }}>{ team?.name }</Typography>
        <Stack spacing={ 2 }>
          {
            !team.manager ? (
              <Typography variant='body1' color='text.secondary' gutterBottom>{team?.description}</Typography>
            ) : (
              <>
                <TextField
                  size='small'
                  type='text'
                  label='Name'
                  value={renderName}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
                <FormControl>
                  <FormLabel>Einstellung für neue Mitglieder</FormLabel>
                  <RadioGroup
                    value={renderType}
                    onChange={(event) => {
                      setType(event.target.value);
                    }}
                  >
                    <FormControlLabel value='PRIVATE' control={<Radio />} label='Beitrittsanfragen nicht erlauben'/>
                    <FormControlLabel value='PUBLIC' control={<Radio />} label='Beitrittsanfragen erlauben'/>
                    <FormControlLabel value='AUTOACCEPT' control={<Radio />} label='Jeder darf beitreten'/>
                  </RadioGroup>
                </FormControl>
                <TextField
                  size='small'
                  type='text'
                  label='Beschreibung'
                  multiline
                  rows={5}
                  value={renderDescription}
                  onChange={(event) => {
                    setDescription(event.target.value);
                  }}
                />
                <Box sx={{
                  display: 'flex',
                  gridAutoFlow: 'column',
                  gridGap: theme.spacing(2),
                }}>
                  <Box sx={{ flexGrow: 1, flexShrink: 1 }} />
                  <Button
                    variant='contained'
                    disabled={!renderName}
                    onClick={async () => {
                      await handleAsyncError(
                        axios.post(`/api/profile/team/${team.id}`, {
                          type: renderType,
                          name: renderName,
                          description: renderDescription,
                        }),
                        enqueueSnackbar,
                        () => {
                          enqueueSnackbar('Änderungen gespeichert', {
                            variant: 'success',
                          });
                        },
                      );
                    }}
                  >
                    speichern
                  </Button>
                </Box>
              </>
            )
          }
        </Stack>
        <Table size='small' sx={{ mt: 2, ml: -1, width: `calc(100% + ${theme.spacing(2)})` }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ pl: 1, pr: 1 }} colSpan={ 2 }>Mitglieder</TableCell>
              <TableCell sx={{ pl: 1, pr: 1, width: 0 }} colSpan={ !team.manager ? 1 : 2 }>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              team.members.map((member) => {
                let statusAction = null;
                const statusText = [];
                if (member.manager) {
                  statusText.push('Manager');
                }
                if (!member.accepted_account) {
                  statusText.push('eingeladen');
                } else if (!member.accepted_team) {
                  if (team.manager) {
                    statusAction = (
                      <Button
                        variant='outlined'
                        color='success'
                        onClick={async () => {
                          await handleAsyncError(
                            axios.post(`/api/profile/team/${team.id}/accept/${member.id}`),
                            enqueueSnackbar,
                            () => {
                              enqueueSnackbar('Beitrittsanfrage erfolgreich angenommen', {
                                variant: 'success',
                              });
                            },
                          );
                          reloadTeam();
                        }}
                      >
                        annehmen
                      </Button>
                    );
                  } else {
                    statusText.push('angefragt');
                  }
                }

                return (
                  <TableRow
                    key={ member.id }
                    sx={{ '&:last-child td, &:last-child-th': { border: 0 } }}
                  >
                    <TableCell sx={{ pl: 1, pr: 1, width: 0 }}>
                      <Avatar
                        src={ member.photo ?? `https://vanillicon.com/v2/${md5(member.id)}.svg` }
                        alt={ member.username }
                        sx={{ bgcolor: 'background.default' }}
                      />
                    </TableCell>
                    <TableCell sx={{ pl: 1, pr: 1, wordBreak: 'break-word' }} component='th' scope='row'>
                      { member.username }
                    </TableCell>
                    <TableCell sx={{ pl: 1, pr: 1, width: 0 }}>
                      { statusAction ?? statusText.join(', ') }
                    </TableCell>
                    {
                      !team.manager ? null : (
                        <TableCell sx={{ pl: 1, pr: 1, width: 0 }}>
                          <IconButton
                            onClick={(ev) => {
                              setUserAction({
                                id: member.id,
                                element: ev.target,
                              });
                            }}
                          >
                            <ExpandCircleDownIcon />
                          </IconButton>
                          <Menu
                            anchorEl={ userAction?.element }
                            open={ userAction?.id === member.id }
                            onClose={() => setUserAction(null)}
                          >
                            {
                              member.manager ? (
                                <MenuItem
                                  onClick={async () => {
                                    if (member.id !== profile.id || window.confirm('Willst du dich wirklich selbst degradieren?')) {
                                      await handleAsyncError(
                                        axios.post(`/api/profile/team/${team.id}/downgrade/${member.id}`),
                                        enqueueSnackbar,
                                        () => {
                                          enqueueSnackbar('Mitglied erfolgreich degradiert', {
                                            variant: 'success',
                                          });
                                        },
                                      );
                                      reloadTeam();
                                      setUserAction(null);
                                    }
                                  }}
                                >
                                  degradieren
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  onClick={async () => {
                                    await handleAsyncError(
                                      axios.post(`/api/profile/team/${team.id}/upgrade/${member.id}`),
                                      enqueueSnackbar,
                                      () => {
                                        enqueueSnackbar('Mitglied erfolgreich befördert', {
                                          variant: 'success',
                                        });
                                      },
                                    );
                                    reloadTeam();
                                    setUserAction(null);
                                  }}
                                >
                                  befördern
                                </MenuItem>
                              )
                            }
                            {
                              member.id === profile.id ? null : (
                                <MenuItem
                                  onClick={async () => {
                                    if (window.confirm(`Willst du "${member?.username}" wirklich aus dem Team entfernen?`)) {
                                      await handleAsyncError(
                                        axios.post(`/api/profile/team/${team.id}/remove/${member.id}`),
                                        enqueueSnackbar,
                                        () => {
                                          enqueueSnackbar('Mitglied erfolgreich entfernt', {
                                            variant: 'success',
                                          });
                                        },
                                      );
                                      reloadTeam();
                                      setUserAction(null);
                                    }
                                  }}
                                >
                                  entfernen
                                </MenuItem>
                              )
                            }
                          </Menu>
                        </TableCell>
                      )
                    }
                  </TableRow>
                );
              })
            }
            {
              !team.manager ? null : (
                <TableRow>
                  <TableCell sx={{ pl: 1, pr: 1 }} colSpan={ 2 }>
                    <Autocomplete
                      getOptionLabel={(option) => option?.username}
                      filterOptions={(x) => x}
                      options={newMemberOptions ?? []}
                      autoComplete
                      includeInputInList
                      filterSelectedOptions
                      value={ newMember }
                      onChange={(ev, value) => {
                        setNewMember(value);
                      }}
                      onInputChange={(ev, value) => {
                        setNewMemberInputValue(value);
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField { ...params } size='small' label='Mitspieler suchen' fullWidth />
                        );
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li { ...props }>
                            <Grid container alignItems='center' gap={ 2 }>
                              <Grid item>
                                <Avatar
                                  src={ option.photo ?? `https://vanillicon.com/v2/${md5(option.id)}.svg` }
                                  alt={ option.username }
                                  sx={{ bgcolor: 'background.default' }}
                                />
                              </Grid>
                              <Grid item>
                                { option?.username }
                              </Grid>
                            </Grid>
                          </li>
                        );
                      }}
                      sx={{ flexGrow: 1, flexShrink: 1 }}
                    />
                  </TableCell>
                  <TableCell sx={{ pl: 1, pr: 1, width: 0 }} colSpan={ 2 }>
                    <Button
                      variant='contained'
                      fullWidth
                      disabled={!newMember}
                      onClick={async () => {
                        if (newMember) {
                          await handleAsyncError(
                            axios.post(`/api/profile/team/${team.id}/invite/${newMember.id}`),
                            enqueueSnackbar,
                            () => {
                              enqueueSnackbar('Mitglied erfolgreich eingeladen', {
                                variant: 'success',
                              });
                            },
                          );
                          setNewMember(null);
                          setNewMemberInputValue('');
                          reloadTeam();
                        }
                      }}
                    >
                      einladen
                    </Button>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
        <Table size='small' sx={{ mt: 2, ml: -1, width: `calc(100% + ${theme.spacing(2)})` }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ pl: 1, pr: 1 }}>Bot</TableCell>
              <TableCell sx={{ pl: 1, pr: 1 }}>Spiel</TableCell>
              <TableCell sx={{ pl: 1, pr: 1 }}>Secret</TableCell>
              <TableCell sx={{ pl: 1, pr: 1 }}>Status</TableCell>
              { team.manager ? <TableCell sx={{ pl: 1, pr: 1, width: 0 }}/> : null }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              team?.bots?.map((bot) => {
                return (
                  <TableRow
                    key={ bot.id }
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ pl: 1, pr: 1, wordBreak: 'break-word' }} component='th' scope='row'>
                      { bot.name }
                    </TableCell>
                    <TableCell sx={{ pl: 1, pr: 1, wordBreak: 'break-word' }}>
                      { bot.game }
                    </TableCell>
                    <TableCell sx={{ pl: 1, pr: 1, wordBreak: 'break-word' }}>
                      { bot.secret }
                    </TableCell>
                    <TableCell sx={{ pl: 1, pr: 1, wordBreak: 'break-word' }} component='th' scope='row'>
                      <Tooltip placement='left' title={ bot.last_game ? `letztes Spiel: ${bot.last_game}` : 'noch kein Spiel gespielt' }>
                        {
                          bot.online ? (
                            <Chip size='small' color='success' label='online' variant='outlined' />
                          ) : (
                            <Chip size='small' color='error' label='offline' variant='outlined' />
                          )
                        }
                      </Tooltip>
                    </TableCell>
                    {
                      !team.manager ? null : (
                        <TableCell sx={{ pl: 1, pr: 1, width: 0, textAlign: 'right' }}>
                          <IconButton
                            onClick={(event) => {
                              setUserAction({
                                id: bot.id,
                                element: event.target
                              });
                            }}
                          >
                            <ExpandCircleDownIcon/>
                          </IconButton>
                          <Menu
                            anchorEl={ userAction?.element }
                            open={ userAction?.id === bot.id }
                            onClose={() => setUserAction(null)}
                          >
                            <MenuItem
                              onClick={async () => {
                                await handleAsyncError(
                                  axios.post(`/api/profile/team/${team.id}/regenerate/${bot.id}`),
                                  enqueueSnackbar,
                                  () => {
                                    enqueueSnackbar('Neues Secret wurde erfolgreich generiert', {
                                      variant: 'success',
                                    });
                                  },
                                );
                                reloadTeam();
                                setUserAction(null);
                              }}
                            >
                              Secret regenerieren
                            </MenuItem>
                            <MenuItem
                              onClick={async () => {
                                await handleAsyncError(
                                  axios.post(`/api/profile/team/${team.id}/delete/${bot.id}`),
                                  enqueueSnackbar,
                                  () => {
                                    enqueueSnackbar('Bot erfolgreich entfernt', {
                                      variant: 'success',
                                    });
                                  },
                                );
                                reloadTeam();
                                setUserAction(null);
                              }}
                            >
                              entfernen
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      )
                    }
                  </TableRow>
                );
              })
            }
            {
              !team.manager ? null : (
                <TableRow>
                  <TableCell sx={{ pl: 1, pr: 1 }}>
                    <TextField
                      size='small'
                      fullWidth
                      type='text'
                      label='Name'
                      value={ newBotName || '' }
                      onChange={(event) => {
                        setNewBotName(event.target.value);
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ pl: 1, pr: 1 }}>
                    <FormControl size='small' fullWidth>
                      <InputLabel id='game-select-label'>Spiel</InputLabel>
                      <Select labelId='game-select-label'
                        value={newBotGame || ''}
                        onChange={(event) => {
                          setNewBotGame(event.target.value);
                        }}
                      >
                        {
                          (games ?? []).map((game) => {
                            return (
                              <MenuItem key={ game?.id } value={ game?.id }>
                                { game?.name } - { game?.subtitle }
                              </MenuItem>
                            );
                          })
                        }
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell sx={{ pl: 1, pr: 1, width: 0 }} colSpan={ 3 }>
                    <Button
                      variant='contained'
                      fullWidth
                      disabled={ !(newBotGame && newBotName) }
                      onClick={async () => {
                        await handleAsyncError(
                          axios.post(`/api/profile/team/${team.id}/bot`, {
                            game: newBotGame,
                            name: newBotName,
                          }),
                          enqueueSnackbar,
                          () => {
                            enqueueSnackbar('Bot wurde erfolgreich erstellt', {
                              variant: 'success',
                            });
                          },
                        );
                        setNewBotName(null);
                        setNewBotGame(null);
                        reloadTeam();
                      }}
                    >
                      anlegen
                    </Button>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

export default React.memo(ProfileTeam);
