interface Levels {
  [key: string]: {
    highlight: string;
    func: (...data: any[]) => void;
    debugOnly: boolean;
  }
}

type Level = 'verbose' | 'debug' | 'info' | 'warn' | 'error';

export class LoggerService {
  private levels: Levels;

  constructor() {
    this.levels = {
      verbose: {
        highlight: 'hsl(0, 0%, 46%)',
        func: console.debug,
        debugOnly: true,
      },
      debug: {
        highlight: 'hsl(238, 100%, 70%)',
        func: console.log,
        debugOnly: true,
      },
      info: {
        highlight: 'hsl(199, 100%, 70%)',
        func: console.log,
        debugOnly: false,
      },
      warn: {
        highlight: 'hsl(24, 100%, 70%)',
        func: console.log,
        debugOnly: false,
      },
      error: {
        highlight: 'hsl(0, 100%, 70%)',
        func: console.log,
        debugOnly: false,
      },
    };
  }

  log(level: Level, ...args: any[]): void {
    const { func, highlight, debugOnly } = this.levels[level];

    if (debugOnly) {
      const url = new URLSearchParams(window.location.search);

      if (url.get('debug') === null) {
        return;
      }
    }

    const params = [].slice.call(args).filter((item) => !!item).filter((item) => item !== args[0]);

    let prefix = args[0];

    if (typeof prefix !== 'string') {
      prefix = prefix.constructor.name;
    }


    func(...[
      `%c ${(new Date()).toLocaleTimeString('de-De')} %c ${level.toUpperCase()} %c ${prefix}`,
      'color:grey;font-family:monospace',
      `background-color:${highlight};color:black;font-weight:bold`,
      `color:${highlight};font-weight:bold`,
      ...params
    ]);
  }

  verbose(message?: any, ...args: any[]) {
    this.log('verbose', message, ...args);
  }

  debug(message?: any, ...args: any[]) {
    this.log('debug', message, ...args);
  }

  info(message?: any, ...args: any[]) {
    this.log('info', message, ...args);
  }

  warning(message?: any, ...args: any[]) {
    this.log('warn', message, ...args);
  }

  warn(message?: any, ...args: any[]) {
    this.warning.call(this, message, ...args);
  }

  error(message?: any, ...args: any[]) {
    this.log('error', message, ...args);
  }

  flatten(obj: object) {
    if (typeof obj !== 'object') {
      obj = { };
    }

    return JSON.parse(JSON.stringify(obj));
  }
}
