import React from 'react';
import { Outlet } from 'react-router-dom';

import { useProfile } from '@Hooks/use-profile';
import AccessDenied from '@Webpages/error/AccessDenied';

type ManagerGuardProps = {
  successElement?: JSX.Element;
};

function ManagerGuard({ successElement }: ManagerGuardProps) {
  const { profile } = useProfile();

  if (!profile || !profile.competitionmanager) {
    return <AccessDenied isLoginRequired isManagerRequired={ !!profile } />
  }

  if (!successElement) {
    return <Outlet />;
  }

  return successElement;
}

export default React.memo(ManagerGuard);
