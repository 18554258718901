import React from 'react';

import { Box, Divider, ListSubheader, SxProps, Theme } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type GroupedListItemsProps = {
  label: string;
  children: JSX.Element | JSX.Element[];
};

function GroupedListItems({ label, children }: GroupedListItemsProps) {
  const [opened, setOpened] = React.useState(true);

  function toggleGroup() {
    setOpened(!opened);
  }

  const subheaderBoxStyles: SxProps<Theme> = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none'
  };
  const subheaderStyles: SxProps<Theme> = {
    bgcolor: 'var(--white)',
    fontWeight: 'bold',
    fontSize: '1.6rem'
  };

  return (
    <Box>
      <Box
        data-testid='clickable-box'
        onClick={ toggleGroup }
        sx={ subheaderBoxStyles }
      >
        <ListSubheader sx={ subheaderStyles }>{ label }</ListSubheader>
        { opened ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
      </Box>
      <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.28)' }} />
      <Box data-testid='content-box' display={ opened ? 'block' : 'none' }>
        { children }
      </Box>
    </Box>
  );
}

export default React.memo(GroupedListItems);
