import { Filter } from '@Components/ranking/RankingFilter';
import { Group, GrouperFn, GroupingService, getKeyForChar } from '@Services/grouping-service';

export type Member = {
  id: string;
  photo: string | null;
  username: string;
};

export type Team = {
  id: string;
  name: string;
  description: string | null;
  members: Member[];
  elo: number;
  placement: number;
};

export function groupAndSortByTeamnameAlphabetical(reverse: boolean): GrouperFn<Team> {
  return (teams: Team[]): Group<Team>[] => {
    const letterMapping: Map<string, Team[]> = new Map<string, Team[]>();
    const groups: Group<Team>[] = [];

    teams.forEach((team) => {
      let key = getKeyForChar(team.name.charAt(0).toLocaleUpperCase('de-DE'));
      const mappedTeams: Team[] = letterMapping.get(key) || [];
      mappedTeams.push(team);
      letterMapping.set(key, mappedTeams);
    });

    letterMapping.forEach((value, key) => {
      groups.push({
        label: key,
        items: value
      });
    });

    groups.sort((a, b) => a.label.localeCompare(b.label, 'de-DE'));
    groups.forEach((group) => {
      group.items.sort((a, b) => a.name.localeCompare(b.name, 'de-DE'));

      if (reverse === true) group.items.reverse();
    });

    if (reverse === true) groups.reverse();

    return groups;
  }
}

export function groupAndSortByElo(reverse: boolean): GrouperFn<Team> {
  const groupingService = new GroupingService();

  return (teams: Team[]): Group<Team>[] => {
    const groups: Group<Team>[] = groupingService.createDefaultGroup(teams);

    groups[0].items.sort((a, b) => {
      if (a.elo === b.elo) {
        return a.name.localeCompare(b.name, 'de-DE');
      }

      return b.elo - a.elo;
    });

    if (reverse === true) groups[0].items.reverse();

    return groups;
  };
}

export type FilterWithGrouper = Filter & {
  groupAndSort: GrouperFn<Team>;
};

export const FILTERS: FilterWithGrouper[] = [{
  id: 'name-asc',
  label: 'Teamname alphabetisch aufsteigend',
  active: false,
  groupAndSort: groupAndSortByTeamnameAlphabetical(false)
}, {
  id: 'name-desc',
  label: 'Teamname alphabetisch absteigend',
  active: false,
  groupAndSort: groupAndSortByTeamnameAlphabetical(true)
}, {
  id: 'elo-asc',
  label: 'Punktzahl (Elo) aufsteigend',
  active: false,
  groupAndSort: groupAndSortByElo(true)
}, {
  id: 'elo-desc',
  label: 'Punktzahl (Elo) absteigend',
  active: true,
  groupAndSort: groupAndSortByElo(false)
}];
