import React from 'react';

import { ServicesContext } from '@Contexts/services-context';

export function useLogger() {
  const context = React.useContext(ServicesContext);

  if (context === null || context.logger === null) {
    throw new Error(
      'Trying to access the logger service but no instance of that service was defined!'
    );
  }

  return context.logger;
}
