import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { Box, Container, CssBaseline, GlobalStyles } from '@mui/material';
import { SnackbarProvider } from 'notistack';

import { ProfileContext } from '@Contexts/profile-context';
import Footer from '../footer/Footer';
import { LoginRequiredGuard, ManagerGuard } from '@Guards';
import { useJsonApiConnector } from '@Hooks/use-json-api-connector';
import Toolbar from '../toolbar/Toolbar';
import BattleshipDisplay from '@Webpages/games/battleship/display/Battleship';
import BattleshipPvC from '@Webpages/games/battleship/pvc/Battleship';
import BotRanking from '@Webpages/ranking/BotRanking';
import Chess from '@Webpages/games/chess/Chess';
import CompetitionDetails from '@Webpages/CompetitionDetails';
import GameDetails from '@Webpages/games/GameDetails';
import Home from '@Webpages/Home';
import MetaTicTacToeDisplay from '@Webpages/games/tic-tac-toe/display/MetaTicTacToe';
import MetaTicTacToePvC from '@Webpages/games/tic-tac-toe/pvc/MetaTicTacToe';
import Mill from '@Webpages/games/mill/Mill';
import PageNotFound from '@Webpages/error/PageNotFound';
import RockPaperScissorsDisplay from '@Webpages/games/rock-paper-scissors/display/RockPaperScissors';
import RockPaperScissorsPvC from '@Webpages/games/rock-paper-scissors/pvc/RockPaperScissors';
import TeamDetails from '@Webpages/user/TeamDetails';
import TeamRanking from '@Webpages/ranking/TeamRanking';
import Teams from '@Webpages/user/Teams';
import TicTacToePvC from '@Webpages/games/tic-tac-toe/pvc/TicTacToe';
import TicTacToeDisplay from '@Webpages/games/tic-tac-toe/display/TicTacToe';
import UserRanking from '@Webpages/ranking/UserRanking';
import NewTeam from '@Webpages/user/NewTeam';
import ProfileTeam from '@Webpages/user/ProfileTeam';
import ProfileCompetitions from '@Webpages/user/Competitions';
import ProfileCompetition from '@Webpages/user/Competition';

const globalStyles = (
  <GlobalStyles
    styles={{
      'html, body, #root': {
        height: '100%'
      },
      '*': {
        '--white': '#fefefe',
        '--secondary': '#0c3859',
        '--bg-color': '#efefef',
        '--divider-color': 'rgba(0, 0, 0, 0.28)',
        '--success': '#28A745',
        '--error': '#DC3545'
      }
    }}
  />
);

function PageLayout() {
  const location = useLocation();
  const bgcolor = location.pathname === '/' ? 'var(--secondary)' : 'var(--bg-color)';
  const jsonApiConnector = useJsonApiConnector();

  const [{
    data: profile,
    loading: profileLoading,
    error: profileError
  }, profileReload] = jsonApiConnector.profile.read(null);

  return (
    <ProfileContext.Provider value={{
      profile,
      profileLoading,
      profileError,
      profileReload
    }}>
      <SnackbarProvider maxSnack={ 3 } anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
        <CssBaseline />
        { globalStyles }
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Toolbar />
          <Container sx={{
            mt: '64px',
            mx: '0',
            mb: '0',
            p: '0 !important',
            maxWidth: '100% !important',
            flex: '1 1 auto',
            bgcolor
          }}>
            <Routes>
              <Route path='/' element={ <Home /> } />
              <Route path='/ranking/teams' element={ <TeamRanking /> } />
              <Route path='/ranking/users' element={ <UserRanking /> } />
              <Route path='/ranking/bots' element={ <BotRanking /> } />
              <Route path='/user/teams' element={
                <LoginRequiredGuard successElement={ <Teams /> } />
              }/>
              <Route path='/user/competitions' element={
                <ManagerGuard successElement={ <ProfileCompetitions /> } />
              }/>
              <Route path='/user/competitions/:competitionId' element={
                <ManagerGuard successElement={ <ProfileCompetition /> } />
              }/>
              <Route path='/user/teams/new' element={
                <LoginRequiredGuard successElement={ <NewTeam /> } />
              }/>
              <Route path='/user/teams/:teamId' element={ <ProfileTeam /> } />
              <Route path='/competition/:competitionId' element={ <CompetitionDetails /> } />
              <Route path='/game/:gameId' element={ <GameDetails /> } />
              <Route path='/team/:teamId' element={ <TeamDetails /> } />
              {/* Routes to watch the last played game */}
              <Route path='/games/mttt/display' element={ <MetaTicTacToeDisplay /> } />
              <Route path='/games/sv/display' element={ <BattleshipDisplay /> } />
              <Route path='/games/sspb/display' element={ <RockPaperScissorsDisplay includeWell /> } />
              <Route path='/games/ssp/display' element={ <RockPaperScissorsDisplay /> } />
              <Route path='/games/ttt/display' element={ <TicTacToeDisplay /> } />
              <Route path='/games/mill/display' element={ <Mill /> } />
              <Route path='/games/chess/display' element={ <Chess /> } />
              {/* Routes to play a game in player versus computer mode */}
              <Route path='/' element={ <LoginRequiredGuard /> }>
                <Route path='/games/mttt/pvc' element={ <MetaTicTacToePvC /> } />
                <Route path='/games/sv/pvc' element={ <BattleshipPvC /> } />
                <Route path='/games/sspb/pvc' element={ <RockPaperScissorsPvC includeWell /> } />
                <Route path='/games/ssp/pvc' element={ <RockPaperScissorsPvC /> } />
                <Route path='/games/ttt/pvc' element={ <TicTacToePvC /> } />
                <Route path='/games/mill/pvc' element={ <Mill /> } />
                <Route path='/games/chess/pvc' element={ <Chess /> } />
              </Route>
              <Route path='*' element={ <PageNotFound /> } />
            </Routes>
          </Container>
          <Footer />
        </Box>
      </SnackbarProvider>
    </ProfileContext.Provider>
  );
}

export default React.memo(PageLayout);
