import * as React from 'react';

import { Avatar, Tooltip } from '@mui/material';
import md5 from 'md5';

import { TeamMember } from '@Types/team-member';

type UserAvatarProps = {
  profile: TeamMember;
  tooltip?: boolean;
};

function UserAvatar({ profile, tooltip = false }: UserAvatarProps) {
  const fallbackAvatar = `https://vanillicon.com/v2/${md5(profile.id)}.svg`;

  if (!tooltip) {
    return (
      <Avatar
        src={ profile.photo ?? fallbackAvatar }
        alt={ profile.username }
        sx={{ bgcolor: 'background.default' }}
      />
    )
  }

  return (
    <Tooltip title={ profile.username } arrow data-testid='avatar-tooltip'>
      <Avatar
        src={ profile.photo ?? fallbackAvatar }
        alt={ profile.username }
        sx={{ bgcolor: 'background.default' }}
      />
    </Tooltip>
  );
}

export default React.memo(UserAvatar);
