import axios, { AxiosInstance } from 'axios';
import { UseAxios, UseAxiosResult, makeUseAxios } from 'axios-hooks';

import { EnqueueSnackbar } from 'notistack';

import {
  Bot,
  Account,
  Game,
  Competition,
  Profile,
  RankingTeam,
  RankingBot,
  Team,
  RankingUser,
} from '@Models';
import { Nullable, VoidFn } from '@Types';
import { handleAsyncError } from '@Utils/handle-async-error';

type Params = {
  [key: string]: any;
};

class Resource<T> {
  protected hook!: UseAxios;
  protected route!: string;
  protected client!: AxiosInstance;

  constructor(axiosHook: UseAxios, client: AxiosInstance, route: string) {
    this.hook = axiosHook;
    this.client = client;
    this.route = route;
  }

  read<E = T>(id: Nullable<string>, params?: Params): UseAxiosResult<E, any, any> {
    return this.hook<E>({
      url: [this.route, (id === null ? '' : `/${id}`)].join(''),
      params
    });
  }

  readAll<E = T>(params?: Params): UseAxiosResult<E[], any, any> {
    return this.hook<E[]>({
      url: this.route,
      params
    });
  }

  update(
    newValues: Params,
    errorMessageHandler: EnqueueSnackbar | null = null,
    successMessageHandler: VoidFn | null = null,
  ): Promise<any> {
    return handleAsyncError(
      axios.post(`/api/${this.route}`, newValues),
      errorMessageHandler,
      successMessageHandler,
    );
  }
}

class ResourceManagement<T> extends Resource<T> {
  acceptInvite(
    id: string,
    errorMessageHandler: EnqueueSnackbar | null = null,
    successMessageHandler: VoidFn | null = null,
  ): Promise<any> {
    return handleAsyncError(
      axios.post(`${this.route}/${id}/accept`),
      errorMessageHandler,
      successMessageHandler,
    );
  }

  declineInvite(
    id: string,
    errorMessageHandler: EnqueueSnackbar | null = null,
    successMessageHandler: VoidFn | null = null,
  ): Promise<any> {
    return handleAsyncError(
      axios.post(`${this.route}/${id}/leave`),
      errorMessageHandler,
      successMessageHandler,
    );
  }

  join(
    id: string,
    errorMessageHandler: EnqueueSnackbar | null = null,
    successMessageHandler: VoidFn | null = null,
  ): Promise<any> {
    return handleAsyncError(
      axios.post(`${this.route}/${id}/join`),
      errorMessageHandler,
      successMessageHandler,
    );
  }

  removeMember(
    id: string,
    memberId: string,
    errorMessageHandler: EnqueueSnackbar | null = null,
    successMessageHandler: VoidFn | null = null,
  ): Promise<any> {
    return handleAsyncError(
      axios.post(`${this.route}/${id}/remove/${memberId}`),
      errorMessageHandler,
      successMessageHandler,
    );
  }

  downgradeMember(
    id: string,
    memberId: string,
    errorMessageHandler: EnqueueSnackbar | null = null,
    successMessageHandler: VoidFn | null = null,
  ): Promise<any> {
    return handleAsyncError(
      axios.post(`${this.route}/${id}/downgrade/${memberId}`),
      errorMessageHandler,
      successMessageHandler,
    );
  }

  upgradeMember(
    id: string,
    memberId: string,
    errorMessageHandler: EnqueueSnackbar | null = null,
    successMessageHandler: VoidFn | null = null,
  ): Promise<any> {
    return handleAsyncError(
      axios.post(`${this.route}/${id}/upgrade/${memberId}`),
      errorMessageHandler,
      successMessageHandler,
    );
  }

  acceptMember(
    id: string,
    memberId: string,
    errorMessageHandler: EnqueueSnackbar | null = null,
    successMessageHandler: VoidFn | null = null,
  ): Promise<any> {
    return handleAsyncError(
      axios.post(`${this.route}/${id}/accept/${memberId}`),
      errorMessageHandler,
      successMessageHandler,
    );
  }

  inviteMember(
    id: string,
    memberId: string,
    errorMessageHandler: EnqueueSnackbar | null = null,
    successMessageHandler: VoidFn | null = null,
  ): Promise<any> {
    return handleAsyncError(
      axios.post(`${this.route}/${id}/invite/${memberId}`),
      errorMessageHandler,
      successMessageHandler,
    );
  }

  /**
   * The payload to be updated needs to be extended by the id of the resource.
   * That id needs to be provided as a property of `newValues`
   */
  update(
    newValues: Params & { id: string; },
    errorMessageHandler?: EnqueueSnackbar | null,
    successMessageHandler: VoidFn | null = null,
  ): Promise<any> {
    const id = newValues.id;
    const payload: Params = { ...newValues };
    delete payload.id;

    return handleAsyncError(
      axios.post(`${this.route}/${id}`, payload),
      errorMessageHandler,
      successMessageHandler,
    );
  }
}

class CompetitionManagement extends ResourceManagement<Competition> {
  getSelection(): UseAxiosResult<Competition[], any, any> {
    return this.hook<Competition[]>({
      url: `${this.route}/selection`,
    });
  }
}

class TeamManagement extends ResourceManagement<Team> {
  createBot(
    id: string,
    payload: Params,
    errorMessageHandler: EnqueueSnackbar | null = null,
    successMessageHandler: VoidFn | null = null,
  ): Promise<any> {
    return handleAsyncError(
      axios.post(`${this.route}/${id}/bot`, payload),
      errorMessageHandler,
      successMessageHandler,
    );
  }

  regenerateSecret(
    id: string,
    botId: string,
    errorMessageHandler: EnqueueSnackbar | null = null,
    successMessageHandler: VoidFn | null = null,
  ): Promise<any> {
    return handleAsyncError(
      axios.post(`${this.route}/${id}/regenerate/${botId}`),
      errorMessageHandler,
      successMessageHandler,
    );
  }

  deleteBot(
    id: string,
    botId: string,
    errorMessageHandler: EnqueueSnackbar | null = null,
    successMessageHandler: VoidFn | null = null,
  ): Promise<any> {
    return handleAsyncError(
      axios.post(`${this.route}/${id}/delete/${botId}`),
      errorMessageHandler,
      successMessageHandler,
    );
  }

  getGames(teamId: string): UseAxiosResult<Game[], any, any> {
    return this.hook<Game[]>(`${this.route}/${teamId}/game`);
  }
}

export class JsonApiConnector {
  useAxios!: UseAxios;
  client!: AxiosInstance;
  /**
   * This resource refers to the `teams` and allows loading a specific team or
   * all of them.
   */
  teams!: Resource<Team>;
  /**
   * This resource refers to the `games` and allows loading a specific game or
   * all of them.
   */
  games!: Resource<Game>;
  /**
   * This resource refers to the ranking data of all teams.
   */
  rankingTeams!: Resource<RankingTeam>;
  /**
   * This resource refers to the ranking data of all users.
   */
  rankingUsers!: Resource<RankingUser>;
  /**
   * This resource refers to the `competitions` and allows loading a specific
   * competition or all of them.
   */
  competitions!: Resource<Competition>;
  /**
   * This resource refers to the profile of an user. It allows loading a
   * specific profile or updating it's data.
   */
  profile!: Resource<Profile>;
  /**
   * This resource refers to the ranking data of all bots.
   */
  rankingBots!: Resource<RankingBot>;
  /**
   * This resource refers to the states of a game, which is needed to visualize
   * the progress of that game.
   */
  displayGame!: Resource<any>;
  /**
   * This resource refers to the competitions of an user. It allows an user to
   * manage a competition in the sense of managing members and it's base details.
   */
  competitionManagement!: CompetitionManagement;
  /**
   * This resource refers to the teams of an user. It allows an user to
   * manage a team in the sense of managing members, bots and it's base details.
   */
  teamManagement!: TeamManagement;
  /**
   * This resource allows to query users by using the `load`-method. Instead of
   * providing an id the query needs to be provided.
   */
  userSearch!: Resource<Account>;
  /**
   * This resource refers to the `bots` and allows loading a specific bot or all
   * of them.
   */
  bots!: Resource<Bot>;

  constructor() {
    this.client = axios.create({
      baseURL: '/api',
      headers: {
        'Content-Type': 'application/vnd.api+json'
      }
    });
    this.useAxios = makeUseAxios({
      cache: false,
      axios: this.client
    });

    this.teams = new Resource<Team>(this.useAxios, this.client, 'team');
    this.userSearch = new Resource<Account>(this.useAxios, this.client, 'search/user');
    this.rankingTeams = new Resource<RankingTeam>(this.useAxios, this.client, 'ranking/team');
    this.rankingUsers = new Resource<RankingUser>(this.useAxios, this.client, 'ranking/user');
    this.rankingBots = new Resource<RankingBot>(this.useAxios, this.client, 'ranking/bot');
    this.games = new Resource<Game>(this.useAxios, this.client, 'game');
    this.competitions = new Resource<Competition>(this.useAxios, this.client, 'competition');
    this.profile = new Resource<Profile>(this.useAxios, this.client, 'profile');
    this.displayGame = new Resource<any>(this.useAxios, this.client, 'display');
    this.competitionManagement = new CompetitionManagement(this.useAxios, this.client, 'profile/competition');
    this.teamManagement = new TeamManagement(this.useAxios, this.client, 'profile/team');
    this.bots = new Resource<Bot>(this.useAxios, this.client, 'bot');
  }
}
