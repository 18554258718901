import React from 'react';

import { ListItem, Typography } from '@mui/material';

type NoDataFoundProps = {
  game: string;
  competition: string;
};

function NoDataFound({ game, competition }: NoDataFoundProps) {
  return (
    <ListItem sx={{ columnGap: '16px', ':hover': { bgcolor: 'primary.50' } }}>
      <Typography>
        Mit der aktuellen Konstellation von Spiel ({ game }) und Turnier
        ({ competition }) konnten keine Daten gefunden werden.
      </Typography>
    </ListItem>
  );
}

export default React.memo(NoDataFound);
