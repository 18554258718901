import React from 'react';

type ClickableFieldInput = {
  currentPlayer: number;
};

interface ClickableFieldOutput {
  onClick: () => void;
}

type ClickableFieldProps = ClickableFieldInput & ClickableFieldOutput;

const COMMMON_STYLES: React.CSSProperties = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  cursor: 'pointer',
  zIndex: 1,
  border: 'none',
};

/**
 * [0]: Player 1
 * [1]: Player 2
 * [2]: Styles if no player has clicked
 */
const PLAYER_STYLES: React.CSSProperties[] = [{
  background: 'radial-gradient(closest-side, rgb(216, 196, 175) 0, rgb(216, 196, 175) 15%, rgb(196, 173, 151) 24%, rgb(216, 196, 175) 39%, rgb(216, 196, 175) 53%, rgb(196, 173, 151) 69%, rgb(216, 196, 175) 81%, rgb(216, 196, 175) 100%)',
  boxShadow: '0 4px 0 0 rgb(196, 173, 151)',
  ...COMMMON_STYLES,
}, {
  background: 'radial-gradient(closest-side, rgba(40,40,40,1) 0, rgba(40,40,40,1) 15%, rgba(0,0,0,1) 24%, rgba(40,40,40,1) 39%, rgba(40,40,40,1) 53%, rgba(0,0,0,1) 69%, rgba(40,40,40,1) 81%, rgba(40,40,40,1) 100%)',
  boxShadow: '0 4px 0 0 rgba(0,0,0,1)',
  ...COMMMON_STYLES,
}, {
  background: 'none',
  ...COMMMON_STYLES,
}];

function ClickableField({ onClick, currentPlayer }: ClickableFieldProps) {
  const [style, setStyle] = React.useState(PLAYER_STYLES[2]);

  return (
    <button style={ style } onClick={() => {
      setStyle(PLAYER_STYLES[currentPlayer]);
      onClick();
    }}></button>
  )
}

export default React.memo(ClickableField);
