import './index.css';
import React from 'react';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

import PageLayout from '@Components/layout/PageLayout';
import { ServicesContext, SERVICES } from '@Contexts/services-context';
import reportWebVitals from './reportWebVitals';
import { THEME } from './theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={ THEME }>
      <ServicesContext.Provider value={ SERVICES }>
        <BrowserRouter>
          <PageLayout />
        </BrowserRouter>
      </ServicesContext.Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
