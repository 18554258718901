import { Group, GrouperFn, GroupingService, getKeyForChar } from '@Services/grouping-service';
import { RankingBot } from '@Models';
import { FilterWithGrouper } from '../types';

export namespace BotGroupAndSort {
  export type Bot = RankingBot & {
    placement: number;
  };

  export function groupAndSortByTeamnameAlphabetical(reverse: boolean): GrouperFn<Bot> {
    return (items: Bot[]): Group<Bot>[] => {
      const letterMapping: Map<string, Bot[]> = new Map<string, Bot[]>();
      const groups: Group<Bot>[] = [];

      items.forEach((bot) => {
        let key = getKeyForChar(bot.team.charAt(0).toLocaleUpperCase('de-DE'));
        const mappedBots: Bot[] = letterMapping.get(key) || [];
        mappedBots.push(bot);
        letterMapping.set(key, mappedBots);
      });

      letterMapping.forEach((value, key) => {
        groups.push({
          label: key,
          items: value
        });
      });

      groups.sort((a, b) => a.label.localeCompare(b.label, 'de-DE'));
      groups.forEach((group) => {
        group.items.sort((a, b) => a.team.localeCompare(b.team, 'de-DE'));

        if (reverse === true) group.items.reverse();
      });

      if (reverse === true) groups.reverse();

      return groups;
    }
  }

  export function groupAndSortByBotnameAlphabetical(reverse: boolean): GrouperFn<Bot> {
    return (items: Bot[]): Group<Bot>[] => {
      const letterMapping: Map<string, Bot[]> = new Map<string, Bot[]>();
      const groups: Group<Bot>[] = [];

      items.forEach((bot) => {
        let key = getKeyForChar(bot.name.charAt(0).toLocaleUpperCase('de-DE'));
        const mappedBots: Bot[] = letterMapping.get(key) || [];
        mappedBots.push(bot);
        letterMapping.set(key, mappedBots);
      });

      letterMapping.forEach((value, key) => {
        groups.push({
          label: key,
          items: value
        });
      });

      groups.sort((a, b) => a.label.localeCompare(b.label, 'de-DE'));
      groups.forEach((group) => {
        group.items.sort((a, b) => a.name.localeCompare(b.name, 'de-DE'));

        if (reverse === true) group.items.reverse();
      });

      if (reverse === true) groups.reverse();

      return groups;
    }
  }

  export function groupAndSortByElo(reverse: boolean): GrouperFn<Bot> {
    const groupingService = new GroupingService();

    return (items: Bot[]): Group<Bot>[] => {
      const groups: Group<Bot>[] = groupingService.createDefaultGroup(items);

      groups[0].items.sort((a, b) => {
        if (a.elo === b.elo) {
          return a.team.localeCompare(b.team, 'de-DE');
        }

        return b.elo - a.elo;
      });

      if (reverse === true) groups[0].items.reverse();

      return groups;
    };
  }

  export const FILTERS: FilterWithGrouper<Bot>[] = [{
    id: 'bot-name-asc',
    label: 'Botname alphabetisch aufsteigend',
    active: false,
    groupAndSort: groupAndSortByBotnameAlphabetical(false)
  }, {
    id: 'bot-name-desc',
    label: 'Botname alphabetisch absteigend',
    active: false,
    groupAndSort: groupAndSortByBotnameAlphabetical(true)
  }, {
    id: 'team-name-asc',
    label: 'Teamname alphabetisch aufsteigend',
    active: false,
    groupAndSort: groupAndSortByTeamnameAlphabetical(false)
  }, {
    id: 'team-name-desc',
    label: 'Teamname alphabetisch absteigend',
    active: false,
    groupAndSort: groupAndSortByTeamnameAlphabetical(true)
  }, {
    id: 'elo-asc',
    label: 'Punktzahl (Elo) aufsteigend',
    active: false,
    groupAndSort: groupAndSortByElo(true)
  }, {
    id: 'elo-desc',
    label: 'Punktzahl (Elo) absteigend',
    active: true,
    groupAndSort: groupAndSortByElo(false)
  }];
};

