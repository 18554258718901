import React from 'react';

import { Box, Divider, List, Paper, Typography } from '@mui/material';

import BotRankingListItem from '@Components/ranking/BotRankingListItem';
import GroupedListItems from '@Components/grouped-list-items/GroupedListItems';
import LoadingSpinner from '@Components/loading/LoadingSpinner';
import NoDataFound from '@Components/ranking/NoDataFound';
import RankingFilter, { Filter } from '@Components/ranking/RankingFilter';
import { BotGroupAndSort } from './functions/bot-group-and-sort';
import { definePlacements } from './functions/define-placements';
import { isFetchingDone } from './functions/is-fetching-done';
import { useJsonApiConnector, useLogger } from '@Hooks';
import { Group } from '@Services/grouping-service';
import { CompetitionFilter, GameFilter } from './types';
import { getDeepClone } from '@Utils/deep-clone';

function BotRanking() {
  const logger = useLogger();
  const jsonApiConnector = useJsonApiConnector();
  const groupedRows = React.useRef<Group<BotGroupAndSort.Bot>[]>([]);
  const [filterBotname, setFilterBotname] = React.useState('');
  const [activeFilter, setActiveFilter] = React.useState('elo-desc');
  const [competition, setCompetition] = React.useState<CompetitionFilter>({
    id: null,
    name: 'Gesamt'
  });
  const [game, setGame] = React.useState<GameFilter>({
    id: null,
    name: 'Gesamt'
  });

  const [{
    data: rankedBots,
    error: rankedBotsError,
  }] = jsonApiConnector.rankingBots.readAll({
    competition: competition.id,
    game: game.id
  });
  const [{
    data: games,
    error: gamesError,
  }] = jsonApiConnector.games.readAll({
    competitionId: competition.id
  });
  const [{
    data: competitions,
    error: competitionsError,
  }] = jsonApiConnector.competitions.readAll();

  if (!isFetchingDone(
    [rankedBots, games, competitions],
    [rankedBotsError, gamesError, competitionsError],
  )) {
    if (rankedBotsError) {
      logger.error('Fetching bot ranking from the database'); // TODO: potentially remove at a later stage
    }

    if (gamesError) {
      logger.error('Fetching games from the database'); // TODO: potentially remove at a later stage
    }

    if (competitionsError) {
      logger.error('Fetching competitions from the database'); // TODO: potentially remove at a later stage
    }

    return <LoadingSpinner sx={{ m: '64px auto 0 auto' }} />;
  }

  let bots = definePlacements<BotGroupAndSort.Bot>((rankedBots || []).map((bot) => {
    return {
      id: bot.id,
      name: bot.name,
      team_id: bot.team_id,
      team: bot.team,
      elo: bot.elo === null ? 0 : Number(bot.elo),
      last_game: bot.last_game,
      online: bot.online,
      placement: 4
    };
  }), 'elo');

  groupedRows.current = BotGroupAndSort.FILTERS.filter((filter => filter.id === activeFilter))[0].groupAndSort(bots);

  function searchBots(searchValue: string): void {
    setFilterBotname(searchValue);
  }

  function applyFilter(filter: Filter): void {
    setActiveFilter(filter.id);
  }

  function applyGame(game: Filter): void {
    setGame({
      id: game.id === '*' ? null : game.id,
      name: game.label
    });
  }

  function applyCompetition(competition: Filter): void {
    setCompetition({
      id: competition.id === '*' ? null : competition.id,
      name: competition.label
    });

    if (competition.id !== '*') {
      setGame({
        id: null,
        name: 'Gesamt'
      });
    }
  }

  const SORT_AND_GROUP = [...BotGroupAndSort.FILTERS];
  SORT_AND_GROUP.forEach((filter) => {
    filter.active = false;
    if (filter.id === activeFilter) filter.active = true;
  });
  const GAME_FILTERS: Filter[] = [
    { id: '*', label: 'Gesamt', active: game.id === null },
    ...(games?.map((gameDB) => {
      return {
        id: gameDB.id,
        label: gameDB.name,
        active: game.id === gameDB.id
      };
    }) || [])
  ];
  const COMPETITION_FILTERS: Filter[] = [
    { id: '*', label: 'Gesamt', active: competition.id === null },
    ...(competitions?.map((competitionDB) => {
      return {
        id: competitionDB.id,
        label: competitionDB.name,
        active: competition.id === competitionDB.id
      };
    }) || [])
  ];

  return (
    <>
      <Typography variant='h3' textAlign='center' sx={{ p: '16px 0' }}>Bot-Rangliste</Typography>
      <RankingFilter
        onSearch={ searchBots }
        onFilterChange={ applyFilter }
        onGameChange={ applyGame }
        onCompetitionChange={ applyCompetition }
        searchLabel='Nach Bot suchen'
        competitions={ COMPETITION_FILTERS }
        games={ GAME_FILTERS }
        filters={ SORT_AND_GROUP }
      />
      <Paper sx={{ backgroundColor: '#FEFEFE', p: '16px', m: '16px auto', maxWidth: '1200px' }} elevation={ 3 }>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', display: 'flex', p: '16px 16px 0 16px', columnGap: '16px', fontWeight: 'bold' }}>
          <Typography flex='40%' fontWeight='inherit'>Botname</Typography>
          <Typography flex='calc(60% - 364px)' fontWeight='inherit'>Teamname</Typography>
          <Typography flex='100px' align='right' fontWeight='inherit'>Status</Typography>
          <Typography flex='200px' align='right' fontWeight='inherit'>Punktzahl (Elo)</Typography>
          <Typography flex='64px' align='center' fontWeight='inherit'>Platz</Typography>
        </Box>
        <List sx={{ maxWidth: '1200px', m: '0 auto' }}>
          {
            (groupedRows.current.length === 0 || (groupedRows.current.length === 1 && groupedRows.current[0].items.length === 0)) ?
              <NoDataFound game={ game.name } competition={ competition.name } />
            : getDeepClone(groupedRows.current).filter((value) => {
                value.items = value.items.filter((bot) => {
                  return bot.name.toLocaleLowerCase().startsWith(filterBotname.toLocaleLowerCase());
                });

                return value.items.length > 0;
              }).map((row, groupIndex) => {
                const { label, items } = row;

                if (label.length === 0) {
                  return items.map((bot) => {
                    return (
                      <BotRankingListItem
                        key={ bot.id }
                        botName={ bot.name }
                        teamName={ bot.team }
                        lastGame={ bot.last_game }
                        isOnline={ bot.online }
                        elo={ bot.elo }
                        placement={ bot.placement }
                        teamId={ bot.team_id }
                      />
                    );
                  });
                }

                return (
                  <Box key={ groupIndex }>
                    <Divider sx={{ borderColor: 'rgba(0,0,0,0.28)' }} />
                    <GroupedListItems label={ label }>
                      {
                        items.map((bot) => {
                          return (
                            <BotRankingListItem
                              key={ bot.id }
                              botName={ bot.name }
                              teamName={ bot.team }
                              lastGame={ bot.last_game }
                              isOnline={ bot.online }
                              elo={ bot.elo }
                              placement={ bot.placement }
                              teamId={ bot.team_id }
                            />
                          );
                        })
                      }
                    </GroupedListItems>
                  </Box>
                );
              })
          }
        </List>
      </Paper>
    </>
  );
}

export default React.memo(BotRanking);
