import React from 'react';

import { Box, CircularProgress, SxProps, Theme } from '@mui/material';

type LoadingSpinnerProps = {
  sx?: SxProps<Theme>;
}

function LoadingSpinner({ sx = {} }: LoadingSpinnerProps) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress color='inherit' sx={{ ...sx }} />
    </Box>
  );
}

export default React.memo(LoadingSpinner);
