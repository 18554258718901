import React from 'react';

import { Box, List, Paper, Typography } from '@mui/material';

import GroupedListItems from '@Components/grouped-list-items/GroupedListItems';
import LoadingSpinner from '@Components/loading/LoadingSpinner';
import NoDataFound from '@Components/ranking/NoDataFound';
import RankingFilter, { Filter } from '@Components/ranking/RankingFilter';
import UserRankingListItem from '@Components/ranking/UserRankingListItem';
import { definePlacements } from './functions/define-placements';
import { FILTERS, User } from './functions/user-group-and-sort';
import { isFetchingDone } from './functions/is-fetching-done';
import { useJsonApiConnector, useLogger } from '@Hooks';
import { Group } from '@Services/grouping-service';
import { CompetitionFilter, GameFilter } from './types';
import { getDeepClone } from '@Utils/deep-clone';

function UserRanking() {
  const logger = useLogger();
  const jsonApiConnector = useJsonApiConnector();
  const groupedRows = React.useRef<Group<User>[]>([]);
  const [filterUsername, setFilterUsername] = React.useState('');
  const [activeFilter, setActiveFilter] = React.useState('elo-desc');
  const [competition, setCompetition] = React.useState<CompetitionFilter>({
    id: null,
    name: 'Gesamt'
  });
  const [game, setGame] = React.useState<GameFilter>({
    id: null,
    name: 'Gesamt'
  });

  const [{
    data: rankedUsers,
    error: rankedUsersError,
  }] = jsonApiConnector.rankingUsers.readAll({
    competition: competition.id,
    game: game.id
  });
  const [{
    data: games,
    error: gamesError,
  }] = jsonApiConnector.games.readAll({
    competitionId: competition.id
  });
  const [{
    data: competitions,
    error: competitionsError,
  }] = jsonApiConnector.competitions.readAll();

  if (!isFetchingDone(
    [rankedUsers, games, competitions],
    [rankedUsersError, gamesError, competitionsError],
  )) {
    if (rankedUsersError) {
      logger.error('Fetching user ranking from the database'); // TODO: potentially remove at a later stage
    }

    if (gamesError) {
      logger.error('Fetching games from the database'); // TODO: potentially remove at a later stage
    }

    if (competitionsError) {
      logger.error('Fetching competitions from the database'); // TODO: potentially remove at a later stage
    }

    return <LoadingSpinner sx={{ m: '64px auto 0 auto' }} />;
  }

  let users = definePlacements<User>((rankedUsers || []).map((user) => {
    return {
      id: user.id,
      username: user.username,
      photo: user.photo,
      elo: user.elo === null ? 0 : Number(user.elo),
      placement: 4
    };
  }), 'elo');

  groupedRows.current = FILTERS.filter((filter => filter.id === activeFilter))[0].groupAndSort(users);

  function searchUsers(searchValue: string): void {
    setFilterUsername(searchValue);
  }

  function applyFilter(filter: Filter): void {
    setActiveFilter(filter.id);
  }

  function applyGame(game: Filter): void {
    setGame({
      id: game.id === '*' ? null : game.id,
      name: game.label
    });
  }

  function applyCompetition(competition: Filter): void {
    setCompetition({
      id: competition.id === '*' ? null : competition.id,
      name: competition.label
    });

    if (competition.id !== '*') {
      setGame({
        id: null,
        name: 'Gesamt'
      });
    }
  }

  const SORT_AND_GROUP = [...FILTERS];
  SORT_AND_GROUP.forEach((filter) => {
    filter.active = false;
    if (filter.id === activeFilter) filter.active = true;
  });
  const GAME_FILTERS: Filter[] = [
    { id: '*', label: 'Gesamt', active: game.id === null },
    ...(games?.map((gameDB) => {
      return {
        id: gameDB.id,
        label: gameDB.name,
        active: game.id === gameDB.id
      };
    }) || [])
  ];
  const COMPETITION_FILTERS: Filter[] = [
    { id: '*', label: 'Gesamt', active: competition.id === null },
    ...(competitions?.map((competitionDB) => {
      return {
        id: competitionDB.id,
        label: competitionDB.name,
        active: competition.id === competitionDB.id
      };
    }) || [])
  ];

  return (
    <>
      <Typography variant='h3' textAlign='center' sx={{ p: '16px 0' }}>Spieler-Rangliste</Typography>
      <RankingFilter
        onSearch={ searchUsers }
        onFilterChange={ applyFilter }
        onGameChange={ applyGame }
        onCompetitionChange={ applyCompetition }
        searchLabel='Nach Spieler suchen'
        competitions={ COMPETITION_FILTERS }
        games={ GAME_FILTERS }
        filters={ SORT_AND_GROUP }
      />
      <Paper sx={{ backgroundColor: '#FEFEFE', p: '16px', m: '16px auto', maxWidth: '1200px' }} elevation={ 3 }>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', display: 'flex', p: '16px 16px 0 16px', columnGap: '16px', fontWeight: 'bold' }}>
          <Typography flex='calc(100% - 264px)' fontWeight='inherit'>Spielername</Typography>
          <Typography flex='200px' align='right' fontWeight='inherit'>Punktzahl (Elo)</Typography>
          <Typography flex='64px' align='center' fontWeight='inherit'>Platz</Typography>
        </Box>
        <List sx={{ maxWidth: '1200px', m: '0 auto' }}>
          {
            (groupedRows.current.length === 0 || (groupedRows.current.length === 1 && groupedRows.current[0].items.length === 0)) ?
              <NoDataFound game={ game.name } competition={ competition.name } />
            : getDeepClone(groupedRows.current).filter((value) => {
                value.items = value.items.filter((user) => {
                  return user.username.toLocaleLowerCase().startsWith(filterUsername.toLocaleLowerCase());
                });

                return value.items.length > 0;
              }).map((row, idx) => {
                const { label, items } = row;

                if (label.length === 0) {
                  return items.map((user) => {
                    const { id, username, photo, elo, placement } = user;

                    return (
                      <UserRankingListItem key={ id }
                        username={ username }
                        photo={ photo }
                        elo={ elo }
                        placement={ placement }
                      />
                    );
                  });
                }

                return (
                  <GroupedListItems label={label} key={idx}>
                    {
                      items.map((user) => {
                        const { id, username, photo, elo, placement } = user;

                        return (
                          <UserRankingListItem key={ id }
                            username={ username }
                            photo={ photo }
                            elo={ elo }
                            placement={ placement }
                          />
                        );
                      })
                    }
                  </GroupedListItems>
                );
              })
          }
        </List>
      </Paper>
    </>
  );
}

export default React.memo(UserRanking);
