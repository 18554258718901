import React from 'react';

import {
  Box,
  Paper,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GamesIcon from '@mui/icons-material/Games';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';

import ChipFilter from '../filter/ChipFilter';
import IconLabel from '../icon-label/IconLabel';

export type Filter = {
  id: string;
  label: string;
  active: boolean;
};

type RankingFilterOutputs = {
  onSearch: (searchValue: string) => void;
  onCompetitionChange: (competition: Filter) => void;
  onGameChange: (game: Filter) => void;
  onFilterChange: (filter: Filter) => void;
};

type RankingFilterInputs = {
  competitions: Filter[];
  games: Filter[];
  filters: Filter[];
  searchLabel: string;
};

type RankingFilterProps = RankingFilterOutputs & RankingFilterInputs;

function RankingFilter(props: RankingFilterProps) {
  const {
    onSearch,
    onCompetitionChange,
    onGameChange,
    onFilterChange,
    competitions,
    games,
    filters,
    searchLabel
  } = props;
  const chipStackStyles: SxProps<Theme> = {
    pt: '8px',
    display: 'flex',
    flexDirection: 'row',
    columnGap: '8px',
    rowGap: '16px',
    flexWrap: 'wrap'
  };

  return (
    <Paper
      elevation={ 3 }
      sx={{
        bgcolor: 'var(--white)',
        p: '16px',
        m: '0 auto',
        maxWidth: '1200px'
      }}
    >
      <IconLabel label='Turnier' icon={ <EmojiEventsIcon /> } />
      <Stack id='stack-competitions' sx={ chipStackStyles }>
        {
          competitions.map((competition) => {
            return (
              <ChipFilter
                key={ competition.id }
                onActivated={() => onCompetitionChange(competition)}
                label={ competition.label }
                active={ competition.active }
                outsideClickContext='stack-competitions'
              />
            );
          })
        }
      </Stack>
      <IconLabel label='Spiel' icon={ <GamesIcon /> } usePaddingTop />
      <Stack id='stack-games' sx={ chipStackStyles }>
        {
          games.map((game) => {
            return (
              <ChipFilter
                key={ game.id }
                onActivated={() => onGameChange(game)}
                label={ game.label }
                active={ game.active }
                outsideClickContext='stack-games'
              />
            );
          })
        }
      </Stack>
      <IconLabel
        label='Sortierung &amp; Gruppierung'
        icon={ <SortIcon /> }
        usePaddingTop
      />
      <Stack id='stack-filters' sx={ chipStackStyles }>
        {
          filters.map((filter) => {
            return (
              <ChipFilter
                key={ filter.id }
                onActivated={() => onFilterChange(filter)}
                label={ filter.label }
                active={ filter.active }
                outsideClickContext='stack-filters'
              />
            );
          })
        }
      </Stack>
      <Box sx={{ maxWidth: '1200px', m: '0 auto', mt: '24px' }}>
        <TextField
          sx={{ width: '100%' }}
          label={
            <Box sx={{
              display: 'flex',
              flexDirection: 'flow',
              columnGap: '8px'
            }}>
              <SearchIcon />
              <Typography>{ searchLabel }</Typography>
            </Box>
          }
          onChange={(ev) => onSearch(
            ev.target.value.length === 0 ? '' : ev.target.value
          )}
        />
      </Box>
    </Paper>
  );
}

export default React.memo(RankingFilter);
