import React from 'react';

import { AvatarGroup } from '@mui/material';

import ProfilePicture from '../profile/ProfilePicture';
import { Nullable } from '@Types/nullable';

export type Member = {
  photo: Nullable<string>;
  username: string;
};

type TeamMembersProps = {
  members: Member[];
  flex: string;
};

function TeamMembers({ members, flex }: TeamMembersProps) {
  return (
    <AvatarGroup max={ 4 } sx={{ flex }}>
      {
        members.map((member, idx) => {
          return (
            <ProfilePicture
              key={ `${idx}-member-image` }
              username={ member.username }
              image={ member.photo }
            />
          );
        })
      }
    </AvatarGroup>
  );
}

export default React.memo(TeamMembers);
