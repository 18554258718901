import { ThemeOptions, createTheme } from '@mui/material/styles';

enum Color {
  GREY = '#505051',
  WHITE = '#FEFEFE',
}

/**
 * - Für die Abstufungen (50 - 900) wurde
 * [diese](https://www.tints.dev/secondary/0C3859) Seite verwendet.
 * - Um anzugucken, wie das Theme aussieht wurde zudem
 * [diese](https://bareynol.github.io/mui-theme-creator/) Seite verwendet
 */
const THEME_OPTIONS: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#1E1E1E',
      50: "#E8E8E8",
      100: "#D1D1D1",
      200: "#A6A6A6",
      300: "#787878",
      400: "#4A4A4A",
      500: "#1E1E1E",
      600: "#171717",
      700: "#121212",
      800: "#0D0D0D",
      900: "#050505",
    },
    secondary: {
      main: '#0c3859',
      50: "#DBEDFA",
      100: "#B7DAF5",
      200: "#6FB6EC",
      300: "#2791E2",
      400: "#1665A2",
      500: "#0C3859",
      600: "#0A2D48",
      700: "#072236",
      800: "#051624",
      900: "#020B12",
    },
    background: {
      default: '#FAFAFA',
    },
    warning: {
      main: '#FFC107',
      50: '#FFF8E1',
      100: '#FFECB3',
      200: '#FFE082',
      300: '#FFD54F',
      400: '#FFCA28',
      500: '#FFC107',
      600: '#FFB300',
      700: '#FFA000',
      800: '#FF8F00',
      900: '#FF6F00',
    },
    error: {
      main: '#DC3545',
      50: '#FFECF1',
      100: '#FFD0D9',
      200: '#F19EA6',
      300: '#E97984',
      400: '#F75965',
      500: '#FD464E',
      600: '#EF3E4D',
      700: '#DC3546',
      800: '#CF2E3E',
      900: '#C02333',
    },
    info: {
      main: '#17A2B8',
      50: '#E0F6F9',
      100: '#B1E8F0',
      200: '#7FD8E6',
      300: '#4EC8D8',
      400: '#2DBDD2',
      500: '#19B2CB',
      600: '#17A3B8',
      700: '#148E9E',
      800: '#127A87',
      900: '#0E585C',
    },
    success: {
      main: '#28A745',
      50: '#E7F6E9',
      100: '#C5E9CA',
      200: '#9FDAA8',
      300: '#76CD85',
      400: '#56C26A',
      500: '#32B64F',
      600: '#28A746',
      700: '#1A953A',
      800: '#0A842F',
      900: '#00641B',
    },
    divider: Color.GREY,
  },
};

export const THEME = createTheme(THEME_OPTIONS);
