import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { useJsonApiConnector } from '@Hooks';
import { handleAsyncError } from '@Utils';

function NewTeam() {
  const navigateTo = useNavigate();
  const jsonApiConnector = useJsonApiConnector();

  const [type, setType] = useState('PUBLIC');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [competition, setCompetition] = React.useState<string | null>(null)
  const [{
    data: competitions
  }] = jsonApiConnector.competitionManagement.getSelection();

  return (
    <>
      <Typography variant='h3' textAlign='center' sx={{p: '16px 0'}}>Neues Team anlegen</Typography>
      <Paper sx={{backgroundColor: '#FEFEFE', p: '16px', m: '16px auto', maxWidth: '1200px'}} elevation={3}>
        <Stack spacing={2}>
          <TextField size='small' type='text' label='Name'
                     value={name} onChange={(event) => {
            setName(event.target.value);
          }}/>
          <FormControl size='small' fullWidth>
            <InputLabel id='competition-select-label'>Turnier</InputLabel>
            <Select labelId='competition-select-label'
                    value={competition}
                    onChange={(event) => {
                      setCompetition(event.target.value);
                    }}>
              <MenuItem>
                kein Turnier
              </MenuItem>
              {(competitions ?? []).map((competition) => {
                return (
                  <MenuItem key={competition?.id} value={competition?.id}>
                    {competition?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Einstellung für neue Mitglieder</FormLabel>
            <RadioGroup
              value={type}
              onChange={(event) => {
                setType(event.target.value);
              }}
            >
              <FormControlLabel value='PRIVATE' control={<Radio/>} label='Beitrittsanfragen nicht erlauben'/>
              <FormControlLabel value='PUBLIC' control={<Radio/>} label='Beitrittsanfragen erlauben'/>
              <FormControlLabel value='AUTOACCEPT' control={<Radio/>} label='Jeder darf beitreten'/>
            </RadioGroup>
          </FormControl>
          <TextField size='small' type='text' label='Beschreibung' multiline rows={5}
                     value={description} onChange={(event) => {
            setDescription(event.target.value);
          }}/>
          <Box sx={{
            display: 'flex',
            gridAutoFlow: 'column',
          }}>
            <Box sx={{flexGrow: 1, flexShrink: 1}}/>
            <Button variant='contained' disabled={!name} onClick={async () => {
              const team = await handleAsyncError(axios.post('/api/profile/team', {
                competition,
                type,
                name,
                description
              }));
              if (team?.id) {
                navigateTo(`/user/teams/${team.id}`);
              }
            }}>speichern</Button>
          </Box>
        </Stack>
      </Paper>
    </>
  )
}

export default React.memo(NewTeam);
