import React from 'react';

import { Box } from '@mui/material';

import { useLogger } from '@Hooks/use-logger';

type TicTacToeFieldInput = {
  children: string;
  locked: boolean;
};

type TicTacToeFieldOutput = {
  onToggle: () => void;
};

type TicTacToeFieldProps = TicTacToeFieldInput & TicTacToeFieldOutput;

function TicTacToeField({ onToggle, children, locked }: TicTacToeFieldProps) {
  const logger = useLogger();
  const size = 192;

  return (
    <Box
      onClick={() => {
        if (!locked) {
          onToggle();
        } else {
          logger.error('This field is locked, the state can not be changed');
        }
      }}
      sx={{
        height: `${size}px`,
        width: `${size}px`,
        padding: '0',
        margin: '0',
        lineHeight: 1,
        border: '1px solid rgba(0, 0, 0, 0.18)',
        fontSize: `${size * 60 / 100}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        userSelect: 'none',
      }}
    >
      { children }
    </Box>
  );
}

export default React.memo(TicTacToeField);
