import React from 'react';

import { Box, LinearProgress, Stack, Typography } from '@mui/material';

import DisplayContainer from '@Components/games/DisplayContainer';
import { useLogger } from '@Hooks/use-logger';
import { PvC } from '@Services/player-versus-computer';
import { Nullable, PvCAnswerFunction, TicTacToePvC } from '@Types';
import TicTacToeField from '../TicTacToeField';

function TicTacToe() {
  const logger = useLogger();
  const [isConnected, setIsConnected] = React.useState(false);
  const [mySymbol, setMySymbol] = React.useState<Nullable<'X' | 'O'>>(null);
  const answerCallback = React.useRef<PvCAnswerFunction<number>>(
    function (move: number) {
      logger.error('Have not received the callback yet.');
    }
  );
  const [board, setBoard] = React.useState<Array<'' | 'X' | 'O'>>(
    ['', '', '', '', '', '', '', '', '']
  );
  /**
   * This state represents if the game is running (0), won (1), lost (2)
   * or if it's a tie (3)
   */
  const [gameState, setGameState] = React.useState(0);

  React.useEffect(() => {
    /**
     * @TODO
     * Needs to be replaced with the secret of the user.
     * Currently the connection is established in the perspective of a bot.
     */
    const pvc = new PvC<
      TicTacToePvC.DataInit,
      [TicTacToePvC.DataRound, PvCAnswerFunction<number>],
      TicTacToePvC.DataResult
    >('73fb3abb-e42e-432d-8c02-914c3ce4bcba');

    const updateBoard = async (data: TicTacToePvC.DataRound, finalRound: boolean) => {
      const mySymbol = data.players.filter((player) => player.id === data.self)[0].symbol;
      setBoard(data.board);
      setMySymbol(mySymbol);

      if (finalRound) {
        if (data.players[0].score === 1 && data.players[0].id === data.self) {
          setGameState(1);
        } else if (data.players[0].score === 1 && data.players[0].id !== data.self) {
          setGameState(2);
        } else if (data.players[1].score === 1 && data.players[1].id === data.self) {
          setGameState(1);
        } else if (data.players[1].score === 1 && data.players[1].id !== data.self) {
          setGameState(2);
        } else {
          setGameState(3);
        }
      } else {
        setGameState(0);
      }
    };

    pvc.on('connection-state', (connected) => {
      setIsConnected(connected);
    });
    pvc.on('round-ended', (data) => {
      pvc.disconnect();
      updateBoard(data, true);

      setTimeout(() => {
        pvc.connect();
        setBoard(['', '', '', '', '', '', '', '', '']);
        setGameState(0);
        setMySymbol(null);
      }, 3000);
    });
    pvc.on('round-state', (data) => {
      const round = data[0];
      updateBoard(round, false);
      answerCallback.current = data[1];
    });

    pvc.connect();

    return () => {
      pvc.offAll();
    }
  }, []);

  function onToggle(field: number): void {
    const boardCopy = [...board];
    boardCopy[field] = mySymbol || '';
    setBoard(boardCopy);
    answerCallback.current(field);
  }

  const prettyBoard = board.map((field) => {
    if (field === 'O') return '⭕';
    if (field === 'X') return '✖️';

    return field;
  });

  return (
    <DisplayContainer label='Tic-Tac-Toe'>
      {
        !mySymbol ? <LinearProgress /> : (
          <Stack direction='column'>
            <Stack direction='row' justifyContent='space-evenly'>
              <Box flex='50%'>
                <Typography variant='h4'
                  color='#2c3e50'
                  sx={{
                    transition: 'all 0.5s',
                    fontSize: 'min(6vw, 9vh)',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>✖️{ mySymbol === 'X' ? 'Sie selbst' : 'Gegner' }</Typography>
              </Box>
              <Box flex='50%'>
                <Typography variant='h4' color='#2c3e50'
                  sx={{
                    transition: 'all 0.5s',
                    fontSize: 'min(6vw, 9vh)',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>⭕{ mySymbol === 'O' ? 'Sie selbst' : 'Gegner' }</Typography>
              </Box>
            </Stack>
            <Box display='flex' flexDirection='column' alignItems='center' pt='16px'>
              <Box display='flex' flexDirection='row'>
                <TicTacToeField
                  onToggle={() => onToggle(0)}
                  locked={ (prettyBoard[0].length !== 0 || !isConnected) }
                >{ prettyBoard[0] }</TicTacToeField>
                <TicTacToeField
                  onToggle={() => onToggle(1)}
                  locked={ (prettyBoard[1].length !== 0 || !isConnected) }
                >{ prettyBoard[1] }</TicTacToeField>
                <TicTacToeField
                  onToggle={() => onToggle(2)}
                  locked={ (prettyBoard[2].length !== 0 || !isConnected) }
                >{ prettyBoard[2] }</TicTacToeField>
              </Box>
              <Box display='flex' flexDirection='row'>
                <TicTacToeField
                  onToggle={() => onToggle(3)}
                  locked={ (prettyBoard[3].length !== 0 || !isConnected) }
                >{ prettyBoard[3] }</TicTacToeField>
                <TicTacToeField
                  onToggle={() => onToggle(4)}
                  locked={ (prettyBoard[4].length !== 0 || !isConnected) }
                >{ prettyBoard[4] }</TicTacToeField>
                <TicTacToeField
                  onToggle={() => onToggle(5)}
                  locked={ (prettyBoard[5].length !== 0 || !isConnected) }
                >{ prettyBoard[5] }</TicTacToeField>
              </Box>
              <Box display='flex' flexDirection='row'>
                <TicTacToeField
                  onToggle={() => onToggle(6)}
                  locked={ (prettyBoard[6].length !== 0 || !isConnected) }
                >{ prettyBoard[6] }</TicTacToeField>
                <TicTacToeField
                  onToggle={() => onToggle(7)}
                  locked={ (prettyBoard[7].length !== 0 || !isConnected) }
                >{ prettyBoard[7] }</TicTacToeField>
                <TicTacToeField
                  onToggle={() => onToggle(8)}
                  locked={ (prettyBoard[8].length !== 0 || !isConnected) }
                >{ prettyBoard[8] }</TicTacToeField>
              </Box>
            </Box>
            {
              gameState === 0 ? '' : gameState === 3 ? (
                <Typography variant='h4' color='#2c3e50' sx={{
                  transition: 'all 0.5s',
                  fontSize: '2.3rem',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  mt: '32px'
                }}>
                  Unentschieden
                </Typography>
              ) : (
                <Typography variant='h4' color='#2c3e50' sx={{
                  transition: 'all 0.5s',
                  fontSize: '2.3rem',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  mt: '32px'
                }}>
                  Sie haben die Partie { gameState === 1 ? 'gewonnen' : 'verloren' }
                </Typography>
              )
            }
          </Stack>
        )
      }
    </DisplayContainer>
  );
}

export default React.memo(TicTacToe);
