import React from 'react';

import { Avatar, Tooltip } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';

import { Nullable } from '@Types/nullable';

type MemberImageProps = {
  image?: Nullable<string>;
  username?: string;
};

function ProfilePicture({ image = null, username }: MemberImageProps) {
  return (
    <Tooltip title={ username }>
      <Avatar src={ image || '' }>
        {/* Fallback icon if provided photo url can't be found - 404 */}
        <AccountCircle
          sx={{ width: 'inherit', height: 'inherit', color: 'primary.400' }}
          data-testid='fallback-icon'
        />
      </Avatar>
    </Tooltip>
  );
}

export default React.memo(ProfilePicture);
