import React from 'react';

import { Container, Link, Paper, Typography } from '@mui/material';

function Footer() {
  return (
    <Paper sx={{ background: '#FEFEFE', pt: 3, pb: 3 }} square elevation={ 4 }>
      <Container sx={{ textAlign: 'center' }}>
        <Typography color='#515151' variant='body2' data-testid='footer-text'>
          <Link
            color='inherit'
            href='https://blog.uhno.de/impressum'
            target='_blank'
          >
            Impressum &amp; Datenschutz
          </Link>
        </Typography>
      </Container>
    </Paper>
  );
}

export default React.memo(Footer);
