import React from 'react';

import { Box } from '@mui/material';

import { FirstPlaceMedal, SecondPlaceMedal, ThirdPlaceMedal } from '@Assets';

type RankingPlacementProps = {
  placement: number;
};

/**
 * The images used for the placement were found and downloaded from the
 * following sites:
 *
 * @see https://www.flaticon.com/free-icon/medal_2583448 (medal 3rd place)
 * @sse https://www.flaticon.com/free-icon/medal_2583350 (medal 2nd place)
 * @see https://www.flaticon.com/free-icon/medal_2583381 (medal 1st place)
 */
function RankingPlacement({ placement }: RankingPlacementProps) {
  const placements: string[] = [
    FirstPlaceMedal,
    SecondPlaceMedal,
    ThirdPlaceMedal
  ];

  if (placement < 4) {
    return (
      <Box flex='64px' textAlign='center'>
        <img
          style={{ maxWidth: '48px', maxHeight: '48px' }}
          src={ placements[placement - 1] }
          alt='medal'
        />
      </Box>
    );
  }

  return <Box flex='64px'></Box>;
}

export default React.memo(RankingPlacement);
