import React from 'react';

import { LinearProgress, Stack, Typography } from '@mui/material';

import { Rock, Paper, Scissors, Well } from '@Assets';
import DisplayContainer from '@Components/games/DisplayContainer';
import { getWinner } from '../functions/get-winner';
import { toNoun } from '../functions/to-noun';
import { useLogger } from '@Hooks/use-logger';
import { PvC } from '@Services/player-versus-computer';
import { Nullable, PvCAnswerFunction, RockPaperScissorsPvC } from '@Types';

const SECRETS = {
  SSP: 'd34fa49c-b7a2-4470-9573-c585911f0a9f',
  SSPB: '07d48760-769f-4040-99d6-25e5ca7b9e61',
};

type RockPaperScissorsProps = {
  includeWell?: boolean;
};

const buttonStyles: React.CSSProperties = {
  border: 'none',
  background: 'transparent',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

function getWinningState(players: RockPaperScissorsPvC.Player[], playerIndex: number): string {
  if (players[0].score > players[1].score && playerIndex === 0) return 'Sie haben gewonnen.';
  if (players[0].score > players[1].score && playerIndex === 1) return 'Sie haben verloren.';
  if (players[0].score < players[1].score && playerIndex === 1) return 'Sie haben gewonnen.';
  if (players[0].score < players[1].score && playerIndex === 0) return 'Sie haben verloren.';

  return 'Unentschieden.';
}

function RockPaperScissors({ includeWell = false }: RockPaperScissorsProps) {
  const logger = useLogger();
  const [receivedData, setReceivedData] = React.useState<Nullable<RockPaperScissorsPvC.DataInit | RockPaperScissorsPvC.DataRound>>(null);
  const [isRoundOver, setIsRoundOver] = React.useState(false);
  const [lastRound, setLastRound] = React.useState<Nullable<RockPaperScissorsPvC.Log>>(null);
  const [round, setRound] = React.useState<Nullable<number>>(null);
  const [myIndex, setMyIndex] = React.useState<Nullable<number>>(null);
  const answerCallback = React.useRef<PvCAnswerFunction<string>>(function (move: string) {
    logger.error('Have not received the callback yet.');
  });
  const elements = {
    STEIN: Rock,
    PAPIER: Paper,
    SCHERE: Scissors,
    BRUNNEN: Well,
  };
  const gameId = includeWell ? 'SSPB' : 'SSP';

  React.useEffect(() => {
    /**
     * @TODO
     * Needs to be replaced with the secret of the user.
     * Currently the connection is established in the perspective of a bot.
     */
    const pvc = new PvC<
      RockPaperScissorsPvC.DataInit,
      [RockPaperScissorsPvC.DataRound, PvCAnswerFunction<string>],
      RockPaperScissorsPvC.DataResult
    >(SECRETS[gameId]);

    pvc.on('connection-state', (connected) => {
      if (connected) {
        setReceivedData(null);
        setRound(null);
      }
    });
    pvc.on('round-ended', (data) => {
      pvc.disconnect();

      setIsRoundOver(true);
      setReceivedData(data);
      setLastRound(data.log[data.log.length - 1]);

      setTimeout(() => {
        pvc.connect();
      }, 3000);
    });
    pvc.on('round-state', (data) => {
      const round = data[0];

      if (round.players[0].id === round.self) {
        setMyIndex(0);
      } else {
        setMyIndex(1);
      }

      setRound(round.round || 1);
      setLastRound(round.log[(round.round || 1) - 2] || null);
      setReceivedData(round);
      setIsRoundOver(false);
      answerCallback.current = data[1];
    });

    pvc.connect();

    return () => {
      pvc.offAll();
    }
  }, [logger, gameId]);

  function onSelect(symbol: string): void {
    answerCallback.current(symbol);
  }

  return (
    <DisplayContainer label={ `Schnick, Schnack, Schnuck${includeWell ? ', Brunnen' : ''}` }>
      {
        !receivedData || myIndex === null || round === null ? <LinearProgress /> : (
          <>
            {
              !lastRound ? <></> : (
                <>
                  <Typography variant='h4' color='#2c3e50' sx={{
                    transition: 'all 0.5s',
                    fontSize: '2.3rem',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    mb: '32px'
                  }}>Ergebnis der letzten Runde</Typography>
                  <Stack direction='row' justifyContent='space-evenly' marginBottom='32px'>
                    <Stack direction='column' alignContent='center' justifyContent='center' flex='50%'>
                      <Typography variant='h4' color='#2c3e50' sx={{
                        transition: 'all 0.5s',
                        fontSize: '2.3rem',
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        mb: '32px'
                      }}>{ myIndex === 0 ? 'Ihre Wahl' : 'Wahl des Gegners' }</Typography>
                    </Stack>
                    <Stack direction='column' alignContent='center' justifyContent='center' flex='50%'>
                      <Typography variant='h4' color='#2c3e50' sx={{
                        transition: 'all 0.5s',
                        fontSize: '2.3rem',
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        mb: '32px'
                      }}>{ myIndex === 1 ? 'Ihre Wahl' : 'Wahl des Gegners' }</Typography>
                    </Stack>
                  </Stack>
                  <Stack direction='row' justifyContent='space-evenly'>
                    <Stack direction='column' alignItems='center' justifyContent='center' flex='50%'>
                      <img src={ elements[lastRound.results[0]] } alt={ toNoun(lastRound.results[0]) } width='128px' />
                    </Stack>
                    <Stack direction='column' alignItems='center' justifyContent='center' flex='50%'>
                      <img src={ elements[lastRound.results[1]] } alt={ toNoun(lastRound.results[1]) } width='128px' />
                    </Stack>
                  </Stack>
                  <Typography variant='h4' color='#2c3e50' sx={{
                    transition: 'all 0.5s',
                    fontSize: '2.3rem',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    mt: '32px'
                  }}>
                    {
                      getWinner(lastRound.results)
                    }
                  </Typography>
                </>
              )
            }
            {
              !isRoundOver ? (
                <Stack direction='column' justifyContent='center' marginTop='32px'>
                  <Typography variant='h4' color='#2c3e50' sx={{
                    transition: 'all 0.5s',
                    fontSize: '2.3rem',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    mt: '32px'
                  }}>Runde {round}</Typography>
                  <Stack direction='row' justifyContent='space-evenly'>
                    <button onClick={() => onSelect('SCHERE')} style={ buttonStyles }>
                      <img src={ elements.SCHERE } alt='Schere' width='128px' />
                    </button>
                    <button onClick={() => onSelect('STEIN')} style={ buttonStyles }>
                      <img src={ elements.STEIN } alt='Stein' width='128px' />
                    </button>
                    <button onClick={() => onSelect('PAPIER')} style={ buttonStyles }>
                      <img src={ elements.PAPIER } alt='Papier' width='128px' />
                    </button>
                    {
                      !includeWell ? '' : (
                        <button onClick={() => onSelect('BRUNNEN')} style={ buttonStyles }>
                          <img src={ elements.BRUNNEN } alt='Brunnen' width='128px' />
                        </button>
                      )
                    }
                  </Stack>
                </Stack>
              ) : (
                <Typography variant='h4' color='#2c3e50' sx={{
                  transition: 'all 0.5s',
                  fontSize: '2.3rem',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  mt: '32px'
                }}>{ getWinningState(receivedData.players, myIndex) } Warten bis neue Runde startet.</Typography>
              )
            }
          </>
        )
      }
    </DisplayContainer>
  )
}

export default React.memo(RockPaperScissors);
