import { formatDate } from './format-date';
import { Competition } from '@Models/competition';
import { AccessType } from '@Types/access-type';

export function createSubtitle(competition: Competition): string {
  const parts = [];

  switch (competition.type) {
    case AccessType.PRIVATE:
      parts.push('privat');
      break;
    case AccessType.PUBLIC:
      parts.push('mit Anmeldung');
      break;
    case AccessType.AUTOACCEPT:
      parts.push('öffentlich');
      break;
  }

  const start = formatDate(competition.start);
  const end = formatDate(competition.end);
  const startRegistration = formatDate(competition.start_registration);
  const endRegistration = formatDate(competition.end_registration);

  if (start && end) {
    parts.push(`${start} bis ${end}`);
  } else if (start) {
    parts.push(`ab ${start}`);
  } else if (end) {
    parts.push(`bis ${end}`);
  } else {
    parts.push('unbegrenzt');
  }

  if (startRegistration && endRegistration) {
    parts.push(`Registrierung ab ${startRegistration} bis ${endRegistration}`);
  } else if (startRegistration) {
    parts.push(`Registrierung ab ${startRegistration}`);
  } else if (endRegistration) {
    parts.push(`Registrierung bis ${endRegistration}`);
  }

  return parts.join(', ');
}
