import React from 'react';

import { ProfileContext, ProfileContextData } from '@Contexts/profile-context';

export function useProfile(): ProfileContextData {
  const context = React.useContext(ProfileContext);

  if (context === null) {
    throw new Error('Trying to access the profile context but no data was defined!');
  }

  return context;
}
