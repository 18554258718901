import React from 'react';

import { useParams } from 'react-router-dom';
import { Avatar, Badge, Box, Card, CardContent, CardHeader, Tooltip, Typography } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';

import LoadingSpinner from '@Components/loading/LoadingSpinner';
import ProfilePicture from '@Components/profile/ProfilePicture';
import { useJsonApiConnector, useLogger } from '@Hooks';
import { TeamBot } from '@Models/bot';
import { AccessType } from '@Types/access-type';
import { formatDate } from '@Utils/format-date';

function TeamDetails() {
  const params = useParams();
  const logger = useLogger();
  const jsonApiConnector = useJsonApiConnector();

  const [{
    data: team,
    loading: teamLoading,
    error: teamError
  }] = jsonApiConnector.teams.read(params.teamId ?? null);
  const [{
    data: bots,
    loading: botsLoading,
    error: botsError,
  }] = jsonApiConnector.bots.readAll<TeamBot>({
    teamId: params.teamId ?? null
  });

  if (!team || teamLoading || teamError) {
    if (teamError) logger.error('Fetching team from the database', teamError);

    return <LoadingSpinner sx={{ m: '64px auto 0 auto' }} />;
  }

  if (!bots || botsLoading || botsError) {
    if (botsError) logger.error('Fetching team bots from the database', botsError);

    return <LoadingSpinner sx={{ m: '64px auto 0 auto' }} />;
  }

  logger.info('Team from the database: ', team);
  logger.info('Team bots from database: ', bots);

  let accessType = '';
  switch (team.type) {
    case AccessType.PRIVATE:
      accessType = 'Privat';
      break;
    case AccessType.PUBLIC:
      accessType = 'Mit Anmeldung';
      break;
    case AccessType.AUTOACCEPT:
      accessType = 'Öffentlich';
      break;
  }

  return (
    <Box sx={{ p: '16px' }}>
      <Typography variant='h3' textAlign='center' sx={{ p: '16px 0' }}>{ team.name }</Typography>
      <Typography variant='subtitle1' textAlign='center'>{ accessType }</Typography>
      <Card sx={{ bgcolor: 'var(--white)', m: '16px auto 0 auto', maxWidth: '1200px' }}>
        <CardHeader title='Beschreibung' />
        <CardContent sx={{ flex: '1 1 auto' }}>
          <Typography variant='body2' color='text.secondary'>
            { team.description ?? 'Dieses Team hat keine Beschreibung hinterlegt.' }
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ bgcolor: 'var(--white)', m: '16px auto 0 auto', maxWidth: '1200px' }}>
        <CardHeader title='Mitglieder' />
        <CardContent sx={{ flex: '1 1 auto' }}>
          <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
            {
              team.members.map((member) => {
                return (
                  <ProfilePicture key={ member.id } image={ member.photo } username={ member.username } />
                );
              })
            }
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ bgcolor: 'var(--white)', m: '16px auto 0 auto', maxWidth: '1200px' }}>
        <CardHeader title='Bots' />
        <CardContent sx={{ flex: '1 1 auto' }}>
          <Box sx={{ display: 'flex', gap: '32px', flexWrap: 'wrap' }}>
            {
              bots.map((bot) => {
                return (
                  <Box display='flex' flexDirection='column' rowGap='16px' alignItems='center'>
                    <Tooltip title={
                      <React.Fragment>
                        <Typography variant='h5' textAlign='center' paddingBottom='8px'>{ bot.name }</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '8px' }}>
                          <Typography>Spiel: { bot.game }</Typography>
                          <Typography>Elo: { bot.elo }</Typography>
                          {
                            bot.games_played > 0 ? (
                              <>
                                <Typography>Letztes Spiel: { formatDate(bot.last_game) } Uhr</Typography>
                                <Typography>Spiele gespielt: { bot.games_played }</Typography>
                              </>
                            ) : <></>
                          }
                        </Box>
                      </React.Fragment>
                    }>
                      <Badge badgeContent={ bot.online ? 'online' : 'offline' } color={ bot.online ? 'success' : 'error' }>
                        <Avatar src={ bot.photo || '' }>
                          {/* Fallback icon if provided photo url can't be found - 404 */}
                          <AccountCircle sx={{ width: 'inherit', height: 'inherit', color: 'primary.400' }} data-testid='fallback-icon' />
                        </Avatar>
                      </Badge>
                    </Tooltip>
                    <Typography variant='h5'>{ bot.name }</Typography>
                  </Box>
                );
              })
            }
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default React.memo(TeamDetails);
