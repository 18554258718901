import React from 'react';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import {useJsonApiConnector, useProfile} from "@Hooks";
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel, MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import {handleAsyncError} from "@Utils";
import axios from "axios";
import {enqueueSnackbar} from "notistack";
import {AlertTitle} from "@mui/lab";
import {logins} from "../../logins";
import md5 from "md5";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProfilePicture from "@Components/profile/ProfilePicture";
import MobileNav from "@Components/toolbar/MobileNav";

type SidebarProps = {
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose }: SidebarProps) => {
  const { profile, profileReload } = useProfile();
  const jsonApiConnector = useJsonApiConnector();

  const [username, setUsername] = React.useState<string | null>(null);
  const [photo, setPhoto] = React.useState<string | null>(null);

  const notMobile = useMediaQuery('(max-width:900px)');

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <div style={{ maxWidth: 450, minWidth: 250, padding: 16 }}>
        {notMobile && (
          <>
            <MobileNav/>
            <Divider sx={{marginY: '16px'}}/>
          </>
        )}
        {profile && (
          <>
            <Box sx={{marginBottom:'8px', display: 'flex', columnGap: '8px', justifyContent: 'space-between', alignItems: 'center'}}>
              <Typography variant={'h5'}> <strong>{profile?.username}</strong> </Typography>
              <ProfilePicture image={ profile?.photo } username={ profile?.username }/>
            </Box>

            {(!profile?.competitioninvites || profile?.competitioninvites.length === 0) && (!profile?.invites || profile?.invites.length === 0) && (
              <Alert sx={{marginBottom:'8px'}} severity='info'>
                Keine Benachrichtigungen
              </Alert>
            )}
            {profile?.competitioninvites?.map((competition) => {
              return <Alert key={competition.id} severity='info' variant='outlined' action={
                <Stack spacing={2}>
                  <Button color='success' onClick={async () => {
                    await handleAsyncError(axios.post(`/api/profile/competition/${competition.id}/accept`), enqueueSnackbar);
                    profileReload();
                  }}>
                    annehmen
                  </Button>
                  <Button color='error' onClick={async () => {
                    await handleAsyncError(axios.post(`/api/profile/competition/${competition.id}/leave`), enqueueSnackbar);
                    profileReload();
                  }}>
                    ablehnen
                  </Button>
                </Stack>
              }>
                <AlertTitle>Turnier-Einladung</AlertTitle>
                Du wurdest eingeladen, Turnier <strong>{competition.name}</strong> beizutreten.
              </Alert>;
            })}
            {profile?.invites?.map((team) => {
              return <Alert key={team.id} severity='info' variant='outlined' action={
                <Stack spacing={2}>
                  <Button color='success' onClick={async () => {
                    await handleAsyncError(axios.post(`/api/profile/team/${team.id}/accept`), enqueueSnackbar);
                    profileReload();
                  }}>
                    annehmen
                  </Button>
                  <Button color='error' onClick={async () => {
                    await handleAsyncError(axios.post(`/api/profile/team/${team.id}/leave`), enqueueSnackbar);
                    profileReload();
                  }}>
                    ablehnen
                  </Button>
                </Stack>
              }>
                <AlertTitle>Team-Einladung</AlertTitle>
                Du wurdest eingeladen, Team <strong>{team.name}</strong> beizutreten.
              </Alert>;
            })}
            <Divider/>
            <Typography sx={{margin: '4px'}} variant={'subtitle1'}>Profilinformationen bearbeiten</Typography>

            <Box sx={{
              display: 'flex',
              gridAutoFlow: 'column',
              gridGap: '8px',
              marginY: '8px'
            }}>
              <TextField type='text' sx={{flexGrow: 1, flexShrink: 1}} label='Benutzername'
                         value={username ?? profile?.username} onChange={(event) => {
                setUsername(event.target.value);
              }}/>

              <FormControl>
                <InputLabel id='avatar-select-label'>Avatar</InputLabel>
                <Select size='small' sx={{width: '10ch'}} labelId='avatar-select-label' label='Avatar'
                        value={photo ?? profile?.photo ?? (profile?.id && `https://vanillicon.com/v2/${md5(profile.id)}.svg`)}
                        onChange={(event) => {
                          setPhoto(event.target.value);
                        }}>

                  {logins.map(({id}) => {
                    const loginProfile = profile?.logins.find((l) => l.id === id);
                    if (loginProfile?.photo) {
                      return <MenuItem key={id} value={loginProfile.photo}>
                        <Avatar src={loginProfile.photo}/>
                      </MenuItem>;
                    }
                    return null;
                  })}
                  {profile?.id && (
                    <MenuItem value={`https://vanillicon.com/v2/${md5(profile.id)}.svg`}>
                      <Avatar src={`https://vanillicon.com/v2/${md5(profile.id)}.svg`} />
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>

            {logins.map(({
                           id,
                           title,
                           icon,
                           disabled
                         }) => {
              const loginProfile = profile?.logins.find((l) => l.id === id);
              if (loginProfile) {
                return <Box key={id} sx={{
                  display: 'flex',
                  gridAutoFlow: 'column',
                  gridGap: '8px',
                  alignItems: 'center',
                  color: `${id}.main`,
                  marginY: '4px'
                }}>
                  <FontAwesomeIcon icon={icon} fixedWidth/>
                  <Box sx={{flexGrow: 1, flexShrink: 1}}>{title}</Box>
                  <Box>{loginProfile.username}</Box>
                  <Avatar src={loginProfile.photo} alt={loginProfile.username} sx={{
                    bgcolor: `${id}.main`,
                    color: `${id}.contrastText`,
                    float: 'right'
                  }}/>
                </Box>;
              }
              return <Box key={id} sx={{
                display: 'flex',
                gridAutoFlow: 'column',
                gridGap: '8px',
                alignItems: 'center',
                color: `${id}.main`,
                marginY: '4px'
              }}>
                <FontAwesomeIcon icon={icon} fixedWidth/>
                <Box sx={{flexGrow: 1, flexShrink: 1}}>{title}</Box>
                <Button variant='contained' disabled={disabled}
                        href={`/auth/${id}/login`}
                        startIcon={<FontAwesomeIcon icon={icon}/>} sx={{
                  float: 'right'
                }}>verknüpfen</Button>
              </Box>
            })}

            <Button color='error' onClick={onClose}>schließen</Button>
            <Button onClick={async () => {
              await jsonApiConnector.profile.update(
                {
                  username: username || profile.username,
                  photo: photo || profile.photo,
                },
                enqueueSnackbar,
                () => {
                  enqueueSnackbar('Daten Erfolgreich geändert', {
                    variant: 'success',
                  });
                },
              );
              profileReload();
            }}>speichern</Button>
          </>
        )}
      </div>
    </Drawer>
  );
};

export default React.memo(Sidebar);