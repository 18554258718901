import { Box, LinearProgress, Stack, Typography, useTheme } from '@mui/material';

import React from 'react';

import DisplayContainer from '@Components/games/DisplayContainer';
import { useLogger } from '@Hooks/use-logger';
import { PvC } from '@Services/player-versus-computer';
import { Nullable, PvCAnswerFunction, Symbol, MetaTicTacToePvC } from '@Types';

function check(board: Symbol[]): number[] {
  for (const symbol of ['X', 'O']) {
    if (board[0] === symbol && board[1] === symbol && board[2] === symbol) {
      return [0, 1, 2];
    }
    if (board[3] === symbol && board[4] === symbol && board[5] === symbol) {
      return [3, 4, 5];
    }
    if (board[6] === symbol && board[7] === symbol && board[8] === symbol) {
      return [6, 7, 8];
    }
    if (board[0] === symbol && board[3] === symbol && board[6] === symbol) {
      return [0, 3, 6];
    }
    if (board[1] === symbol && board[4] === symbol && board[7] === symbol) {
      return [1, 4, 7];
    }
    if (board[2] === symbol && board[5] === symbol && board[8] === symbol) {
      return [2, 5, 8];
    }
    if (board[0] === symbol && board[4] === symbol && board[8] === symbol) {
      return [0, 4, 8];
    }
    if (board[2] === symbol && board[4] === symbol && board[6] === symbol) {
      return [2, 4, 6];
    }
  }

  return [];
};

const DEFAULT_BOARD = new Array<Symbol>(9).fill('').map(() => new Array<Symbol>(9).fill(''));
const DEFAULT_OVERVIEW = new Array<Symbol>(9).fill('');

function MetaTicTacToe() {
  const logger = useLogger();
  const theme = useTheme();
  const [mySymbol, setMySymbol] = React.useState<Nullable<'X' | 'O'>>(null);
  const [forcedSection, setForcedSection] = React.useState<Nullable<number>>(null);
  const answerCallback = React.useRef<PvCAnswerFunction<[number, number]>>(function (move: [number, number]) {
    logger.error('Have not received the callback yet.');
  });
  const [board, setBoard] = React.useState<Symbol[][]>(DEFAULT_BOARD);
  const [overview, setOverview] = React.useState<Symbol[]>(DEFAULT_OVERVIEW);
  /**
   * This state represents if the game is running (0), won (1), lost (2) or if it's a tie (3)
   */
  const [gameState, setGameState] = React.useState(0);

  React.useEffect(() => {
    /**
     * @TODO
     * Needs to be replaced with the secret of the user.
     * Currently the connection is established in the perspective of a bot.
     */
    const pvc = new PvC<
      MetaTicTacToePvC.DataInit,
      [MetaTicTacToePvC.DataRound, PvCAnswerFunction<[number, number]>],
      MetaTicTacToePvC.DataResult
    >('02340ddd-ad76-4981-bd32-058c22fc229a');

    pvc.on('round-init', (data) => {
      const player = data.players.filter((player) => player.id === data.self)[0];
      setMySymbol(player.symbol);
    });
    pvc.on('round-state', (data) => {
      const round = data[0];
      logger.info('Round Data: ', round);
      setBoard(round.board);
      setOverview(round.overview);
      setForcedSection(round.forcedSection);
      answerCallback.current = data[1];
    });
    pvc.on('round-ended', (data) => {
      logger.info('Result Data: ', data);
      let index = 0;

      if (data.players[1].id === data.self) {
        index = 1;
      }

      pvc.disconnect();

      if (data.players[0].score === data.players[1].score) {
        setGameState(3);
      } else if (data.players[index].score > 0) {
        setGameState(1);
      } else {
        setGameState(2);
      }

      setBoard(data.board);
      setOverview(data.overview);
      setForcedSection(null);

      setTimeout(() => {
        setMySymbol(null);
        setBoard(DEFAULT_BOARD);
        setOverview(DEFAULT_OVERVIEW);
        setForcedSection(null);
        setGameState(0);
        pvc.connect();
      }, 3000);
    });

    pvc.connect();

    return () => {
      pvc.offAll();
    };
  }, [logger]);

  function toggleField(field: [number, number], locked: boolean): void {
    if (locked) {
      logger.warn('Can not toggle this field since it is locked');
    } else {
      answerCallback.current(field);
    }
  }

  function renderBoard() {
    const overviewMap = check(overview);

    return (
      <Stack direction='row' sx={{
        transition: 'all 0.25s',
        border: 'min(0.5vw, 0.75vh) solid transparent',
        margin: 'min(0.5vw, 0.75vh)',
        borderRadius: 'min(1vw, 1.5vh)',
        display: 'grid',
        gridTemplateRows: 'auto auto auto',
        gridTemplateColumns: 'auto auto auto',
        overflow: 'hidden',
        position: 'relative'
      }}>
        {
          board.map((fields, section) => {
            const sectionMap = check(fields);
            const completed = overview[section] !== '';

            return (
              <Stack direction='column' key={ section } sx={{
                transition: 'all 0.5s',
                transitionDelay: '0.3s',
                display: 'grid',
                gridTemplateRows: 'auto auto auto',
                gridTemplateColumns: 'auto auto auto',
                border: `min(0.25vw, 0.375vh) solid ${theme.palette.primary.main}`,
                borderTop: Math.floor(section / 3) === 0 ? '0px solid transparent' : null,
                borderBottom: Math.floor(section / 3) === 2 ? '0px solid transparent' : null,
                borderLeft: section % 3 === 0 ? '0px solid transparent' : null,
                borderRight: section % 3 === 2 ? '0px solid transparent' : null,
                background: ((forcedSection != null && forcedSection !== section) || completed) ? '#95a5a6' : null,
                position: 'relative'
              }}>
                {
                  completed ? (
                    <Box sx={{
                      zIndex: 10,
                      filter: `grayscale(${overviewMap.length && !overviewMap.includes(section) ? 1 : 0})`,
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      fontSize: 'min(9vw, 13.5vh)',
                      textAlign: 'center',
                      lineHeight: 'min(15vw, 22.5vh)'
                    }}>
                      {overview[section] === 'X' ? '✖️' : (overview[section] === 'O' ? '⭕' : '💩')}
                    </Box>
                  ) : null
                }
                {
                  fields.map((value, field) => {
                    let content = '';
                    const style: any = {};

                    switch (value) {
                      case 'X':
                        style.fontSize = 'min(3vw, 4.5vh)';
                        content = '✖️';
                        break;
                      case 'O':
                        style.fontSize = 'min(3vw, 4.5vh)';
                        content = '⭕';
                        break;
                    }

                    return (
                      <Box sx={{
                        zIndex: 1,
                        border: `min(0.125vw, 0.1875vh) solid ${theme.palette.primary.main}`,
                        borderTop: Math.floor(field / 3) === 0 ? '0px solid transparent' : null,
                        borderBottom: Math.floor(field / 3) === 2 ? '0px solid transparent' : null,
                        borderLeft: field % 3 === 0 ? '0px solid transparent' : null,
                        borderRight: field % 3 === 2 ? '0px solid transparent' : null
                      }} key={ field }>
                        <Box onClick={() => toggleField([section, field], (completed || content !== '' || (forcedSection != null && forcedSection !== section)))} sx={{
                          transition: 'background 0.3s, font-size 0.5s, box-shadow 0.25s',
                          filter: `grayscale(${completed && !sectionMap.includes(field) ? 1 : 0})`,
                          width: 'min(5vw, 7.5vh)',
                          height: 'min(5vw, 7.5vh)',
                          textAlign: 'center',
                          lineHeight: 'min(5vw, 7.5vh)',
                          borderRadius: 'min(0.5vw, 0.75vh)',
                          fontSize: '0',
                          ...style
                        }} key={ field }>{ content }</Box>
                      </Box>
                    );
                  })
                }
              </Stack>
            );
          })
        }
      </Stack>
    );
  };

  return (
    <DisplayContainer label='Meta-Tic-Tac-Toe'>
      {
        !mySymbol ? <LinearProgress/> : (
          <>
            <Stack direction='row' justifyContent='space-evenly' sx={{ height: '100%' }}>
              <Box sx={{ height: '100%', width: '75%' }}>
                <Stack direction='column' justifyContent='center'
                  sx={{ height: 'calc(100% - min(1.5vw, 2.25vh))', overflow: 'hidden' }}>
                  <Stack direction='row' justifyContent='space-evenly'>
                    <Box flex='50%'>
                      <Typography variant='h4'
                        color='#2c3e50'
                        sx={{
                          transition: 'all 0.5s',
                          fontSize: 'min(3vw, 4.5vh)',
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>{ mySymbol === 'X' ? '✖️' : '⭕' } Sie selbst</Typography>
                    </Box>
                    <Box flex='50%'>
                      <Typography variant='h4' color='#2c3e50'
                        sx={{
                          transition: 'all 0.5s',
                          fontSize: 'min(3vw, 4.5vh)',
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>{ mySymbol === 'O' ? '✖️' : '⭕' } Ihr Gegner</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' justifyContent='space-evenly'>
                    {
                      renderBoard()
                    }
                  </Stack>
                </Stack>
              </Box>
            </Stack>
            {
              gameState === 0 ? '' : gameState === 3 ? (
                <Typography variant='h4' color='#2c3e50' sx={{
                  transition: 'all 0.5s',
                  fontSize: '2.3rem',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  mt: '32px'
                }}>
                  Unentschieden
                </Typography>
              ) : (
                <Typography variant='h4' color='#2c3e50' sx={{
                  transition: 'all 0.5s',
                  fontSize: '2.3rem',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  mt: '32px'
                }}>
                  Sie haben die Partie { gameState === 1 ? 'gewonnen' : 'verloren' }
                </Typography>
              )
            }
          </>
        )
      }
    </DisplayContainer>
  );
}

export default React.memo(MetaTicTacToe);
