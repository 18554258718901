import { toNoun } from './to-noun';
import { RockPaperScissorsSymbol } from '@Types/rock-paper-scissors-display';

export function getWinner(symbols: RockPaperScissorsSymbol[]): string {
  switch (symbols[0]) {
    case 'SCHERE':
      switch (symbols[1]) {
        case 'SCHERE':
          return 'Unentschieden';
        case 'STEIN':
        case 'BRUNNEN':
          return `${toNoun(symbols[1])} gewinnt`;
        case 'PAPIER':
        default:
          return 'Schere gewinnt';
      }
    case 'STEIN':
      switch (symbols[1]) {
        case 'STEIN':
          return 'Unentschieden';
        case 'PAPIER':
        case 'BRUNNEN':
          return `${toNoun(symbols[1])} gewinnt`;
        case 'SCHERE':
        default:
          return 'Stein gewinnt';
      }
    case 'PAPIER':
      switch (symbols[1]) {
        case 'PAPIER':
          return 'Unentschieden';
        case 'SCHERE':
          return 'Schere gewinnt';
        case 'STEIN':
        case 'BRUNNEN':
        default:
          return 'Papier gewinnt';
      }
    case 'BRUNNEN':
      switch (symbols[1]) {
        case 'BRUNNEN':
          return 'Unentschieden';
        case 'PAPIER':
          return 'Papier gewinnt';
        case 'SCHERE':
        case 'STEIN':
        default:
          return 'Brunnen gewinnt';
      }
    default:
      return `${toNoun(symbols[1])} gewinnt`;
  }
}
