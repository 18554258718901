import { Nullable, Undefinable } from '@Types';

export function isFetchingDone(
  data: Undefinable<any[]>[],
  errors: Nullable<any>[]
): boolean {
  if (data.includes(undefined)) return false;

  for (const err of errors) {
    if (err !== null) return false;
  }

  return true;
}
