import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Paper, Typography, Chip, Tabs, Tab } from '@mui/material';
import MuiMarkdown from 'mui-markdown';

import { useJsonApiConnector } from '@Hooks/use-json-api-connector';
import PageNotFound from '@Webpages/error/PageNotFound';

const BOX_SHADOW = 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px';

type TabPanelProps = {
  children: React.ReactNode;
  value: number;
  index: number;
};

function GameDetails() {
  const [activeTab, setActiveTab] = React.useState(0);
  const navigateTo = useNavigate();
  const jsonApiConnector = useJsonApiConnector();
  const { gameId } = useParams();

  const [{
    data: game,
    error,
  }] = jsonApiConnector.games.read(gameId?.toLocaleUpperCase() ?? null);

  if (error?.response?.status === 404) return <PageNotFound />;
  if (error || !game) return null;

  const tabContents = [
    {
      label: 'Spielregeln',
      content: game.description,
    },
    {
      label: 'Getting Started',
      content: <MuiMarkdown>{ game.getstarted }</MuiMarkdown>, // TODO: hier später mit leeren String ersetzen
    },
  ];

  const handleTabChange = (ev: any, newValue: React.SetStateAction<number>) => {
    setActiveTab(newValue);
  };

  const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => (
    <div role='tabpanel' hidden={ value !== index }>
      { value === index && <Box p={ 3 }>{ children }</Box> }
    </div>
  );

  function onWatch(ev: React.MouseEvent<HTMLDivElement>, route: string): void {
    ev.preventDefault();
    navigateTo(route);
  }

  function onPlay(ev: React.MouseEvent<HTMLDivElement>, route: string): void {
    ev.preventDefault();
    navigateTo(route);
  }

  return (
    <Box data-testid='details-component'>
      <Typography variant='h3' textAlign='center' sx={{ pt: '16px' }}>
        { game.name }
      </Typography>
      <Typography gutterBottom textAlign='center' variant='subtitle1'>
        { game.subtitle }
      </Typography>
      <Box textAlign='center'>
        <Chip
          sx={{ mx: '8px', maxWidth: 'fit-content', boxShadow: BOX_SHADOW }}
          label='Zuschauen'
          color='secondary'
          clickable
          onClick={(ev) => {
            onWatch(ev, `/games/${game.id.toLocaleLowerCase()}/display`);
          }}
        />
        <Chip
          sx={{ mx: '8px', maxWidth: 'fit-content', boxShadow: BOX_SHADOW }}
          label='Selber spielen'
          color='secondary'
          clickable
          onClick={(ev) => {
            onPlay(ev, `/games/${game.id.toLocaleLowerCase()}/pvc`);
          }}
        />
      </Box>
      <Paper
        sx={{
          backgroundColor: '#FEFEFE',
          p: '16px',
          m: '16px auto',
          maxWidth: '1200px',
        }}
        elevation={ 3 }
      >
        <Tabs
          value={ activeTab }
          onChange={ handleTabChange }
          indicatorColor='primary'
        >
          {
            tabContents.map((tab, index) => (
              <Tab key={ index } label={ tab.label } />
            ))
          }
        </Tabs>
        {
          tabContents.map((tab, index) => (
            <TabPanel key={ index } value={ activeTab } index={ index }>
              { tab.content }
            </TabPanel>
          ))
        }
      </Paper>
    </Box>
  );
}

export default React.memo(GameDetails);
