import React from 'react';
import { useParams } from 'react-router-dom';

import { Alert, Box, Button, Paper, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import PageNotFound from './error/PageNotFound';
import { useJsonApiConnector, useProfile } from '@Hooks';
import { createSubtitle } from '@Utils/create-subtitle';

function CompetitionDetails() {
  const jsonApiConnector = useJsonApiConnector();
  const { competitionId } = useParams();
  const { profile, profileReload } = useProfile();

  const [{
    data: competition,
    error,
  }, reloadCompetition] = jsonApiConnector.competitions.read(
    competitionId?.toLocaleUpperCase() ?? null
  );

  if (error?.response?.status === 404) {
    return <PageNotFound />;
  }

  if (error || !competition) return null;

  let action;

  if (profile) {
    if (competition.accepted_account && competition.accepted_competition) {
      action = <Alert>beigetreten</Alert>;
    } else {
      switch (competition.type) {
        case 'PRIVATE': {
          action = <Alert severity='info'>private Veranstaltung</Alert>;
          break;
        }
        case 'PUBLIC': {
          if (competition.accepted_account && !competition.accepted_competition) {
            action = <Alert severity='info'>Einladung angefragt</Alert>
          } else if (!competition.accepted_account && competition.accepted_competition) {
            action = <Button variant='contained' onClick={
              async () => {
                await jsonApiConnector.competitionManagement.acceptInvite(
                  competition.id,
                  enqueueSnackbar,
                  () => {
                    enqueueSnackbar('Einladung angenommen', {
                      variant: 'success',
                    });
                  },
                );
                reloadCompetition();
                profileReload();
              }
            }>Einladung annehmen</Button>
          } else {
            action = <Button variant='contained' onClick={
              async () => {
                await jsonApiConnector.competitionManagement.join(
                  competition.id,
                  enqueueSnackbar,
                  () => {
                    enqueueSnackbar('Einladung angefragt', {
                      variant: 'success',
                    });
                  },
                );
                reloadCompetition();
                profileReload();
              }
            }>Einladung anfragen</Button>
          }
          break;
        }
        case 'AUTOACCEPT': {
          action = <Button variant='contained' onClick={
            async () => {
              await jsonApiConnector.competitionManagement.join(
                competition.id,
                enqueueSnackbar,
                () => {
                  enqueueSnackbar('Erfolgreich beigetreten', {
                    variant: 'success',
                  });
                },
              );
              reloadCompetition();
              profileReload();
            }
          }>beitreten</Button>
          break;
        }
      }
    }
  }

  return (
    <Box>
      <Typography
        variant='h3'
        textAlign='center'
        sx={{ pt: '16px' }}
      >
        { competition.name }
      </Typography>
      <Typography
        gutterBottom
        textAlign='center'
        variant='subtitle1'
      >
        { createSubtitle(competition) }
      </Typography>
      <Paper
        sx={{
          textAlign: 'center',
          backgroundColor: '#FEFEFE',
          p: '16px',
          m: '16px auto',
          maxWidth: '1200px',
        }}
        elevation={ 3 }
      >
        <Typography gutterBottom variant='body2' color='text.secondary'>
          { competition.description }
        </Typography>
        { action }
      </Paper>
    </Box>
  );
}

export default React.memo(CompetitionDetails);
