import React from 'react';

import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Sidebar from '../sidebar/Sidebar';
import {
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  AppBar,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import StarsIcon from '@mui/icons-material/Stars';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import GroupsIcon from '@mui/icons-material/Groups';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MenuIcon from '@mui/icons-material/Menu';

import logo from '@Assets/logo.png';
import { useProfile } from '@Hooks/use-profile';
import { logins } from '../../logins';
import ProfilePicture from '../profile/ProfilePicture';
import useMediaQuery from "@mui/material/useMediaQuery";

function Toolbar() {
  const [
    anchorElement,
    setAnchorElement
  ] = React.useState<null | HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const notMobile = useMediaQuery('(max-width:900px)');


  const { profile } = useProfile();
  const isLoggedIn = profile !== undefined;
  const isManager = !!(profile && profile?.competitionmanager);


  function handleClick(event: React.MouseEvent<HTMLDivElement>): void {
    setAnchorElement(event.currentTarget);
  }

  const handleSidebarClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  function handleClose(): void {
    setAnchorElement(null);
    setOpen(false);
  }

  function handleLogin(): void {
    setOpen(true);
  }

  function handleMenuItemClick(route: string): void {
    handleClose();
    navigateTo(route);
  }

  const navigateTo = useNavigate();

  return (
    <AppBar color='primary' sx={{
      height: '64px',
      p: '8px 16px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}>
      <Box sx={{ display: 'flex', columnGap: '8px', alignItems: 'center' }}>
        <a href='/' style={{
          textDecoration: 'none',
          color: 'inherit',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: '8px'
        }}>
          <img
            src={ logo }
            aria-label='games.uhno.de Logo'
            width='48px'
            height='48px'
            style={{ borderRadius: '8px' }}
          />
          <Typography fontWeight='800' fontSize='1.4rem'>
            games.uhno.de
          </Typography>
        </a>
      </Box>

      {
        notMobile ? (<Box onClick={handleSidebarClick}><MenuIcon fontSize="large" /></Box>) : (

      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '16px' }}>
        <Box onClick={() => navigateTo('/')} sx={{
          columnGap: '8px',
          display: 'flex',
          cursor: 'pointer'
        }}>
          <HomeIcon />
          <Typography>Startseite</Typography>
        </Box>
        <div onClick={ handleClick } color='inherit' role='button' style={{
          columnGap: '8px',
          display: 'flex',
          cursor: 'pointer'
        }}>
          <StarsIcon />
          <Typography>Ranglisten</Typography>
          {
            Boolean(anchorElement) ? <ExpandLessIcon /> : <ExpandMoreIcon />
          }
        </div>
        <Menu
          open={ Boolean(anchorElement) }
          anchorEl={ anchorElement }
          onClose={ handleClose }
          sx={{ mt: '8px' }}
        >
          <MenuItem onClick={() => handleMenuItemClick('/ranking/teams')}>
            <Box sx={{ display: 'flex', columnGap: '8px' }}>
              <LeaderboardIcon />
              <Typography>Team-Rangliste</Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('/ranking/users')}>
            <Box sx={{ display: 'flex', columnGap: '8px' }}>
              <LeaderboardIcon />
              <Typography>Spieler-Rangliste</Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('/ranking/bots')}>
            <Box sx={{ display: 'flex', columnGap: '8px' }}>
              <LeaderboardIcon />
              <Typography>Bot-Rangliste</Typography>
            </Box>
          </MenuItem>
        </Menu>
        {
          isLoggedIn ? (
            <>
              <Box onClick={() => navigateTo('/user/teams')} sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '8px',
                cursor: 'pointer'
              }}>
                <GroupsIcon />
                <Typography>Meine Teams</Typography>
              </Box>
              {
                isManager ? (
                  <Box onClick={() => navigateTo('/user/competitions')} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '8px',
                    cursor: 'pointer'
                  }}>
                    {/* <GroupsIcon /> */}
                    <Typography>Meine Turniere</Typography>
                  </Box>
                ) : <></>
              }
            </>
          ) : <></>
        }
        {
          isLoggedIn ? (
            <Button
              variant='contained'
              color='secondary'
              href='/auth/logout'
              sx={{
                borderRadius: '8px',
                columnGap: '8px'
              }}
            >
              <LogoutIcon />
              Abmelden
            </Button>
          ) : (
            <Button
              variant='contained'
              color='secondary'
              onClick={() => handleLogin()}
              sx={{
                borderRadius: '8px',
                columnGap: '8px'
              }}
            >
              <LoginIcon />
              Anmelden
            </Button>
          )
        }
        <Dialog PaperProps={{
          style: {
            maxWidth: '200p',
            backgroundColor: "white",
          },
        }} open={open} onClose={handleClose}>
          <DialogTitle>Anmelden via...</DialogTitle>
          <DialogContent dividers>
            {
              logins.map(({ id, title, icon, disabled }) => (
                <Button fullWidth={true} key={id} variant='contained'
                  disabled={disabled}
                  sx={{my: '8px'}}
                  href={`/auth/${id}/login`}
                  startIcon={<FontAwesomeIcon icon={icon}/>}
                >
                  {title}
                </Button>
              ))
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Abbrechen</Button>
          </DialogActions>
        </Dialog>
        {
          isLoggedIn ? (
            <Box onClick={handleSidebarClick}
                 sx={{columnGap: '8px', padding: '0px',  '&:hover': {
                     cursor: 'pointer'
                   }}}>
              <ProfilePicture
                image={ profile?.photo }
                username={ profile?.username }
              />
            </Box>
          ) : <></>
        }
      </Box>
        )
      }
      <Sidebar isOpen={sidebarOpen} onClose={handleCloseSidebar} />
    </AppBar>
  );
}

export default React.memo(Toolbar);
