import React from 'react';

import { Box, SxProps, Theme, Typography } from '@mui/material';

type IconLabelProps = {
  label: string;
  usePaddingTop?: boolean;
  icon: JSX.Element;
};

function IconLabel({ label, icon, usePaddingTop = false }: IconLabelProps) {
  const boxStyles: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '8px',
    alignItems: 'center',
    pt: usePaddingTop ? '16px' : 0
  };

  return (
    <Box sx={ boxStyles }>
      { icon }
      <Typography data-testid='label' variant='h5'>{ label }</Typography>
    </Box>
  );
}

export default React.memo(IconLabel);
