import React from 'react';

import { Chip } from '@mui/material';

import { useOutsideClick } from '@Hooks/use-outside-click';

export type ChipFilterProps = {
  label: string;
  active?: boolean;
  outsideClickContext?: string | null;
  onActivated: () => void;
};

const BOX_SHADOW = 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px';

function ChipFilter({
  label,
  active = true,
  outsideClickContext = null,
  onActivated
}: ChipFilterProps) {
  const [activationState, setActivationState] = React.useState(active);

  function handleOutsideClick() {
    setActivationState(false);
  }

  function toggleActivation() {
    const newState = !activationState;
    setActivationState(newState);

    if (newState === true) onActivated();
  }

  const ref = useOutsideClick(handleOutsideClick, outsideClickContext);

  return (
    <Chip
      data-testid='filter-chip'
      ref={ ref }
      label={ label }
      color={ activationState ? 'secondary' : 'default' }
      sx={{ maxWidth: 'fit-content', boxShadow: BOX_SHADOW }}
      onClick={ toggleActivation }
    />
  );
}

export default React.memo(ChipFilter);
