import './Battleship.css';
import React from 'react';

import { Box, LinearProgress, Stack, Typography } from '@mui/material';

import DisplayContainer from '@Components/games/DisplayContainer';
import { useLogger } from '@Hooks/use-logger';
import { PvC } from '@Services/player-versus-computer';
import { Nullable, PvCAnswerFunction, BattleshipPvC } from '@Types';
import { BattleshipSymbol } from '../types';
import { randomChoice } from '@Utils/random-choice';

const DEFAULT_BOARD: BattleshipSymbol[][] = [
  ['', '', '', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '', '', '']
];

function Battleship() {
  const logger = useLogger();
  const answerCallback = React.useRef<PvCAnswerFunction<[number, number] | BattleshipPvC.SetPhaseMove[]>>(function (move: [number, number] | BattleshipPvC.SetPhaseMove[]) {
    logger.error('Have not received the callback yet.');
  });
  const [myBoard, setMyBoard] = React.useState<BattleshipSymbol[][]>(DEFAULT_BOARD);
  const [enemyBoard, setEnemyBoard] = React.useState<BattleshipSymbol[][]>(DEFAULT_BOARD);
  const [gameId, setGameId] = React.useState<Nullable<string>>(null);
  const [myIndex, setMyIndex] = React.useState<Nullable<number>>(null);
  const [gameState, setGameState] = React.useState<Nullable<number>>(null);
  const [lastMove, setLastMove] = React.useState<Nullable<[number, number]>>(null);

  React.useEffect(() => {
    /**
     * @TODO
     * Needs to be replaced with the secret of the user.
     * Currently the connection is established in the perspective of a bot.
     */
    const pvc = new PvC<
      BattleshipPvC.DataInit,
      [
        BattleshipPvC.DataSet | BattleshipPvC.DataRound,
        PvCAnswerFunction<[number, number] | BattleshipPvC.SetPhaseMove[]>
      ],
      BattleshipPvC.DataResult
    >('5b962501-5383-4300-ab30-2ba9163f3bc1');

    const setPhase = () => {
      answerCallback.current([
        { start: [4, 3], direction: "h", size: 5 },
        { start: [8, 6], direction: "v", size: 4 },
        { start: [1, 5], direction: "v", size: 3 },
        { start: [3, 5], direction: "v", size: 3 },
        { start: [5, 5], direction: "v", size: 2 }
      ]);
    };

    pvc.on('round-ended', (data) => {
      pvc.disconnect();

      let index = 0;

      if (data.players[0].id !== data.self) {
        index = 1;
      }

      if (data.boards) {
        setMyBoard(data.boards[index]);
        setEnemyBoard(data.boards[(index === 0 ? 1 : 0)]);
      }

      if (data.players[index].score > 0) {
        setGameState(1);
      } else {
        setGameState(-1);
      }

      setTimeout(() => {
        pvc.connect();
        setGameId(null);
        setMyIndex(null);
        setMyBoard(DEFAULT_BOARD);
        setEnemyBoard(DEFAULT_BOARD);
        setGameState(null);
        setLastMove(null);
      }, 3000);
    });
    pvc.on('round-state', (data) => {
      const round = data[0];
      let index = 0;

      setGameId(round.id);

      if (round.players[0].id === round.self) {
        setMyIndex(0);
      } else {
        index = 1;
        setMyIndex(1);
      }

      answerCallback.current = data[1];

      if (round.type === 'SET') {
        setPhase();
      } else {
        setMyBoard((round as BattleshipPvC.DataRound).boards[index]);
        setEnemyBoard((round as BattleshipPvC.DataRound).boards[(index === 0 ? 1 : 0)]);
      }
    });

    pvc.connect();

    return () => {
      pvc.offAll();
    }
  }, [logger]);

  /**
   *
   * @param field An array of the column and the row [column, row]
   */
  function onFieldClicked(field: [number, number], myBoard: boolean): void {
    if (!myBoard) {
      if (enemyBoard[field[0]][field[1]] === '') {
        answerCallback.current(field);
        setLastMove(field);
      } else {
        logger.error('This field has already been clicked', field, enemyBoard[field[1]][field[0]]);
      }
    } else {
      logger.warn('You can not select a field on your own board');
    }
  }

  const renderBoard = (board: BattleshipSymbol[][], myBoard: boolean) => {
    /**
     * If this factor is changed from 0.75 to any other value, the animation
     * keyframes within the Battleship.css needs to be adjusted aswell.
     */
    const factor = 0.75;

    return (
      <Stack direction='row' sx={{
        transition: 'all 0.25s',
        padding: `min(${factor}vw, ${1.5 * factor}vh)`,
        borderRadius: `min(${2 * factor}vw, ${3 * factor}vh)`,
        width: 'fit-content'
      }}>
        {
          board.map((column, x) => (
            <Stack direction='column' key={x}>
              {
                column.map((value, y) => {
                  const seed = `${gameId} ${x} ${y}`;
                  let content = randomChoice(['🐳', '🐋', '🐬', '🐟', '🐠', '🐡', '🦈', '🐙', '🦀', '🦑'], seed);
                  const hitContent = randomChoice(['💥', '🔥'], seed);
                  const style: any = { };
                  let hit = false;

                  switch (value) {
                    case '.':
                      style.bgcolor = '#2980b9';
                      break;
                    case 'x':
                      style.bgcolor = '#e74c3c';
                      style.fontSize = `min(${3 * factor}vw, ${4.5 * factor}vh)`;
                      content = hitContent;
                      hit = true;
                      break;
                    case 'X':
                      style.bgcolor = '#34495e';
                      style.fontSize = `min(${3 * factor}vw, ${4.5 * factor}vh)`;
                      content = hitContent;
                      hit = true;
                      break;
                    case 'O':
                      style.bgcolor = '#95a5a6';
                      content = hitContent;
                      hit = true;
                      break;
                    default:
                      style.bgcolor = '#3498db';
                  }

                  if (!myBoard && !hit && lastMove && x === lastMove[0] && y === lastMove[1]) {
                    style.animation = 'selection-disappear 1s forwards';
                  }

                  return (
                    <Box onClick={() => onFieldClicked([x, y], myBoard)} sx={{
                      transition: 'background 1s, font-size 0.5s, box-shadow 0.25s',
                      width: `min(${5 * factor}vw, ${7.5 * factor}vh)`,
                      height: `min(${5 * factor}vw, ${7.5 * factor}vh)`,
                      boxShadow: 'inset 0 0 0 0.5px white',
                      textAlign: 'center',
                      lineHeight: `min(${5 * factor}vw, ${7.5 * factor}vh)`,
                      borderRadius: `min(${0.5 * factor}vw, ${0.75 * factor}vh)`,
                      fontSize: '0',
                      ...style
                    }} key={ y }>{ content }</Box>
                  );
                })
              }
            </Stack>
          ))
        }
      </Stack>
    );
  };

  return (
    <DisplayContainer label='Schiffe versenken'>
      {
        !gameId || myIndex === null ? <LinearProgress /> : (
          <>
            <Stack direction='column' justifyContent='center' sx={{ height: 'calc(100% - min(1.5vw, 2.25vh))' }}>
              <Stack direction='row' justifyContent='space-evenly'>
                <Box flex='50%'>
                  <Typography variant='h4' color='#2c3e50'
                    sx={{
                      transition: 'all 0.5s',
                      fontSize: '2.3rem',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>Ihr Spielfeld</Typography>
                </Box>
                <Box flex='50%'>
                  <Typography variant='h4' color='#2c3e50'
                    sx={{
                      transition: 'all 0.5s',
                      fontSize: '2.3rem',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>Spielfeld vom Gegner</Typography>
                </Box>
              </Stack>
              <Stack direction='row' justifyContent='space-evenly'>
                <Box flex='50%' display='flex' justifyContent='center'>
                  {
                    renderBoard(myBoard, true)
                  }
                </Box>
                <Box flex='50%' display='flex' justifyContent='center'>
                  {
                    renderBoard(enemyBoard, false)
                  }
                </Box>
              </Stack>
              {
                gameState === null ? '' : (
                  <Typography variant='h4' color='#2c3e50'
                    sx={{
                      transition: 'all 0.5s',
                      fontSize: '2.3rem',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      mt: '32px'
                    }}>{ gameState === 1 ? 'Sie haben gewonnen' : 'Sie haben verloren' }</Typography>
                )
              }
            </Stack>
          </>
        )
      }
    </DisplayContainer>
  );
};

export default React.memo(Battleship);
