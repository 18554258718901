import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';

import { useJsonApiConnector } from '@Hooks/use-json-api-connector';

function Games() {
  const navigateTo = useNavigate();
  const jsonApiConnector = useJsonApiConnector();

  const [{
    data: games,
    error,
  }] = jsonApiConnector.games.readAll();

  if (error || !games) {
    return null;
  }

  function onWatch(
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    route: string
  ): void {
    ev.preventDefault();
    navigateTo(route);
  }

  function onPlay(
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    route: string
  ): void {
    ev.preventDefault();
    navigateTo(route);
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={ 2 }>
        {
          games.map((game) => (
            <Grid item xs={ 12 } sm={ 6 } md={ 4 } key={ game.id }>
              <Card
                data-testid='game-card'
                component={ RouterLink }
                to={ `/game/${game.id.toLocaleLowerCase()}` }
                key={ game.id }
                sx={{
                  bgcolor: 'primary.50',
                  display: 'flex',
                  flexDirection: 'column',
                  textDecoration: 'none',
                  height: '100%',
                }}
              >
                <CardHeader title={ game.name } subheader={ game.subtitle } />
                <CardContent sx={{ flex: '1 1 auto' }}>
                  <Typography variant='body2' color='text.secondary'>
                    { game.description }
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    p: '16px',
                    justifyContent: 'space-between'
                  }}
                >
                  <Tooltip title='Zuschauen'>
                    <IconButton
                      data-testid='watch-button'
                      onClick={(ev) => onWatch(
                        ev,
                        `/games/${game.id.toLocaleLowerCase()}/display`
                      )}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Selber spielen'>
                    <IconButton
                      data-testid='play-button'
                      onClick={(ev) => onPlay(
                        ev,
                        `/games/${game.id.toLocaleLowerCase()}/pvc`
                      )}
                    >
                      <PlayArrowIcon />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              </Card>
            </Grid>
          ))
        }
      </Grid>
    </Box>
  )
}

export default React.memo(Games);
