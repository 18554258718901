import React from 'react';
import { Outlet } from 'react-router-dom';

import { useProfile } from '@Hooks/use-profile';
import AccessDenied from '@Webpages/error/AccessDenied';

type LoginRequiredGuardProps = {
  successElement?: JSX.Element;
};

function LoginRequiredGuard({ successElement }: LoginRequiredGuardProps) {
  const { profile } = useProfile();

  if (!profile) {
    return <AccessDenied isLoginRequired />
  }

  if (!successElement) {
    return <Outlet />;
  }

  return successElement;
}

export default React.memo(LoginRequiredGuard);
