import React from 'react';

import { Box, LinearProgress, Stack, Typography, useTheme } from '@mui/material';

import DisplayContainer from '@Components/games/DisplayContainer';
import { useJsonApiConnector, useLogger } from '@Hooks';
import { Bot } from '@Models/bot';
import { TicTacToeDisplay, Symbol } from '@Types';

function TicTacToe() {
  const logger = useLogger();
  const jsonApiConnector = useJsonApiConnector();
  const theme = useTheme();
  const [bots, setBots] = React.useState<Bot[]>([]);
  const [step, setStep] = React.useState<number>(0);

  const [{
    data: info
  }, reload] = jsonApiConnector.displayGame.read<TicTacToeDisplay>('TTT');

  logger.info('Fetched game from database: ', info);

  React.useEffect(() => {
    setStep(0);
    setBots([]);

    if (info) {
      (async () => {
        const bots = await Promise.all(info.data.players.map(
          async (id) => (
            await jsonApiConnector.client.get<Bot>(`/bot/${id}`)
          ).data
        ));

        setBots(bots);
      })();
    }
  }, [info, jsonApiConnector]);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!info || bots.length === 0) {
      logger.info('still loading');
    } else {
      if (step === info.data.states.length) {
        timeout = setTimeout(() => {
          reload();
        }, 5000);
      } else {
        timeout = setTimeout(() => setStep(step + 1), 2000);
      }
    }

    return () => clearTimeout(timeout);
  }, [step, info, bots, reload, logger]);

  const state = info?.data.states[step - 1];

  function renderBoard(board: Symbol[]) {
    return (
      <Stack direction='row' sx={{
        transition: 'all 0.25s',
        border: 'min(0.5vw,0.75vh) solid transparent',
        margin: 'min(0.5vw,0.75vh)',
        borderRadius: 'min(1vw,1.5vh)',
        display: 'grid',
        gridTemplateRows: 'auto auto auto',
        gridTemplateColumns: 'auto auto auto',
        overflow: 'hidden',
        position: 'relative',
        userSelect: 'none'
      }}>
        {
          board.map((field, id) => {
            let content = '';

            switch (field) {
              case 'X':
                content = '✖️';
                break;
              case 'O':
                content = '⭕';
                break;
            }

            return <Box sx={{
              zIndex: 1,
              border: `min(0.125vw, 0.1875vh) solid ${theme.palette.primary.main}`,
              borderTop: Math.floor(id / 3) === 0 ? '0px solid transparent' : null,
              borderBottom: Math.floor(id / 3) === 2 ? '0px solid transparent' : null,
              borderLeft: id % 3 === 0 ? '0px solid transparent' : null,
              borderRight: id % 3 === 2 ? '0px solid transparent' : null,
              userSelect: 'inherit'
            }} key={ id }>
              <Box sx={{
                transition: 'background 1s, font-size 0.5s, box-shadow 0.25s',
                width: 'min(5vw, 7.5vh)',
                height: 'min(5vw, 7.5vh)',
                textAlign: 'center',
                lineHeight: 'min(5vw, 7.5vh)',
                borderRadius: 'min(0.5vw, 0.75vh)',
                fontSize: 'min(3vw, 4.5vh)',
                userSelect: 'inherit'
              }}>{ content }</Box>
            </Box>;
          })
        }
      </Stack>
    );
  };

  return (
    <DisplayContainer label='Tic-Tac-Toe'>
      {
        !info || bots.length === 0 || !state ? <LinearProgress /> : (
          <>
            <Stack direction='row' justifyContent='space-evenly' sx={{height: '100%'}}>
              <Box sx={{height: '100%', width: '75%'}}>
                <Stack direction='column' justifyContent='center'
                  sx={{height: 'calc(100% - min(1.5vw,2.25vh))', overflow: 'hidden'}}>
                  <Stack direction='row' justifyContent='space-evenly'>
                    <Box sx={{
                      width: 'min(40vw,60vh)'
                    }}>
                      <Typography variant='h4'
                        color={ state.active === 1 ? '#2c3e50' : '#95a5a6' }
                        sx={{
                          transition: 'all 0.5s',
                          fontSize: 'min(6vw, 9vh)',
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>✖️{ bots[0].name }</Typography>
                      <Typography variant='body2'
                        color={ state.active === 1 ? '#2c3e50' : '#95a5a6' }
                        sx={{
                          transition: 'all 0.5s',
                          fontSize: 'min(2vw, 3vh)',
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>Team "{ bots[0].team }"</Typography>
                    </Box>
                    <Box sx={{
                      width: 'min(40vw,60vh)'
                    }}>
                      <Typography variant='h4' color={ state.active === 0 ? '#2c3e50' : '#95a5a6' }
                        sx={{
                          transition: 'all 0.5s',
                          fontSize: 'min(6vw, 9vh)',
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>⭕{ bots[1].name }</Typography>
                      <Typography variant='body2' color={ state.active === 0 ? '#2c3e50' : '#95a5a6' }
                        sx={{
                          transition: 'all 0.5s',
                          fontSize: 'min(2vw, 3vh)',
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>Team "{ bots[1].team }"</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' justifyContent='space-evenly' sx={{ mt: '32px' }}>
                    {
                      renderBoard(state.board)
                    }
                  </Stack>
                </Stack>
                <LinearProgress value={100 / info.data.states.length * step} variant='determinate'
                  sx={{
                    m: 'min(0.5vw, 0.75vh)',
                    mt: '16px',
                    height: 'min(0.5vw, 0.75vh)',
                    borderRadius: 'min(0.25vw, 0.375vh)'
                  }}/>
              </Box>
            </Stack>
          </>
        )
      }
    </DisplayContainer>
  );
}

export default React.memo(TicTacToe);
