import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  AvatarGroup,
  Badge,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';

import { useJsonApiConnector, useProfile } from '@Hooks';
import UserAvatar from '@Components/profile/UserAvatar';

function Competitions() {
  const { profile } = useProfile();
  const jsonApiConnector = useJsonApiConnector();

  const [{
    data: competitions,
  }] = jsonApiConnector.competitionManagement.readAll();

  if (!profile) return null;

  return (
    <Box
      display='grid'
      gridTemplateColumns='repeat(auto-fill, minmax(320px, 1fr))'
      gap={ 2 }
      sx={{ m: '0 auto', p: 2 }}
      maxWidth='1200px'
    >
      {
        (competitions || []).map((competition) => (
          <Card key={ competition.id }>
            <CardActionArea
              component={ RouterLink }
              to={ `/user/competitions/${competition.id}` }
            >
              <CardContent sx={{
                height: '240px',
                display: 'flex',
                flexDirection: 'column'
              }}>
                <Badge color='info' badgeContent={ competition.requests }>
                  <Typography gutterBottom variant='h5' component='div'>
                    { competition.name }
                  </Typography>
                </Badge>
                <Typography
                  gutterBottom
                  variant='body2'
                  color='text.secondary'
                  sx={{
                    height: 0,
                    overflow: 'hidden',
                    flexGrow: 1
                  }}
                >
                  { competition.description }
                </Typography>
                <AvatarGroup max={ 8 }>
                  {
                    (competition?.members || []).map((member) => (
                      <UserAvatar key={ member.id } profile={ member } tooltip/>
                    ))
                  }
                </AvatarGroup>
              </CardContent>
            </CardActionArea>
          </Card>
        ))
      }
    </Box>
  );
};

export default React.memo(Competitions);
